/**
 * Created by - Ashlekh on 11-02-2024
 * Reason - For storing
 */
import { useState, useEffect } from "react";
import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { getAllDetailsApi } from "../Api/services";

export const GlobalContext = createContext();

export default function Context(props) {
  const location = useLocation();
  var [globalJyotReceiptList, setGlobalJyotReceiptList] = useState([]);
  // Added by - Ashlekh on 14-02-2024
  // Reason - For storing currentRoute in context
  const [globalCurrentRoute, setGlobalCurrentRoute] = useState("");
  const [
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
  ] = useState("");
  // End of code - Ashlekh on 14-02-2024
  // Reason - For storing currentRoute in context

  // Added by - Ashlekh on 29-03-2024
  // Reason - For storing donation receipt history list & mobile number
  var [globalDonationReceiptList, setGlobalDonationReceiptList] = useState([]);
  var [
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
  ] = useState("");
  // End of code - Ashlekh on 29-03-2024
  // Reason - For storing donation receipt history list & mobile number

  const [socialMediaDetails, setSocialMediaDetails] = useState([]);
  // Addition by Om Shrivastava on 01-04-24
  // Reason : Need to set social links, coverphotos, homepages details
  const [backgroundImage, setBackgroundImage] = useState("");
  const [godDetails, setGodDetails] = useState([]);
  const [marqueeText, setMarqueeText] = useState("");
  const [coverPhotos, setCoverPhotos] = useState([]);
  const [homePagePhotos, setHomePagePhotos] = useState([]);
  const [homeContent, setHomeContent] = useState("");
  const [mandirMemberDetails, setMandirMemberDetails] = useState([]);
  const [homePageDescription, setHomePageDescription] = useState("");
  // End of addition by Om Shrivastava on 01-04-24
  // Reason : Need to set social links, coverphotos, homepages details
  // Addition by Om Shrivastava on 02-04-24
  // Reason : Need to set donation description
  const [donationDescriptionData, setDonationDescriptionData] = useState("");
  // End of addition by Om Shrivastava on 02-04-24
  // Reason : Need to set donation description
  // Addition by Om Shrivastava on 12-04-24
  // Reason : Need to set jyot description
  const [jyotDescriptionData, setJyotDescriptionData] = useState("");
  // End of addition by Om Shrivastava on 12-04-24
  // Reason : Need to set donation description
  // Addition by Om Shrivastava on 30-03-24
  // Reason : Need to set logo in from backend
  const [logo, setLogo] = useState("");

  /* Addition by Om Shrivastava on 04-04-24
  Reason : Set the usestate data for loader  */
  const [isHomePageLoader, setIsHomePageLoader] = useState(true);
  /* End of addition by Om Shrivastava on 04-04-24
  Reason : Set the usestate data for loader  */

  useEffect(() => {
    getHeaderLogo();
  }, []);

  const getHeaderLogo = async () => {
    const response = await getAllDetailsApi();
    // if (response?.website_contents.header_logo != null) {
    setLogo(response?.website_contents?.header_logo);
    // Addition by Om Shrivastava on 01-04-24
    // Reason : Need to set social links, coverphotos, homepages details
    setSocialMediaDetails(response?.social_links);

    setBackgroundImage(response?.website_contents?.background_image);
    setMarqueeText(response?.website_contents?.banner_text);
    setGodDetails(response?.details_of_gods);
    setCoverPhotos(response?.cover_photos);
    setHomePagePhotos(response?.home_page_photos);
    setHomeContent(response?.website_contents?.home_content);
    setMandirMemberDetails(response?.mandir_members);
    setHomePageDescription(response?.website_contents?.description);
    // End of addition by Om Shrivastava on 01-04-24
    // Reason : Need to set social links, coverphotos, homepages details
    // Addition by Om Shrivastava on 12-04-24
    // Reason : Need to set jyot description
    setJyotDescriptionData(response?.website_contents?.jyot_description);
    // End of addition by Om Shrivastava on 12-04-24
    // Reason : Need to set jyot description
    // Addition by Om Shrivastava on 02-04-24
    // Reason : Need to set donation description
    setDonationDescriptionData(
      response?.website_contents?.donation_description
    );
    // End of addition by Om Shrivastava on 02-04-24
    // Reason : Need to set donation description
    // }
    /* Addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
    setIsHomePageLoader(false);
    /* End of addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
  };
  // End of addition by Om Shrivastava on 30-03-24
  // Reason : Need to set logo in usestate

  return (
    <>
      <GlobalContext.Provider
        value={{
          globalJyotReceiptList,
          setGlobalJyotReceiptList,
          location,
          // Added by - Ashlekh on 14-02-2024
          // Reason - To make globalCurrentRoute setGlobalCurrentRoute globalJyotReceiptHistoryMobileNumber setGlobalJyotReceiptHistoryMobileNumber available to other components
          globalCurrentRoute,
          setGlobalCurrentRoute,
          globalJyotReceiptHistoryMobileNumber,
          setGlobalJyotReceiptHistoryMobileNumber,
          // End of code - Ashlekh on 14-02-2024
          // Reason - To make globalCurrentRoute setGlobalCurrentRoute globalJyotReceiptHistoryMobileNumber setGlobalJyotReceiptHistoryMobileNumber available to other components

          // Added by - Ashlekh on 29-03-2024
          // Reason - To make globalDonationReceiptList, setGlobalDonationReceiptList, globalDonationReceiptHistoyrMobileNumber, setGlobalDonationReceiptHistoryMobileNumber available to other components
          globalDonationReceiptList,
          setGlobalDonationReceiptList,
          globalDonationReceiptHistoryMobileNumber,
          setGlobalDonationReceiptHistoryMobileNumber,
          // End of code - Ashlekh on 29-03-2024
          // Reason - To make globalDonationReceiptList, setGlobalDonationReceiptList, globalDonationReceiptHistoyrMobileNumber, setGlobalDonationReceiptHistoryMobileNumber available to other components

          // Addition by Om Shrivastava on 30-03-24
          // Reason : Set the logo in context
          logo,
          setLogo,
          // End of Addition by Om Shrivastava on 30-03-24
          // Reason : Set the logo in context
          // Addition by Om Shrivastava on 01-04-24
          // Reason : Need to set social links, coverphotos, homepages details
          socialMediaDetails,
          setSocialMediaDetails,
          backgroundImage,
          setBackgroundImage,
          godDetails,
          setGodDetails,
          marqueeText,
          setMarqueeText,
          coverPhotos,
          setCoverPhotos,
          homePagePhotos,
          setHomePagePhotos,
          homeContent,
          setHomeContent,
          mandirMemberDetails,
          setMandirMemberDetails,
          homePageDescription,
          setHomePageDescription,
          // End of addition by Om Shrivastava on 01-04-24
          // Reason : Need to set social links, coverphotos, homepages details
          // Addition by Om Shrivastava on 02-04-24
          // Reason : Need to set donation description
          donationDescriptionData,
          setDonationDescriptionData,
          // End of addition by Om Shrivastava on 01-04-24
          // Reason : Need to set donation description
          // Addition by Om Shrivastava on 12-04-24
          // Reason : Need to set jyot description
          jyotDescriptionData,
          setJyotDescriptionData,
          // End of addition by Om Shrivastava on 12-04-24
          // Reason : Need to set jyot description
          // Addition by Om Shrivastava on 04-04-24
          // Reason : Need to set home page loader
          isHomePageLoader,
          setIsHomePageLoader,
          // End of addition by Om Shrivastava on 04-04-24
          // Reason : Need to set home page loader
        }}
      >
        {props.children}
      </GlobalContext.Provider>
    </>
  );
}
