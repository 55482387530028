/**
 * Created by - Ashish Dewangan on 12-01-2024
 * Reason - To have about gods page
 */
import { useContext, useEffect, useState } from "react";
import { getAllDetailsApi, getGalleryContentsApi } from "../../Api/services";

import homeStyle from "./Home.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GodsPhotoContainer from "./GodsPhotoContainer";
import CoverImage from "./CoverImage";
import HomePagePhotosContents from "./HomePagePhotosContents";
import MandirMembers from "./MandirMembers";
import HomePageDescription from "./HomePageDescription";
import Marquee from "react-fast-marquee";
import LiveVideoContainer from "../Gallery/LiveVideoContainer";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/Context";

const Home = (props) => {
  // Modification and addition by Om Shrivastava on 01-04-24
  // Reason : This usestate data is not used yet
  // const [backgroundImage, setBackgroundImage] = useState("");
  // const [godDetails, setGodDetails] = useState([]);
  // const [marqueeText, setMarqueeText] = useState("");
  // const [coverPhotos, setCoverPhotos] = useState([]);
  // const [homePagePhotos, setHomePagePhotos] = useState([]);
  // const [homeContent, setHomeContent] = useState("");
  // const [mandirMemberDetails, setMandirMemberDetails] = useState([]);
  // const [homePageDescription, setHomePageDescription] = useState("");
  var {
    backgroundImage,
    godDetails,
    marqueeText,
    coverPhotos,
    homePagePhotos,
    homeContent,
    mandirMemberDetails,
    homePageDescription,
  } = useContext(GlobalContext);
  // End of modification and addition by Om Shrivastava on 01-04-24
  // Reason : This usestate data is not used yet
  // Addition by Om Shrivastava on 11-02-24
  // Reason : Need to set the live video data
  const [liveVideo, setLiveVideo] = useState(null);
  // End of addition by Om Shrivastava on 11-02-24
  // Reason : Need to set the live video data

  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalCurrentRoute,
    setGlobalCurrentRoute,
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    isHomePageLoader,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To add context variable of donation
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To add context variable of donation
  } = useContext(GlobalContext);
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // Commented this code by Om Shrivastava on 01-04-24
    // Reason : This method is not used
    // getDetails();
    // End of Commented this code by Om Shrivastava on 01-04-24
    // Reason : This method is not used
    // Addition by Om Shrivastava on 11-02-24
    // Reason : Need to set the live video data
    getGalleryData();
    // End of addition by Om Shrivastava on 11-02-24
    // Reason : Need to set the live video data
    // Added by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    // End of code by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variable to empty in mobile number and list
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variable to empty in mobile number and list
  }, []);
  // Commented this code by Om Shrivastava on 01-04-24
  // Reason : This method is not used
  //Added by - Ashlekh on 14-01-2024
  //Reason - To get all details
  // const getDetails = async () => {
  //   const response = await getAllDetailsApi();

  //   // if (response?.website_contents != null) {
  //   setBackgroundImage(response?.website_contents?.background_image);
  //   setMarqueeText(response?.website_contents?.banner_text);
  //   setGodDetails(response?.details_of_gods);
  //   setCoverPhotos(response?.cover_photos);
  //   setHomePagePhotos(response?.home_page_photos);
  //   setHomeContent(response?.website_contents?.home_content);
  //   setMandirMemberDetails(response?.mandir_members);
  //   setHomePageDescription(response?.website_contents?.description);
  // };
  // }
  //End of code addition by - Ashlekh on 14-01-2024
  //Reason - To get all details
  // End of commented this code by Om Shrivastava on 01-04-24
  // Reason : This method is not used
  // Addition by Om Shrivastava on 11-02-24
  // Reason : Get the gallery details data
  const getGalleryData = async () => {
    const response = await getGalleryContentsApi();

    if (response?.live_video != null) {
      setLiveVideo(response?.live_video);
    }
  };
  // End of addition by Om Shrivastava on 11-02-24
  // Reason : Get the gallery details data

  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${homeStyle.pageFrame}`}>
      {/* Addition by Om Shrivastava on 04-04-24
      Reason : Need to add loader in this page  */}
      {isHomePageLoader ? (
        <div
          className={`${homeStyle.loaderDiv}`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "93vh",
          }}
        >
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      ) : (
        //       End of addition by Om Shrivastava on 04-04-24
        // Reason : Need to add loader in this page
        <div className={`${homeStyle.coloredBackground}`}>
          {/* End of code addition by - Ashish Dewangan on 01-02-2024
      Reason - To have background image */}
          <div className={`${homeStyle.pageContainer}`}>
            {/* Added by - Ashlekh on 14-01-2024
          Reason -  For different contents in home page*/}
          {marqueeText.length>0?
            <div className={`${homeStyle.marqueeContainer}`}>
              {/* Modification and addition by Om Shrivastava on 22-01-24
            Reason : Need to fix some bugs marquee tag */}
              {/* <marquee
              className={`${homeStyle.marqueeText}`}
              // behavior="scroll" direction="left" style={{ color: "black" }}
            >
              {marqueeText}
            </marquee> */}
              <Marquee>{marqueeText}</Marquee>
              {/* End of modification and addition by Om Shrivastava on 22-01-24
            Reason : Need to fix some bugs marquee tag */}
            </div>
            :<div style={{fontSize:'13px'}}> OK</div>}
            {/* Code commented and added by - Ashlekh on 18-01-2024
          Reason - To import GodsPhotoContainer */}
            {/* <div className={`${homeStyle.godsPhotoContainer}`}>
            {godDetails?.map((god, index) => (
              <div key={index} className={`${homeStyle.godsPhotoSubContainer}`}>
                <img className={`${homeStyle.godImage}`} src={`${config.baseUrl}${god.image}`} alt="" />
                <br />
                <p className={`${homeStyle.godName}`}>{god.name}</p>
              </div>
            ))}
            <hr />
          </div> */}
            <GodsPhotoContainer godDetails={godDetails} />
            {/* End of comment by - Ashlekh on 18-01-2024
          Reason - To import GodsPhotoContainer */}

            {/* Code commented by - Ashlekh on 18-01-2024
          Reason - To import CoverImage */}
            {/* <div className={`${homeStyle.coverImageContainer}`}>
            <div className={`${homeStyle.sliderContainer}`}>

              <Carousel autoPlay={true} transitionTime={1} infiniteLoop={true}>
                {coverPhotos.map(e =>
                  <Carousel.Item key={e.id}>
                    <div className={`${homeStyle.imageDiv}`}>
                      <img className={`${homeStyle.image}`} src={config.baseUrl + e.photo} alt="" />
                    </div>
                  </Carousel.Item>
                )}

              </Carousel>
            </div>
          </div> */}
            {coverPhotos.length > 0 && <CoverImage coverPhotos={coverPhotos} />}
            {/* End of comment by - Ashlekh on 18-01-2024
          Reason - To import CoverImage */}

            {/* Added by - Ashlekh on 17-01-2024
          Reason - To add home page photos & contents */}
            {/* <div className={`${homeStyle.photoAndContentContainer}`}>
            <div className={`${homeStyle.sliderContent}`}>
              <Carousel autoPlay={true} transitionTime={1} infiniteLoop={true}>
                {homePagePhotos.map(photo => (
                  <Carousel.Item key={photo.id}>
                    <img src={config.baseUrl + photo.photo} alt="" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className={`${homeStyle.contentContainer}`}>
              {parse(
                homeContent != null && homeContent.length > 0
                  ? homeContent
                  : ""
              )}
              {(homeContent == null || homeContent.length == 0) && (
                <div className={`${homeStyle.noContentFoundText}`}>
                  {Constants.dataNotFoundText}
                </div>
              )}
            </div>
          </div> */}
            {/* Addition by Om Shrivastava on 11-02-24
          Reason : Need to add the live video section  */}
            {/* Condition added by Om Shrivastava on 14-02-24
          Reason : Need to add the condition when data is null */}
            {liveVideo != null || liveVideo != undefined ? (
              <LiveVideoContainer liveVideo={liveVideo} />
            ) : null}
            {/* Condition added by Om Shrivastava on 14-02-24
          Reason : Need to add the condition when data is null */}
            {/* End of addition by Om Shrivastava on 11-02-24
          Reason : Need to add the live video section  */}

            <HomePagePhotosContents
              homePagePhotos={homePagePhotos}
              homeContent={homeContent}
            />
            {/* End of code addition by - Ashlekh on 17-01-2024
          Reason - To add home page photos & contents */}

            {/* Added by - Ashlekh on 17-01-2024
          Reason - For mandir members */}
            {/* <div className={`${homeStyle.mandirMembersContainer}`}>
            <div>

            </div>
          </div> */}
            <MandirMembers mandirMemberDetails={mandirMemberDetails} />
            <HomePageDescription homePageDescription={homePageDescription} />
            {/* End of code addition by - Ashlekh on 14-01-2024
        Reason - For different contents in home page */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
