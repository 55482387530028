/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have gallery page
 */
import { useContext, useEffect, useState } from "react";
import { getAllDetailsApi, getGalleryContentsApi } from "../../Api/services";
import galleryStyle from "./Gallery.module.css";
import ImagesContainer from "./ImagesContainer";
import LiveVideoContainer from "./LiveVideoContainer";
import Constants from "../../constants/Constants";
import VideosContainer from "./VideosContainer";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/Context";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [liveVideo, setLiveVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalCurrentRoute,
    setGlobalCurrentRoute,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);
  const location = useLocation();
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context

  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    getGalleryData();
    // Added by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    // End of code by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  const getGalleryData = async () => {
    /**
     * Modified by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */
    // const response = await getAllDetailsApi();
    const response = await getGalleryContentsApi();
    /**
     * End of modification by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */

    if (
      response?.gallery_images != null &&
      response?.gallery_images?.length > 0
    ) {
      setImages(response?.gallery_images);
    }
    if (
      response?.gallery_videos != null &&
      response?.gallery_videos?.length > 0
    ) {
      setVideos(response?.gallery_videos);
    }
    if (response?.live_video != null) {
      setLiveVideo(response?.live_video);
    }
    /**
     * Added by - Ashish Dewangan on 22-01-2024
     * Reason - To hide loader after api gives the response
     */
    setIsLoading(false);
    /**
     * End of code addition by - Ashish Dewangan on 22-01-2024
     * Reason - To hide loader after api gives the response
     */
  };
  return (
    /**
     * Added by - Ashish Dewangan on 31-01-2024
     * Reason - To provide background image
     */
    <div className={`${galleryStyle.pageFrame}`}>
      <div className={`${galleryStyle.coloredBackground}`}>
        {/* End of addition by - Ashish Dewangan on 31-01-2024
      Reason - To provide background image */}

        <div className={`${galleryStyle.pageContainer}`}>
          {" "}
          <div className={`${galleryStyle.title}`}>
            {Constants.galleryTitleText}
          </div>
          {/* Modified by - Ashish Dewangan on 22-01-2024
      Reason - To show loading image while data is being fetched from api */}
          {/* <div className={`${galleryStyle.content}`}>
          {images.length > 0 || videos.length > 0 || liveVideo != null ? (
            <div>
              {images.length > 0 && <ImagesContainer images={images} />}
              {liveVideo != null && (
                <LiveVideoContainer liveVideo={liveVideo} />
              )}
              {videos.length > 0 && <VideosContainer videos={videos} />}
            </div>
          ) : (
            <div className={`${galleryStyle.noContentFoundText}`}>
              {Constants.dataNotFoundText}
            </div>
          )}
        </div> */}
          {isLoading ? (
            <div
              className={`${galleryStyle.content}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "90vh",

              }}
            >
              {/* Modified by - Ashish Dewangan on 31-01-2024
          Reason - Changed loader from diya to adyant cloud */}
              {/* <img src="diya.gif" /> */}
              <img style={{ height: "30vh" }}   src="adyant_loader.gif" />
              {/* End of modification by - Ashish Dewangan on 31-01-2024
          Reason - Changed loader from diya to adyant cloud */}
            </div>
          ) : (
            <div className={`${galleryStyle.content}`}>
              {images.length > 0 || videos.length > 0 || liveVideo != null ? (
                <div>
                  {images.length > 0 && <ImagesContainer images={images} />}
                  {/* Commented by Om Shrivastava on 11-02-24
                  Reason : Need to remove the live video component  */}
                  {/* {liveVideo != null && (
                    <LiveVideoContainer liveVideo={liveVideo} />
                  )} */}
                  {/* End of commented by Om Shrivastava on 11-02-24
                  Reason : Need to remove the live video component  */}
                  {videos.length > 0 && <VideosContainer videos={videos} />}
                </div>
              ) : (
                <div className={`${galleryStyle.noContentFoundText}`}>
                  {Constants.dataNotFoundText}
                </div>
              )}
            </div>
          )}
          {/* End of modification by - Ashish Dewangan on 22-01-2024
      Reason - To show loading image while data is being fetched from api */}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
