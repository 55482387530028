/**
 * Created by - Ashlekh on 12-02-2024
 * Reason - To have Temple Trustee page
 */
import React, { useContext, useEffect, useState } from "react";
import templeTrusteeStyle from "./TempleTrustee.module.css";
import { getTempleTrusteeDetailsApi } from "../../Api/services";
import Constants from "../../constants/Constants";
import config from "../../Api/config";
import parse from "html-react-parser";
import { GlobalContext } from "../../context/Context";
import { useLocation } from "react-router-dom";

const TempleTrustee = () => {
  const [templeTrusteeDetails, setTempleTrusteeDetails] = useState("");
  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalCurrentRoute,
    setGlobalCurrentRoute,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);
  const location = useLocation();
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context
  // Added by - Ashlekh on 17-02-2024
  // Reason - For applying loader
  const [isLoading, setIsLoading] = useState(true);
  // End of code - Ashlekh on 17-02-2024
  // Reason - For applying loader
  useEffect(() => {
    window.scrollTo(0, 0);
    getTempleTrusteeDetails();
    // Added by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    // End of code by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  const getTempleTrusteeDetails = async () => {
    const response = await getTempleTrusteeDetailsApi();
    if (response != null) {
      setTempleTrusteeDetails(response?.temple_trustees);
    }
    // Added by - Ashlekh on 17-02-2024
    // Reason - If response comes from api then loader will not appear
    setIsLoading(false);
    // End of code - Ashlekh on 17-02-2024
    // Reason - If response comes from api then loader will not appear
  };
  // console.log("templeTrusteeDetailssss", templeTrusteeDetails);

  return (
    <div className={`${templeTrusteeStyle.pageFrame}`}>
      <div className={`${templeTrusteeStyle.coloredBackground}`}>
        <div className={`${templeTrusteeStyle.pageContainer}`}>
          {" "}
          <div className={`${templeTrusteeStyle.title}`}>
            {Constants.templeTrusteeTitle}
          </div>
          {/* Added by - Ashlekh on 17-02-2024
          Reason - For adding loader */}
          {isLoading ? (
            <div
              className={`${templeTrusteeStyle.content}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "90vh",

              }}
            >
              <img style={{ height: "30vh" }}   src="adyant_loader.gif" />
            </div>
          ) : (
            // End of code - Ashlekh on 17-02-2024
            // Reason - For adding loader
            <div>
              {templeTrusteeDetails !== null &&
              templeTrusteeDetails?.length > 0 ? (
                templeTrusteeDetails?.map((templeTrustee, index) => (
                  <div key={index}>
                    {/* {console.log("templeTrustee", templeTrustee)} */}
                    {window.innerWidth > 720 ? (
                      <div className={`${templeTrusteeStyle.mainContainer}`}>
                        <div
                          className={`${templeTrusteeStyle.imageContainer}`}
                          style={{ order: index % 2 === 0 ? 0 : 1 }}
                        >
                          <div
                            className={`${
                              templeTrusteeStyle.imageSubContainer
                            } ${
                              index % 2 === 0
                                ? templeTrusteeStyle.even
                                : templeTrusteeStyle.odd
                            }`}
                          >
                            <img
                              className={`${templeTrusteeStyle.image}`}
                              src={
                                templeTrustee.image != null &&
                                templeTrustee.image.trim() != "" &&
                                templeTrustee?.image?.length > 1
                                  ? `${config.baseURL}${templeTrustee.image}`
                                  : // Code changed by - Ashlekh on 23-02-2024
                                    // Reason - To change default image
                                    // : `${`default_mandir_member.jpg`}`
                                    `${`dummy-user.png`}`
                                // End of code - Ashlekh on 23-02-2024
                                // Reason - To change default image
                              }
                              alt=""
                            />
                            <div
                              className={`${templeTrusteeStyle.templeTrusteeNameAndDesignation} ${templeTrusteeStyle.templeTrusteeName}`}
                            >
                              {templeTrustee?.name}
                            </div>
                            <div
                              className={`${templeTrusteeStyle.templeTrusteeNameAndDesignation} ${templeTrusteeStyle.templeTrusteeDesignation}`}
                            >
                              {templeTrustee?.designation}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${templeTrusteeStyle.descriptionContainer}`}
                          style={{ order: index % 2 === 0 ? 1 : 0 }}
                        >
                          <div
                            className={`${templeTrusteeStyle.descriptionSubContainer}`}
                          >
                            {parse(
                              templeTrustee?.description != null &&
                                templeTrustee?.description?.length > 0
                                ? // Modification and commented by Om shrivastava on 18-03-24
                                  // Reason : Need to add this section in div
                                  // templeTrustee.description
                                  `<div >
                                    ${templeTrustee.description}
                                  </div>`
                                : // End of modification and commented by Om shrivastava on 18-03-24
                                  // Reason : Need to add this section in div
                                  ""
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={`${templeTrusteeStyle.mainContainer}`}>
                        <div className={`${templeTrusteeStyle.imageContainer}`}>
                          <div
                            className={`${
                              templeTrusteeStyle.imageSubContainer
                            } ${
                              index % 2 === 0
                                ? templeTrusteeStyle.even
                                : templeTrusteeStyle.odd
                            }`}
                          >
                            <img
                              className={`${templeTrusteeStyle.image}`}
                              src={
                                templeTrustee.image != null &&
                                templeTrustee.image.trim() != "" &&
                                templeTrustee?.image?.length > 1
                                  ? `${config.baseURL}${templeTrustee.image}`
                                  : // Code changed by - Ashlekh on 30-03-2024
                                    // Reason - To change mandir member page in mobile view
                                    // : `${`default_mandir_member.jpg`}`
                                    `${`dummy-user.png`}`
                                // End of code - Ashlekh on 30-03-2024
                                // Reason - To change mandir member page in mobile view
                              }
                              alt=""
                            />
                            <div
                              className={`${templeTrusteeStyle.templeTrusteeNameAndDesignation} ${templeTrusteeStyle.templeTrusteeName}`}
                            >
                              {templeTrustee?.name}
                            </div>
                            <div
                              className={`${templeTrusteeStyle.templeTrusteeNameAndDesignation} ${templeTrusteeStyle.templeTrusteeDesignation}`}
                            >
                              {templeTrustee?.designation}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${templeTrusteeStyle.descriptionContainer}`}
                        >
                          <div
                            className={`${templeTrusteeStyle.descriptionSubContainer}`}
                          >
                            {parse(
                              templeTrustee?.description != null &&
                                templeTrustee?.description?.length > 0
                                ? // Modification and commented by Om shrivastava on 18-03-24
                                  // Reason : Need to add this section in div
                                  // templeTrustee.description
                                  `<div >
                                    ${templeTrustee.description}
                                  </div>`
                                : // End of modification and commented by Om shrivastava on 18-03-24
                                  // Reason : Need to add this section in div
                                  ""
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className={`${templeTrusteeStyle.noContentFoundText}`}>
                  {Constants.dataNotFoundText}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TempleTrustee;
