/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have container to hold videos
 */

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ReactPlayer from "react-player";

import videosContainerStyle from "./VideosContainer.module.css";
import Constants from "../../constants/Constants";

const VideosContainer = (props) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 721 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div>
      <hr className={`${videosContainerStyle.horizontalLine}`} />
      <div className={`${videosContainerStyle.subTitle}`}>
        {/* Modified by - Ashish Dewangan on 03-02-2024
        Reason - To get text label from constants file */}
        {/* {"वीडियो"} */}
        {Constants.VIDEO_TEXT}
        {/* End of modification by - Ashish Dewangan on 03-02-2024
        Reason - To get text label from constants file */}
      </div>
      <div className={`${videosContainerStyle.videosContainer} `}>
        {/* Modified by - Ashish Dewangan on 21-01-2024
        Reason - react-grid-Carousel library was taking too much time to load the images */}
        {/* <Carousel cols={4} rows={1} gap={0} showDots={true} autoplay={true}> */}
        <Carousel responsive={responsive}>
          {props?.videos.map((videoData, index) => {
            return (
              // <Carousel.Item key={index}>
              <div key={index}>
                <ReactPlayer
                  className={`${videosContainerStyle.video}`}
                  url={videoData?.video}
                  controls={true}
                  width={290}
                  height={175}
                  style={{ margin: "15px auto" }}
                />
              </div>
            );
          })}
        </Carousel>
        {/* </Carousel> */}
        {/* End of code addition by - Ashish Dewangan on 21-01-2024
        Reason - react-grid-Carousel library was taking too much time to load the images */}
      </div>
    </div>
  );
};

export default VideosContainer;
