/**
 * Created by - Ashish Dewangan on 24-01-2024
 * Reason - To have component that will contain image indicating flow of jyot booking
 */

import donationFlowConatainer from "./DonationFlowContainer.module.css";

const DonationFlowConatiner = () => {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;

  return (
    <div
      className={`${donationFlowConatainer.contactUsDetailsContainer}`}
      // style={{border:'1px solid red'}}
    >
      <div className={`${donationFlowConatainer.imageContainer}`}>
        <img
          className={`${donationFlowConatainer.image}`}
          // src={jyotFlowImg}
          // Modification and addition by Om Shrivastava on 02-02-24
          // Reason : Need to to change the image
          // src="formpath.jpg"
          // Modification and addition by Om Shrivastava on 16-02-24
          // Reason : Need to change the donation form path
          // src="formpath.png"
          // Modification and addition by Om Shrivastava on 16-02-24
          // Reason : Need to change the donation form path
          // src="donation_form_path.png"
          src="donation_path.jpeg"
          // End of modification and addition by Om Shrivastava on 16-02-24
          // Reason : Need to change the donation form path
          // End of modification and addition by Om Shrivastava on 16-02-24
          // Reason : Need to change the donation form path

          // End of Modification and addition by Om Shrivastava on 02-02-24
          // Reason : Need to to change the image

          alt=""
        />
      </div>

      <div className={`${donationFlowConatainer.parentCollapse}`}>
        <div style={{ display: "flex" }}>
          {/* <Collapsible
          className={`${donationFlowConatainer.collapseContent}`}
          trigger="पूजा फॉर्म "
        > */}
          <img
            className={`${donationFlowConatainer.image}`}
            // src={jyotFlowImg}
            // Modification and addition by Om Shrivastava on 16-02-24
            // Reason : Need to change the donation form path
            // src="form_path_mobile_view.png"
            // Modification and addition by Om Shrivastava on 28-06-2024
            // Reason : Add the new donation image in phone view
            // src="donation_form_path_mobile_view.png"
            src="donation_form_mobile_view.jpeg"
            // End of modification and addition by Om Shrivastava on 28-06-2024
            // Reason : Add the new donation image in phone view

            // End of modification and addition by Om Shrivastava on 16-02-24
            // Reason : Need to change the donation form path

            alt=""
          />
          {/* </Collapsible> */}
          {/* <div style={{fontWeight:'bold',fontSize:'13px'}}>
            {currentDate}
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default DonationFlowConatiner;
