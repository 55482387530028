/**
 * Created by - Ashish Dewangan on 24-01-2024
 * Reason - To have component that will contain image indicating flow of jyot booking
 */

import jyotFlowContainerStyle from "./JyotFlowContainer.module.css";

const JyotFlowContainer = () => {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;

  return (
    <div
      className={`${jyotFlowContainerStyle.contactUsDetailsContainer}`}
      // style={{border:'1px solid red'}}
    >
      <div className={`${jyotFlowContainerStyle.imageContainer}`}>
        <img
          className={`${jyotFlowContainerStyle.image}`}
          // src={jyotFlowImg}
          // Modification and addition by Om Shrivastava on 02-02-24
          // Reason : Need to to change the image
          // src="formpath.jpg"
          // Code changed by - Ashlekh on 07-04-2024
          // Reason - To change tool tip image
          // src="formpath.png"
          // Code changed by - Ashlekh on 24-05-2024
          // Reason - To change tool tip
          // src="jyotformpath.png"
          src="formpath.png"
          // End of code - Ashlekh on 24-05-2024
          // Reason - To change tool tip
          // End of code - Ashlekh on 07-04-2024
          // Reason - To change tool tip image
          // End of Modification and addition by Om Shrivastava on 02-02-24
          // Reason : Need to to change the image

          alt=""
        />
      </div>

      <div className={`${jyotFlowContainerStyle.parentCollapse}`}>
        <div style={{ display: "flex" }}>
          {/* <Collapsible
          className={`${jyotFlowContainerStyle.collapseContent}`}
          trigger="पूजा फॉर्म "
        > */}
          <img
            className={`${jyotFlowContainerStyle.image}`}
            // src={jyotFlowImg}
            // Modification and addition by Om Shrivastava on 16-02-24
            // Reason : Need to add the image, where adding is also show
            // src="form_path_mobile_view.png"
            // Code changed by - Ashlekh on 09-04-2024
            // Reason - To change jyot tool tip image
            // src="form_path_mobile_view.jpg"
            // Code changed by - Ashlekh on 24-05-2024
            // Reason - To change tool tip image in mobile view
            // src="jyotformpath_mobile_view.png"
            src="form_path_mobile_view.jpg"
            // End of code - Ashlekh on 24-05-2024
            // Reason - To change tool tip image in mobile view
            // End of code - Ashlekh on 09-04-2024
            // Reason - To change jyot tool tip image
            // End of Modification and addition by Om Shrivastava on 16-02-24
            // Reason : Need to add the image, where adding is also show

            alt=""
          />
          {/* </Collapsible> */}
          {/* <div style={{fontWeight:'bold',fontSize:'13px'}}>
            {currentDate}
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default JyotFlowContainer;
