/**
 * Created by - Ashlekh on 19-01-2024
 * Reason - To have home page description
 */

import { useEffect, useState } from "react";
import { getAllDetailsApi } from "../../Api/services";

import homePageDescriptionStyle from "./homePageDescription.module.css";
import parse from "html-react-parser";
import Constants from "../../constants/Constants";

const HomePageDescription = (props) => {
  // console.log("props@homePageDescription", props);
  useEffect(() => {}, []);
  return (
    <div className={`${homePageDescriptionStyle.content}`}>
      {parse(
        props.homePageDescription != null &&
          props.homePageDescription.length > 0
          ? // Modification and commented by Om shrivastava on 18-03-24
            // Reason : Need to add this section in div
            // props.homePageDescription
            `<div >
                    ${props.homePageDescription}
                  </div>`
          : // End of modification and commented by Om shrivastava on 18-03-24
            // Reason : Need to add this section in div
            ""
      )}
      {(props.homePageDescription == null ||
        props.homePageDescription.length == 0) && (
        <div className={`${homePageDescriptionStyle.noContentFoundText}`}>
          {/* {Constants.noContentFoundText} */}
        </div>
      )}
    </div>
  );
};

export default HomePageDescription;
