/**
 * Created by - Ashlekh on 18-01-2024
 * Reason - For Details of God container
 */

import { useEffect, useState } from "react";
import { getAllDetailsApi } from "../../Api/services";

import godsPhotoContainerStyle from "./godsPhotoContainer.module.css";
import parse from "html-react-parser";
import config from "../../Api/config";
// import config from "../../Api/config";

import Constants from "../../constants/Constants";
import { Link } from "react-router-dom";
// import Carousel from "react-grid-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const GodsPhotoContainer = (props) => {
  const responsive = {
    // superLargeDesktop: {
    //   breakpoint: { max: 4000, min: 3000 },
    //   items: 10,
    //   slidesToSlide: 10,
    // },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 11,
      slidesToSlide: 11,
    },
    tablet: {
      breakpoint: { max: 1024, min: 721 },
      items: 6,
      slidesToSlide: 6,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 3,
      slidesToSlide: 3,
    },
  };

  const [godDetails, setGodDetails] = useState([]);

  // useEffect(() => {
  //   getDetails();
  // }, []);

  // const getDetails = async () => {
  //   const response = await getAllDetailsApi();
  //   // console.log("response@GodsPhotoContainer", response);
  //   if (response != null) {
  //     setGodDetails(response?.details_of_gods);
  //   }
  // }

  return (
    // <div className={`${godsPhotoContainerStyle.godsPhotoContainer}`}>
    /**
     * Modified by - Ashish Dewangan on 29-01-2024
     * Reason - To give the div a container class for styling
     */
    // <div>
    <div className={`${godsPhotoContainerStyle.container}`}>
      {/* Modified by - Ashish Dewangan on 29-01-2024
     Reason - To give the div a container class for styling */}

      {/* <Carousel
        cols={5}
        rows={1}
        gap={40}
        // showDots={true}
        autoplay={true}
      > */}
      {/* Addition by Om Shrivastava on 11-02-24
            Reason : Need to add the label for khallari darsan */}
      {/* Addtion by Om Shrivastava on 12-04-24
            Reason : Add the condition when the length of goddetails is greater than 0 then text is show, another wise not show */}
      {props?.godDetails?.length > 0 ? (
        <div
          className={`${godsPhotoContainerStyle.khallariHeading}`}
          // style={{fontWeight:'bold',paddingBottom:'7%',paddingTop:'2%',paddingRight:'15%'}}
        >
          {" "}
          {Constants.KHALLARI_DARSHAN_TEXT} :-
        </div>
      ) : null}
      {/* End of addtion by Om Shrivastava on 12-04-24
            Reason : Add the condition when the length of goddetails is greater than 0 then text is show, another wise not show */}
      {/* End of addition by Om Shrivastava on 11-02-24
            Reason : Need to add the label for khallari darsan */}

      <Carousel responsive={responsive}>
        {/* {godDetails?.map((god, index) => ( */}
        {props?.godDetails.map((god, index) => (
          // <Carousel.Item key={index}>
          <div
            key={index}
            className={`${godsPhotoContainerStyle.godsPhotoSubContainer}`}
          >
            {/* End of Addition by Om Shrivastava on 11-02-24
            Reason : Need to add the label for khallari darsan   */}
            <Link to={`/about_god`} state={god.id}>
              <img
                className={`${godsPhotoContainerStyle.godImage}`}
                src={`${config.baseURL}${god.image}`}
                alt=""
              />
            </Link>
            {/* <br /> */}
            <p className={`${godsPhotoContainerStyle.textName}`}>{god.name}</p>
          </div>
          // </Carousel.Item>
        ))}
      </Carousel>
      {/* <hr/> */}
      {/* Code commented and added by - Ashlekh on 04-02-2024
        Reason - When there is no Gods image then horizontal line will not display */}
      {/* <div style={{borderBottom: "1px solid #C0C0C0", paddingTop: "10px"}}></div> */}
      {props?.godDetails?.length > 0 ? (
        <div
          style={{ borderBottom: "1px solid #C0C0C0", paddingTop: "10px" }}
        ></div>
      ) : null}
      {/* End of comment by - Ashlekh on 04-02-2024
      Reason - When there is no Gods image then horizontal line will not display */}
    </div>
  );
};

export default GodsPhotoContainer;
