/**
 * Created by - Ashlekh on 29-03-2024
 * Reason - For donation receipt history section
 */

import { useContext, useEffect, useState } from "react";
import donationReceiptHistory from "./donationReceiptHistory.module.css";
import Constants from "../../constants/Constants";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { getDonationReceiptHistoryApi } from "../../Api/services";
import { GlobalContext } from "../../context/Context";

const DonationReceiptHistory = () => {
  const [donationReceiptList, setDonationReceiptList] = useState("");
  const location = useLocation();
  let [validationMsg, setValidationMsg] = useState("");
  var {
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalCurrentRoute,
    setGlobalCurrentRoute,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (globalCurrentRoute !== "/donation_receipt") {
      setGlobalDonationReceiptList([]);
      setGlobalDonationReceiptHistoryMobileNumber("");
      setGlobalCurrentRoute(location.pathname);
    }
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);
  const formik = useFormik({
    initialValues: {
      contact_number:
        globalDonationReceiptHistoryMobileNumber === ""
          ? ""
          : globalDonationReceiptHistoryMobileNumber,
    },

    onSubmit: (values) => {
      submitMobileNumber(values);
    },
    validationSchema: yup.object({
      contact_number: yup
        .number()
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .integer()
        .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें।")
        .required("कृपया मोबाइल नंबर दर्ज करें।")
        .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें।")
        .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें।"),
    }),
  });
  async function submitMobileNumber(values) {
    const requestData = {
      contact_number: values.contact_number,
    };
    const response = await getDonationReceiptHistoryApi(values.contact_number);
    setGlobalDonationReceiptList(response?.donation_receipt_history);
    setGlobalDonationReceiptHistoryMobileNumber(values?.contact_number);

    setDonationReceiptList(response?.donation_receipt_history);

    if (response.message) {
      setValidationMsg(response.message);
    } else if (response.donation_receipt_history) {
      setValidationMsg("");
    }
  }

  function handleChange(e) {
    setValidationMsg("");
  }
  return (
    <div className={`${donationReceiptHistory.pageFrame}`}>
      <div className={`${donationReceiptHistory.coloredBackground}`}>
        <div className={`${donationReceiptHistory.pageContainer}`}>
          <div className={`${donationReceiptHistory.title}`}>
            {Constants.donationReceiptTitleText}
          </div>
          <div className={`${donationReceiptHistory.subTitleText}`}>
            {Constants.donationReceiptSubtitleText}
          </div>
          <form
            id="form"
            onSubmit={formik.handleSubmit}
            noValidate={true}
            onChange={(e) => handleChange(e)}
          >
            <div className={donationReceiptHistory.inputHolder}>
              <label className={donationReceiptHistory.label}>
                <span style={{ color: "red" }}>*</span>
                {" " + Constants.receiptMobileNumber + " : "}
              </label>
              <div className={donationReceiptHistory.inputWithError}>
                <input
                  name="contact_number"
                  id="contact_number"
                  pattern="[1-9]{1}[0-9]{9}"
                  {...formik.getFieldProps("contact_number")}
                  className={donationReceiptHistory.inputBox}
                />
                {formik.touched.contact_number &&
                formik.errors.contact_number ? (
                  <span
                    className={`${donationReceiptHistory.validationMsgContainer}`}
                  >
                    {formik.errors.contact_number}
                  </span>
                ) : null}
                {
                  (validationMsg = !null ? (
                    <span
                      className={`${donationReceiptHistory.validationMsgContainer}`}
                      style={{ color: "red" }}
                    >
                      {validationMsg}
                    </span>
                  ) : null)
                }
              </div>
            </div>
            <div className={`${donationReceiptHistory.buttonContainer}`}>
              <label
                className={`${donationReceiptHistory.buttonSubContainer1}`}
              >
                <span></span>
              </label>
              <button
                className={`${donationReceiptHistory.button}`}
                type="submit"
              >
                {Constants.submitTextReceiptHistory}
              </button>
            </div>
          </form>
          {globalDonationReceiptList != null &&
          globalDonationReceiptList?.length > 0 ? (
            <div className={`${donationReceiptHistory.tableContainer}`}>
              <table
                style={{
                  width: "600px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "collapse",
                  border: "1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "80px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {Constants.viewTextReceiptHistory}
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "80px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {Constants.receiptNumberTextReceiptHistory}
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "150px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {Constants.nameTextReceiptHistory}
                    </th>

                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "80px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {Constants.paymentStatusTextReceiptHistory}
                    </th>

                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "100px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      मोबाइल नंबर
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(globalDonationReceiptList)?.map(
                    (item, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            padding: "4px 2px",
                          }}
                        >
                          <Link
                            to={"/donation_receipt"}
                            state={item}
                            className={`${donationReceiptHistory.tableButton}`}
                          >
                            {Constants.viewTextReceiptHistory}
                          </Link>
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            padding: "4px 2px",
                          }}
                        >
                          {item.receipt_number}
                        </td>
                        <td
                          style={{
                            wordBreak: "break-all",
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.name}
                        </td>

                        <td
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.payment_status}
                        </td>
                        <td
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {item.contact_number}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DonationReceiptHistory;
