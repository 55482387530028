/**
 * Created by - Ashlekh on 18-01-2024
 * Reason - To import Home page photo
 */

import { useEffect, useState } from "react";
import { getAllDetailsApi } from "../../Api/services";

import homePagePhotosContentsStyle from "./homePagePhotosContents.module.css";
import parse from "html-react-parser";
import config from "../../Api/config";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Constants from "../../constants/Constants";

const HomePagePhotosContents = (props) => {
  const [homePagePhotos, setHomePagePhotos] = useState([]);
  const [homeContent, setHomeContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    // getDetails();
  }, []);

  // const getDetails = async () => {
  //   const response = await getAllDetailsApi();
  //   console.log("responseee", response);
  //   if(response != null) {
  //       setHomePagePhotos(response?.home_page_photos);
  //       setHomeContent(response?.website_contents?.home_content);
  //   }
  // }

  // Addition by Om Shrivastava on 24-01-24
  // Reason :Need to fix the resposiveness
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 721 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    // End of Addition by Om Shrivastava on 24-01-24
    // Reason :Need to fix the resposiveness
  };

  return (
    <div className={`${homePagePhotosContentsStyle.photoAndContentContainer}`}>
      <div className={`${homePagePhotosContentsStyle.sliderContent}`}>
        {/* <Carousel autoPlay={true} transitionTime={1} infiniteLoop={true}>
          {props?.homePagePhotos.map((photo) => (
            <Carousel.Item key={photo.id}>
              <img src={config.baseURL + photo.photo} alt="" />
            </Carousel.Item>
          ))}
        </Carousel> */}
        <Carousel
          responsive={responsive}
          showDots={true}
          // Added by - Ashlekh on 04-02-2024
          // Reason - In homePagePhotos if there is more than one image then arrow will display
          arrows={props?.homePagePhotos?.length > 1 ? true : false}
          // End of code addition by - Ashlekh on 04-02-2024
          // Reason - In homePagePhotos if there is more than one image then arrow will display
        >
          {props?.homePagePhotos.map((e, index) => {
            return (
              // <Carousel.Item key={index}>
              <div key={index}>
                <img
                  className={`${homePagePhotosContentsStyle.image}`}
                  src={config.baseURL + e.photo}
                  alt=""
                />
              </div>
              // </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      {/* <div className={`${homePagePhotosContentsStyle.contentContainer}`}>
              {parse(
                homeContent != null && homeContent.length > 0
                  ? homeContent
                  : ""
              )}
              {(homeContent == null || homeContent.length == 0) && (
                <div className={`${homePagePhotosContentsStyle.noContentFoundText}`}>
                  {Constants.dataNotFoundText}
                </div>
              )}
            </div> */}

      <div className={`${homePagePhotosContentsStyle.contentContainer}`}>
        {/* <p style={{border:'1px solid red'}}> */}
        {parse(
          props?.homeContent != null && props?.homeContent.length > 0
            ? // Modification and commented by Om shrivastava on 18-03-24
              // Reason : Need to add this section in div
              // props.homeContent
              `<div >
                ${props.homeContent}
              </div>`
            : // End of modification and commented by Om shrivastava on 18-03-24
              // Reason : Need to add this section in div
              ""
        )}
        {/* </p> */}
        {(props?.homeContent == null || props?.homeContent.length == 0) && (
          <div className={`${homePagePhotosContentsStyle.noContentFoundText}`}>
            {/* {Constants.dataNotFoundText} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomePagePhotosContents;
