/**
 * Created by - Ashlekh on 26-01-2024
 * Reason - For jyot receipt history section
 */

import { useContext, useEffect, useState } from "react";
import jyotReceiptHistory from "./jyotReceiptHistory.module.css";
import Constants from "../../constants/Constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import JyotReceipt from "../Receipt/JyotReceipt";
import {
  getJyotReceiptApi,
  getJyotReceiptHistoryApi,
} from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { GlobalContext } from "../../context/Context";

const JyotReceiptHistory = (props) => {
  const [jyotReceiptList, setJyotReceiptList] = useState("");
  {
    /* Addition by Om Shrivastava on 08-02-24
Reason : NEed to set the validation message */
  }
  let [validationMsg, setValidationMsg] = useState("");
  {
    /* End of addition by Om Shrivastava on 08-02-24
Reason : NEed to set the validation message */
  }
  const navigate = useNavigate();
  const location = useLocation();
  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalCurrentRoute,
    setGlobalCurrentRoute,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context
  useEffect(() => {
    window.scrollTo(0, 0);
    // getJyotReceiptHistory();
    // Added by - Ashlekh on 14-02-2024
    // Reason - For checking route

    if (globalCurrentRoute !== "/jyot_receipt") {
      setGlobalJyotReceiptList([]);
      setGlobalJyotReceiptHistoryMobileNumber("");
    }
    // End of code - Ashlekh on 14-02-2024
    // Reason - For checking route
    // Added by - Ashlekh on 29-03-2024
    // Reason - To store current route
    setGlobalCurrentRoute(location.pathname);
    // End of code - Ashlekh on 29-03-2024
    // Reason - To store current route
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  // const getJyotReceiptHistory = async () => {
  //   const response = await getJyotReceiptHistoryApi();
  //   console.log("response@JyotReceiptHistory", response);
  // };

  const formik = useFormik({
    initialValues: {
      // Code changed by - Ashlekh on 15-02-2024
      // reason - To add condition in contact_number
      // contact_number: "",
      contact_number:
        globalJyotReceiptHistoryMobileNumber === ""
          ? ""
          : globalJyotReceiptHistoryMobileNumber,
      // End of code - Ashlekh on 15-02-2024
      // Reason - To add condition in contact_number
    },

    onSubmit: (values) => {
      submitMobileNumber(values);
    },
    validationSchema: yup.object({
      contact_number: yup
        .number()
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .integer()
        .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें।")
        .required("कृपया मोबाइल नंबर दर्ज करें।")
        .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें।")
        .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें।"),
    }),
  });

  async function submitMobileNumber(values) {
    const requestData = {
      contact_number: values.contact_number,
    };
    const response = await getJyotReceiptHistoryApi(values.contact_number);
    // Added by - Ashlekh on 14-02-2024
    // Reason - to set all values in context variable
    setGlobalJyotReceiptList(response?.jyot_receipt_history);
    setGlobalJyotReceiptHistoryMobileNumber(values?.contact_number);
    // End of code - Ashlekh on 14-02-2024
    // Reason - to set all values in context variable
    setJyotReceiptList(response?.jyot_receipt_history);

    if (response.message) {
      // Commented by - Ashlekh on 10-02-2024
      // Reason - Form must not reset
      // formik.resetForm();
      // End of comment - Ashlekh on 10-02-2024
      // Reason - Form must not reset
      // Commented by - Ashlekh on 15-02-2024
      // Reason - Mobile number must not be empty when we click on submit
      // document.getElementById("form").reset();
      // End of comment by - Ashlekh on 15-02-2024
      // Reason - Mobile number must not be empty when we click on submit
      // notificationObject.success(response.message);
      setValidationMsg(response.message);
    } else if (response.jyot_receipt_history) {
      // notificationObject.error(response.error);
      // console.log('ppp')
      setValidationMsg("");
    }
  }

  function viewJyotReceipt() {}
  // Added by - Ashlekh on 19-02-2024
  // Reason - To clear validation message when user enters registered no
  function handleChange(e) {
    setValidationMsg("");
  }
  // End of code - Ashlekh on 19-02-2024
  // Reason - To clear validation message when user enters registered no
  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${jyotReceiptHistory.pageFrame}`}>
      <div className={`${jyotReceiptHistory.coloredBackground}`}>
        {/* End of code addition by - Ashish Dewangan on 01-02-2024
      Reason - To have background image */}
        <div className={`${jyotReceiptHistory.pageContainer}`}>
          <div className={`${jyotReceiptHistory.title}`}>
            {Constants.receiptTitleText}
          </div>
          <div className={`${jyotReceiptHistory.subTitleText}`}>
            {Constants.receiptSubTitleText}
          </div>
          {/* <div className={`${jyotReceiptHistory.inputMobileNumber}`}>
        {Constants.receiptMobileNumber}
        <input
          type="text"
          id="mobileNumber"
          value={mobileNumber}
          onChange={(e) => {
            if (e.target.value.length <= 10)
              setMobileNumber(e.target.value.toString());
          }}
        />
      </div>
      <div className={`${jyotReceiptHistory.submitButton}`}>
        <button >Submit</button>
      </div> */}
          <form
            id="form"
            onSubmit={formik.handleSubmit}
            noValidate={true}
            // Added by - Ashlekh on 19-02-2024
            // Reason - To clear validation message when user enters registered no
            onChange={(e) => handleChange(e)}
            // End of code - Ashlekh on 19-02-2024
            // Reason - To clear validation message when user enters registered no
          >
            <div className={jyotReceiptHistory.inputHolder}>
              <label className={jyotReceiptHistory.label}>
                <span style={{ color: "red" }}>*</span>
                {/* {" मोबाइल नंबर : "} */}
                {" " + Constants.receiptMobileNumber + " : "}
              </label>
              <div className={jyotReceiptHistory.inputWithError}>
                <input
                  name="contact_number"
                  id="contact_number"
                  pattern="[1-9]{1}[0-9]{9}"
                  {...formik.getFieldProps("contact_number")}
                  className={jyotReceiptHistory.inputBox}
                />
                {formik.touched.contact_number &&
                formik.errors.contact_number ? (
                  <span
                    className={`${jyotReceiptHistory.validationMsgContainer}`}
                  >
                    {formik.errors.contact_number}
                  </span>
                ) : null}
                {/* Addition by Om Shrivastava on 08-02-24
Reason : Need to set the validation message */}
                {
                  (validationMsg = !null ? (
                    <span
                      className={`${jyotReceiptHistory.validationMsgContainer}`}
                      style={{ color: "red" }}
                    >
                      {validationMsg}
                    </span>
                  ) : null)
                }
                {/* End of addition by Om Shrivastava on 08-02-24
Reason : Need to set the validation message */}
              </div>
            </div>
            <div className={`${jyotReceiptHistory.buttonContainer}`}>
              <label className={`${jyotReceiptHistory.buttonSubContainer1}`}>
                <span></span>
              </label>
              <button className={`${jyotReceiptHistory.button}`} type="submit">
                {/* {"सबमिट"} */}
                {Constants.submitTextReceiptHistory}
              </button>
            </div>
          </form>
          {/* <div>
        <div>Receipt Information</div>
        <table>
          <thead>
            <tr>
              <th>Receipt Number</th>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(mobileNumber)?.map((item, index) => (
              <tr key={index}>
                <td>{item.receipt_number}</td>
                <td>{item.name}</td>
                <td>{item.contact_number}</td>
                <td>{item.payment_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
          {/* Code changed by - Ashlekh on 14-02-2024
      Reason - To check values using context  */}
          {/* {jyotReceiptList != null && jyotReceiptList?.length > 0 ? ( */}
          {globalJyotReceiptList != null &&
          globalJyotReceiptList?.length > 0 ? (
            <div className={`${jyotReceiptHistory.tableContainer}`}>
              <table
                style={{
                  width: "600px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "collapse",
                  border: "1px solid black",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "80px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {/* रसीद देखें */}
                      {Constants.viewTextReceiptHistory}
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "80px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {/* रसीद नंबर */}
                      {Constants.receiptNumberTextReceiptHistory}
                    </th>
                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "150px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {/* नाम */}
                      {Constants.nameTextReceiptHistory}
                    </th>

                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "80px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {/* पेमेंट स्टेटस */}
                      {Constants.paymentStatusTextReceiptHistory}
                    </th>

                    <th
                      style={{
                        border: "1px solid black",
                        padding: "8px 2px",
                        width: "100px",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      मोबाइल नंबर
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Code changed by - Ashlekh on 14-02-2024
                  Reason - To map using context */}
                  {/* {Object.values(jyotReceiptList)?.map((item, index) => ( */}
                  {Object.values(globalJyotReceiptList)?.map((item, index) => (
                    // End of code - Ashlekh on 14-02-2024
                    // Reason - To map using context
                    <tr key={index} style={{ border: "1px solid black" }}>
                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          padding: "4px 2px",
                        }}
                      >
                        <Link
                          to={"/jyot_receipt"}
                          state={item}
                          className={`${jyotReceiptHistory.tableButton}`}
                        >
                          {/* रसीद देखें */}
                          {Constants.viewTextReceiptHistory}
                        </Link>
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                          padding: "4px 2px",
                        }}
                      >
                        {item.receipt_number}
                      </td>
                      <td
                        style={{
                          wordBreak: "break-all",
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item.name}
                      </td>

                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item.payment_status}
                      </td>
                      <td
                        style={{
                          border: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        {item.contact_number}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default JyotReceiptHistory;
