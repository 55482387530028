/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have a container to hold images
 */
import { useEffect, useState } from "react";

import imagesContainerStyle from "./ImagesContainer.module.css";
import config from "../../Api/config";
import { saveAs } from "file-saver";
import { HiDownload } from "react-icons/hi";
import Constants from "../../constants/Constants";

const ImagesContainer = (props) => {
  const [images, SetImages] = useState([]);

  const downloadImage = (url) => {
    saveAs(url, "image.jpg");
  };

  return (
    <div>
      <hr className={`${imagesContainerStyle.horizontalLine}`} />
      <div
        className={`${imagesContainerStyle.subTitle} ${imagesContainerStyle.extraBottomMargin}`}
      >
        {/* Modified by - Ashish Dewangan on 03-02-2024
        Reason - To get text label from constants file */}
        {/* {"फोटो"} */}
        {Constants.PHOTO_TEXT}
        {/* End of modification by - Ashish Dewangan on 03-02-2024
        Reason - To get text label from constants file */}
      </div>
      <div className={`${imagesContainerStyle.imagesContainer}`}>
        {props?.images.map((imageData, index) => {
          return imageData?.image?.length > 0 ? (
            <div key={index}>
              <img
                key={index}
                src={config.baseURL + imageData?.image}
                className={`${imagesContainerStyle.image}`}
              />
              {/* Added by - Ashish Dewangan on 14-01-2024
              Reason - Added download icon */}
              {/* Modified by - Ashish Dewangan on 31-01-2024
              Reason - Changed download icon */}
              {/* <IoMdDownload
              
              className={`${imagesContainerStyle.downloadIcon}`}
              onClick={(e) => {
                downloadImage(config.baseURL + imageData?.image);
              }}
            /> */}
              {/* End of modification by - Ashish Dewangan on 31-01-2024
              Reason - Changed download icon */}
              <HiDownload
                className={`${imagesContainerStyle.downloadIcon}`}
                onClick={(e) => {
                  downloadImage(config.baseURL + imageData?.image);
                }}
              />
              {/* <MdDownloadForOffline className={`${imagesContainerStyle.downloadIcon}`} style={{}} onClick={e=>{downloadImage(config.baseURL + imageData?.image)}}/> */}
              {/* End of code addition by - Ashish Dewangan on 14-01-2024
              Reason - Added download icon */}
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default ImagesContainer;
