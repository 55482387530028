/**
 * Created by - Ashlekh on 18-01-2024
 * Reason - To have mandir members section
 */

import { useEffect, useState } from "react";
import { getAllDetailsApi } from "../../Api/services";

import mandirMembersStyle from "./mandirMembers.module.css";
import parse from "html-react-parser";
import config from "../../Api/config";

// import Carousel from 'react-bootstrap/Carousel';
// import "../../../node_modules/bootstrap/dist/css/bootstrap.css"
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Carousel from "react-grid-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Constants from "../../constants/Constants";

const MandirMembers = (props) => {
  // console.log("props@mandirMembers", props);
  const responsive = {
    // superLargeDesktop: {
    //   // the naming can be any, depends on you.
    //   breakpoint: { max: 4000, min: 3000 },
    //   items: 5
    // },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  useEffect(() => {}, []);
  return (
    // <div className={`${mandirMembersStyle.mandirMembersContainer}`}>
    <div className={`${mandirMembersStyle.container}`}>
      {/* Added by - Ashlekh on 31-01-2024
      Reason - To apply null and length condition in mandirmembers */}
      {props?.mandirMemberDetails != null &&
      props?.mandirMemberDetails.length > 0 ? (
        <Carousel responsive={responsive}>
          {/* End of code addition by - Ashlekh on 31-01-2024
       Reason - To apply null and length condition in mandirmembers */}
          {/* <Carousel cols={4} rows={1} gap={50}> */}

          {props?.mandirMemberDetails?.map((mandirMember, index) => (
            // <div key={index} className={`${mandirMembersStyle.mandirMembersSubContainer}`}>
            //     <img className={`${mandirMembersStyle.memberImage}`}
            //         src={`${config.baseURL}${mandirMember.image}`} alt="" />
            //     <p className={`${mandirMembersStyle.memberName}`}>{mandirMember.name}</p>
            //     <p className={`${mandirMembersStyle.memberDesignation}`}>{mandirMember.description}</p>
            // </div>
            // <Carousel.Item key={index}>
            <div key={index}
              className={`${mandirMembersStyle.mandirMembersContainer}`}
              // style={{backgroundColor:`rgb(${index},${index},${index})`}}
            >
              <div
                // Code changed by - Ashlekh on 03-02-2024
                // Reason - To change colour by even & odd
                // className={`${mandirMembersStyle.mandirMembersSubContainer}`}
                className={`${mandirMembersStyle.mandirMembersSubContainer} ${index % 2 === 0 ? mandirMembersStyle.even : mandirMembersStyle.odd}`}
                // End of code by - Ashlekh on 03-02-2024
                // Reason - To change colour by even & odd

              >
                <div style={{display:'flex',justifyContent:'center',marginTop:'8%'}}>
                <img
                  className={`${mandirMembersStyle.memberImage}`}

                  // Code changed by - Ashlekh on 05-02-2024
                  // Reason - To display by default image
                  // src={`${config.baseURL}${mandirMember.image}`}
                  src={mandirMember.image != null && mandirMember.image.trim() != '' && mandirMember?.image?.length > 1 ? `${config.baseURL}${mandirMember.image}` : `${`default_mandir_member.jpg`}`}
                  // End of code addition by - Ashlekh on 05-02-2024
                  // Reason - To display by default image
                  alt=""
                />
                </div>
                {/* <p className={`${mandirMembersStyle.memberName}`}>
                {mandirMember.name}
              </p>
              <p className={`${mandirMembersStyle.memberDesignation}`}>
                {mandirMember.description}
              </p> */}
                <p className={`${mandirMembersStyle.memberNameAndDesignation}`}>
                  {mandirMember.name}
                </p>
                <p className={`${mandirMembersStyle.memberNameAndDesignation}`}>
                  {mandirMember.description}
                </p>
              </div>
            </div>
            // </Carousel.Item>
          ))}
          {/* </Carousel> */}
          {/* Added by - Ashlekh on 31-01-2024 */}
        </Carousel>
      ) : null}
      {/* End of code addition by - Ashlekh on 31-01-2024 */}
    </div>
  );
};

export default MandirMembers;
