/**
 * Created by - Om Shrivastava on 14-02-24
 * Reason - To have component that will contain scanner details
 */

import donationScannerConatainerStyle from "./DonationScannerContainer.module.css";
import Constants from "../../constants/Constants";
import config from "../../Api/config";
import parse from "html-react-parser";

const DonationScannerContainer = (props) => {
  // console.log(props);
  return (
    // Modification and Commented by Om Shrivastava on 18-05-2024
    // Reason : Show scanner section and change the postion of donation description 
    
    // Modification and addition by Om Shrivastava on 01-04-24
    // Reason : Need to remove scanner for in this page
    <div className={`${donationScannerConatainerStyle.contactUsDetailsContainer}`}>

      <div className={`${donationScannerConatainerStyle.qrDataConatainer}`}>
        <div>
          <img
            className={`${donationScannerConatainerStyle.qrImage}`}
            src={config.baseURL + props.bankDetails.scanner_image}
            alt=""
          />
        </div>
       {/* <div className={`${donationScannerConatainerStyle.paymentDetailContainer}`}> */}
        {/* Modification and addition by Om Shrivastava on 28-01-24
        Reason : Need to add condition when data is not present */}
        {props?.bankDetails?.name ? (
          <div className={`${donationScannerConatainerStyle.dataContainer}`}>
            <div className={`${donationScannerConatainerStyle.row1}`}>
              {Constants.NAME_TEXT}
            </div>
            <div className={`${donationScannerConatainerStyle.row2}`}> - </div>
            <div className={`${donationScannerConatainerStyle.row3}`}>
              {" "}
              {props?.bankDetails?.name}
            </div>
          </div>
        ) : null}
        {props?.bankDetails?.contact_number ? (
          <div className={`${donationScannerConatainerStyle.dataContainer}`}>
            <div className={`${donationScannerConatainerStyle.row1}`}>
              {Constants.contactUsTitleText}
            </div>
            <div className={`${donationScannerConatainerStyle.row2}`}> - </div>
            <div className={`${donationScannerConatainerStyle.row3}`}>
              {props?.bankDetails?.contact_number}
            </div>
          </div>
        ) : null}
        {props?.bankDetails?.bank_name ? (
          <div className={`${donationScannerConatainerStyle.dataContainer}`}>
            <div className={`${donationScannerConatainerStyle.row1}`}>UPI ID</div>
            <div className={`${donationScannerConatainerStyle.row2}`}> - </div>
            <div className={`${donationScannerConatainerStyle.row3}`}>
              {props?.bankDetails?.bank_name}
            </div>
          </div>
        ) : null}
{/* </div> */}
         {/* End of modification and addition by Om Shrivastava on 28-01-24
        Reason : Need to add condition when data is not present */}
      </div>
    </div>

    // <div
    //   className={`${donationScannerConatainerStyle.contactUsDetailsContainer}`}
    // >
    // <h3 style={{fontSize:'17px',color:'#f5781f',fontWeight:"bold", textAlign:'left'}}>{Constants.noteTitleText} : </h3>
    //   <div className={donationScannerConatainerStyle.descriptionContainer}>
    //     {parse(
    //       props.donationDescription != null &&
    //         props.donationDescription.length > 0
    //         ? // Modification and commented by Om shrivastava on 18-03-24
    //           // Reason : Need to add this section in div
    //           // props.donationDescription
    //           `<div >
    //                 ${props.donationDescription}
    //               </div>`
    //         : // End of modification and commented by Om shrivastava on 18-03-24
    //           // Reason : Need to add this section in div
    //           ""
    //     )}
    //   </div>
    // </div>
    // End of modification and addition by Om Shrivastava on 01-04-24
    // Reason : Need to remove scanner for in this page
    
    // End of modification and Commented by Om Shrivastava on 18-05-2024
    // Reason : Show scanner section and change the postion of donation description 
  );
};

export default DonationScannerContainer;
