/**
 * Created by - Ashish Dewangan on 24-01-2024
 * Reason - To have component that will contain scanner details
 */

import scannerContainerStyle from "./ScannerContainer.module.css";
import Constants from "../../constants/Constants";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import config from "../../Api/config";
import parse from "html-react-parser";

const ScannerContainer = (props) => {
  return (
    // Commented by - Ashlekh on 19-03-2024
    // Reason - To remove scanner & its details
    // Modification and Commented by Om Shrivastava on 18-05-2024
    // Reason : Show scanner section and change the postion of jyot description
    <div className={`${scannerContainerStyle.contactUsDetailsContainer}`}>
      <div className={`${scannerContainerStyle.qrDataConatainer}`}>
        <div>
          <img
            className={`${scannerContainerStyle.qrImage}`}
            src={config.baseURL + props.bankDetails.scanner_image}
            alt=""
          />
        </div>
        {/* Modification and addition by Om Shrivastava on 28-01-24
        Reason : Need to add condition when data is not present */}
        {/* <div className={`${scannerContainerStyle.paymentDetailContainer}`}> */}
          {props?.bankDetails?.name ? (
            <div className={`${scannerContainerStyle.dataContainer}`}>
              <div className={`${scannerContainerStyle.row1}`}>
                {Constants.NAME_TEXT}
              </div>
              <div className={`${scannerContainerStyle.row2}`}> - </div>
              <div className={`${scannerContainerStyle.row3}`}>
                {" "}
                {props?.bankDetails?.name}
              </div>
            </div>
          ) : null}
          {props?.bankDetails?.contact_number ? (
            <div className={`${scannerContainerStyle.dataContainer}`}>
              <div className={`${scannerContainerStyle.row1}`}>
                {Constants.contactUsTitleText}
              </div>
              <div className={`${scannerContainerStyle.row2}`}> - </div>
              <div className={`${scannerContainerStyle.row3}`}>
                {props?.bankDetails?.contact_number}
              </div>
            </div>
          ) : null}
          {props?.bankDetails?.bank_name ? (
            <div className={`${scannerContainerStyle.dataContainer}`}>
              <div className={`${scannerContainerStyle.row1}`}>UPI ID</div>
              <div className={`${scannerContainerStyle.row2}`}> - </div>
              <div className={`${scannerContainerStyle.row3}`}>
                {props?.bankDetails?.bank_name}
              </div>
            </div>
          ) : null}
        {/* </div> */}

        {/* End of modification and addition by Om Shrivastava on 28-01-24
        Reason : Need to add condition when data is not present */}
      </div>
    </div>
    //End of comment - Ashlekh on 19-03-2024
    //Reason - To remove scanner & its details
    // <div
    // className={`${scannerContainerStyle.contactUsDetailsContainer}`}
    // >
    // <h3 style={{fontSize:'17px',color:'#f5781f',fontWeight:"bold", textAlign:'left',paddingLeft:'1%'}}>{Constants.noteTitleText} : </h3>

    //   {/* <div
    //     className={scannerContainerStyle.label}
    //     style={{
    //       width: "100%",
    //       fontSize: "12px",
    //       fontWeight: "500",
    //       color: "red",
    //       paddingLeft: "1%",
    //       display: "flex",
    //       paddingTop: "1%",
    //     }}
    //   >
    //     <span style={{ color: "red", fontSize: "18px" }}>• </span>
    //     <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>
    //       मनोकामना ज्योति कलश का मूल्य 651रु हैं।
    //     </span>
    //   </div>
    //   <div
    //     className={scannerContainerStyle.label}
    //     style={{
    //       width: "100%",
    //       fontSize: "12px",
    //       fontWeight: "500",
    //       color: "red",
    //       paddingLeft: "1%",

    //       display: "flex",
    //     }}
    //   >
    //     <span style={{ color: "red", fontSize: "18px" }}>• </span>
    //     <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>
    //       अखण्ड ज्योति कलश का मूल्य 25001 रू हैं
    //     </span>
    //   </div>
    //   <div
    //     className={scannerContainerStyle.label}
    //     style={{
    //       width: "100%",
    //       fontSize: "12px",
    //       fontWeight: "500",
    //       color: "red",
    //       paddingLeft: "1%",

    //       display: "flex",
    //     }}
    //   >
    //     <span style={{ color: "red", fontSize: "18px" }}>• </span>
    //     <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>
    //       ऊपर उल्लेखित ज्योति कलशों में से किसी को भी आपके नाम पर आजीवन जलाए
    //       रखने का विकल्प चुना जा सकता हैं।
    //     </span>
    //   </div> */}

    //   <div className={scannerContainerStyle.descriptionContainer}>
    //     {parse(
    //       props.jyotDescription != null &&
    //         props.jyotDescription.length > 0
    //         ? // Modification and commented by Om shrivastava on 18-03-24
    //           // Reason : Need to add this section in div
    //           // props.jyotDescription
    //           `<div >
    //                 ${props.jyotDescription}
    //               </div>`
    //         : // End of modification and commented by Om shrivastava on 18-03-24
    //           // Reason : Need to add this section in div
    //           ""
    //     )}
    //   </div>

    // </div>

    // End of modification and Commented by Om Shrivastava on 18-05-2024
    // Reason : Show scanner section and change the postion of jyot description
  );
};

export default ScannerContainer;
