/**
 * Created by - Ashish Dewangan on 24-01-2024
 * Reason - To have jyot page
 */
import { useContext, useEffect, useState } from "react";
import { getContactUsDetailsApi, getJyotDetailsApi } from "../../Api/services";

import jyotStyle from "./Jyot.module.css";
import Constants from "../../constants/Constants";
import VerticalLine from "../../components/Widgets/Line/VerticalLine";
import notificationObject from "../../components/Widgets/Notification/Notification";
import JyotFlowContainer from "./JyotFlowContainer";
import JyotForm from "./JyotForm";
import ScannerContainer from "./ScannerContainer";
import { GlobalContext } from "../../context/Context";
import { useLocation } from "react-router-dom";
// import JyotFform from "./JyotFform";

const Jyot = () => {
  const [contactUsDetails, setContactUsDetails] = useState({});

  const [paymentDetails, setPaymentDetails] = useState("");

  const [jyotTypeDetails, setJyotTypeDetails] = useState([]);
  // Code changed by - Ashlekh on 23-02-2024
  // Reason - Parv will not be used, Instead of parv jyot will come
  // const [parvDetails, setParvDetails] = useState("");
  const [jyotDetails, setJyotDetails] = useState([]);
  // End of code - Ashlekh on 23-02-2024
  // Reason - Parv will not be used, Instead of parv jyot will come
  const [isLoading, setIsLoading] = useState(true);

  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalCurrentRoute,
    setGlobalCurrentRoute,
    jyotDescriptionData,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);
  const location = useLocation();
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context
  useEffect(() => {
    getJyotDetailsData();
    // Added by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    // End of code by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  const getJyotDetailsData = async () => {
    /**
     * Addition by - Om Shrivastava on 27-01-24
     * Reason - Called Jyot details API
     */
    const response = await getJyotDetailsApi();
    /**
     * End of Addition by - Om Shrivastava on 27-01-24
     * Reason - Called Jyot details API
     */

    // if (response?.terms_and_conditions != null) {
    setPaymentDetails(response?.payment_details);
    // Addition by Om Shrivastava on 28-01-24
    // Reason : Need to set the parv and jyot details
    if (response?.jyot_type != []) setJyotTypeDetails(response?.jyot_type);
    // Code changed by - Ashlekh on 23-02-2024
    // Reason - Parv will not be used, Instead of parv jyot will come
    // setParvDetails(response?.parv);
    if (response?.jyot != null) setJyotDetails(response?.jyot);
    // End of code - Ashlekh on 23-02-2024
    // Reason - Parv will not be used, Instead of parv jyot will come
    // End of Addition by Om Shrivastava on 28-01-24
    // Reason : Need to set the parv and jyot details
    setIsLoading(false);
    // }
  };

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;

  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    // Commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details
    // getContactUsDetails();
    // End of commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details
  }, []);

  const getContactUsDetails = async () => {
    const response = await getContactUsDetailsApi();

    if (response?.contact_us_details != null) {
      setContactUsDetails(response?.contact_us_details);
    }
  };

  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${jyotStyle.pageFrame}`}>
      {/* {isLoading == false ? ( */}
      <div className={`${jyotStyle.coloredBackground}`}>
        {/* End of code addition by - Ashish Dewangan on 01-02-2024
      Reason - To have background image */}
        <div className={`${jyotStyle.pageContainer}`}>
          {" "}
          <div className={`${jyotStyle.title}`}>
            <div className={`${jyotStyle.heading}`}>
              {" "}
              {Constants.JYOT_TITLE_TEXT}
            </div>
            <div className={`${jyotStyle.currentTimeData}`}>{currentDate}</div>
          </div>
          {/* Added by - Ashlekh on 04-03-2024
            Reason - To add loader in jyot form */}
          {isLoading ? (
            <div
              className={`${jyotStyle.content}`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "90vh",
              }}
            >
              <img style={{ height: "30vh" }}  src="adyant_loader.gif" />
            </div>
          ) : (
            // End of code - Ashlekh on 04-03-2024
            // Reason - To add loader in jyot form
            <div className={`${jyotStyle.content} ${jyotStyle.section}`}>
              {/* // Commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details */}
              {/* <JyotFlowContainer contactUsDetails={contactUsDetails} /> */}
              <JyotFlowContainer />
              {/* // End of commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details */}

              <VerticalLine />
              <JyotForm
                // Addition by Om Shrivastava on 28-01-24
                // Reason : Need to send the parv and jyot type details
                // Code changed by - Ashlekh on 23-02-2024
                // Reason - Not required to send parv, instead jyot will be sent
                // parvData={parvDetails}
                jyotDetails={jyotDetails}
                jyotData={jyotTypeDetails}
                // End of code - Ashlekh on 23-02-2024
                // Reason - Not required to send parv, instead jyot will be sent
                // End of addition by Om Shrivastava on 28-01-24
                // Reason : Need to send the parv and jyot type details
                // Addition by Om Shrivastava on 18-05-2024
                // Reason : Send the jyot description data 
                jyotDescription = {jyotDescriptionData}
                // End of addition by Om Shrivastava on 18-05-2024
                // Reason : Send the jyot description data 
              />

              <VerticalLine />
              <ScannerContainer bankDetails={paymentDetails} 
              jyotDescription = {jyotDescriptionData}
              />
            </div>
          )}
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
};

export default Jyot;
