import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import AboutUs from "./pages/AboutUs/AboutUs";
import AboutGod from "./pages/AboutGod/AboutGod";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import ContactUs from "./pages/ContactUs/ContactUs";
import Jyot from "./pages/Jyot/Jyot";
import JyotReceipt from "./pages/Receipt/JyotReceipt";
import DonationReceipt from "./pages/Receipt/DonationReceipt";
import Volunteer from "./pages/Volunteer/Volunteer";
import Receipt from "./pages/JyotReceiptHistory/JyotReceiptHistory";
import JyotReceiptHistory from "./pages/JyotReceiptHistory/JyotReceiptHistory";
import TempleTrustee from "./pages/TempleTrustee/TempleTrustee";
import Donation from './pages/Donation/Donation'
import DonationReceiptHistory from "./pages/DonationReceiptHistory/DonationReceiptHistory";

function App() {
  return (
    /**
     * Added by - Ashlekh on 10-01-2024
     * Reason - To link/import all components
     */
    <div>
      <Header />
      <Routes>
        {/* Added by - Ashish Dewangan on 13-01-2024
        Reason - To have routes for home page */}
        <Route path="/" element={<Home />} />
        {/* End of addition by - Ashish Dewangan on 13-01-2024
        Reason - To have routes for home page */}

        {/* Added by - Ashish Dewangan on 11-01-2024
        Reason - To have routes for privacy policy page */}
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        {/* End of addition by - Ashish Dewangan on 11-01-2024
        Reason - To have routes for privacy policy page */}

        {/* Added by - Ashish Dewangan on 11-01-2024
        Reason - To have routes for terms and conditions page */}
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        {/* End of addition by - Ashish Dewangan on 11-01-2024
        Reason - To have routes for terms and conditions page */}

        {/* Added by - Ashish Dewangan on 12-01-2024
        Reason - To have routes for about us page */}
        <Route path="/about_us" element={<AboutUs />} />
        {/* End of addition by - Ashish Dewangan on 12-01-2024
        Reason - To have routes for about us page */}

        {/* Added by - Ashish Dewangan on 12-01-2024
        Reason - To have routes for about gods page */}
        <Route path="/about_god" element={<AboutGod />} />
        {/* End of addition by - Ashish Dewangan on 12-01-2024
        Reason - To have routes for about gods page */}

        {/* Added by - Ashish Dewangan on 12-01-2024
        Reason - To have routes for gallery page */}
        <Route path="/gallery" element={<Gallery />} />
        {/* End of addition by - Ashish Dewangan on 12-01-2024
        Reason - To have routes for gallery page */}

        {/* Added by - Ashish Dewangan on 18-01-2024
        Reason - To have routes for contact us page */}
        <Route path="/contact_us" element={<ContactUs />} />
        {/* End of addition by - Ashish Dewangan on 18-01-2024
        Reason - To have routes for contact us page */}

        {/* Added by - Om Shrivastava on 25-01-24
        Reason - To have routes for jyot page */}
        <Route path="/jyot" element={<Jyot />} />
        {/* End of addition by - Om Shrivastava on 25-01-24
        Reason - To have routes for jyot page */}

        {/* Added by - Ashish Dewangan on 27-01-24
        Reason - To have route for jyot receipt page */}
        <Route path="/jyot_receipt" element={<JyotReceipt />} />
        {/* End of addition by - Ashish Dewangan on 27-01-24
        Reason - To have route for jyot receipt page */}

        {/* Added by - Om Shrivastava on 26-03-24
        Reason - To have route for donation receipt page */}
        <Route path="/donation_receipt" element={<DonationReceipt />} />
        {/* End of addition by - Om Shrivastava on 26-03-24
        Reason - To have route for doantion receipt page */}

        {/* Added by - Ashlekh on 25-01-2024
        Reason - To have route for volunteer page */}
        {/* Commented by - Ashlekh on 12-02-2024
        Reason - Volunteer page not required */}
        {/* <Route path="/volunteer" element={<Volunteer />} /> */}
        {/* End of comment - Ashlekh on 12-02-2024
        Reason - Volunteer page not required */}
        {/* End of code addition by - Ashlekh on 25-01-2024
        Reason - To have route for volunteer page */}

        {/* Added by - Ashlekh on 26-01-2024
        Reason - To have route for receipt page */}
        <Route path="/jyot_receipt_history" element={<JyotReceiptHistory />} />
        {/* End of code addition by - Ashlekh on 26-01-2024
        Reason - To have route for receipt page */}

        {/* Added by - Ashlekh on 12-02-2024
        Reason - To have route for temple trustee */}
        <Route path="/temple_trustee" element={<TempleTrustee/>} />
        {/* End of code - Ashlekh on 12-02-2024
        Reason - To have route for temple trustee */}
        
        {/* Added by - Om Shrivastava on 14-02-24
        Reason - To have routes for Donation page */}
        <Route path="/donation" element={<Donation />} />
        {/* End of addition by - Om Shrivastava on 14-02-24
        Reason - To have routes for Donation page */}

        {/* Added by - Om Shrivastava on 15-02-2024
        Reason - To have route for donation receipt */}
        <Route path="/donation_receipt" element={<DonationReceipt/>} />
        {/* End of code - Om Shrivastava on 15-02-2024
        Reason - To have route for donation receipt */}

        {/* Added by - Ashlekh on 29-03-2024
        Reason - To have route for donation receipt history page */}
        <Route path="/donation_receipt_history" element={<DonationReceiptHistory />} />
        {/* End of code - Ashlekh on 29-03-2024
        Reason - To have route for donation receipt history page */}
      </Routes>
      <Footer />
    </div>
    /**
     * End of code addition by - Ashlekh on 10-01-2024
     * Reason - To link/import all components
     */
  );
}

export default App;
