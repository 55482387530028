/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have vertical line component
 */

import verticalLineStyle from "./VerticalLine.module.css";


const VerticalLine = () => {

  return (
    <div className={`${verticalLineStyle.verticalLine}`}>
         </div>
  );
};

export default VerticalLine;
