/**
 * Created by - Om Shrivastava on 14-02-24
 * Reason - To have Donation page
 */
import { useContext, useEffect, useState } from "react";
import {
  getContactUsDetailsApi,
  getDonationTypeApi,
  getWhatsAppDetailsApi,
} from "../../Api/services";

import donationStyle from "./Donation.module.css";
import Constants from "../../constants/Constants";
import VerticalLine from "../../components/Widgets/Line/VerticalLine";
import notificationObject from "../../components/Widgets/Notification/Notification";
import DonationFlowContainer from "./DonationFlowContainer";
import DonationForm from "./DonationForm";
import DonationScannerContainer from "./DonationScannerContainer";
import { GlobalContext } from "../../context/Context";

const Donation = () => {
  const [contactUsDetails, setContactUsDetails] = useState({});

  const [paymentDetails, setPaymentDetails] = useState("");

  /* Addition by Om Shrivastava on 04-04-24
      Reason : Set the usestate data for loader  */

  const [isLoading, setIsLoading] = useState(true);

  /* End of addition by Om Shrivastava on 04-04-24
      Reason : Set the usestate data for loader  */

  const [donationType, setDonationType] = useState([]);
  // const [donationDescriptionData, setDonationDescriptionData] = useState('')
  var {
    donationDescriptionData,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);

  useEffect(() => {
    getDonationTypeData();

    // getDonationDescription();
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);
  /**
   * Addition by - Om Shrivastava on 14-02-24
   * Reason - Called Donation type details API
   */
  const getDonationTypeData = async () => {
    const response = await getDonationTypeApi();

    setDonationType(response?.donation_type);
    setPaymentDetails(response?.payment_details);

    /* Addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */

    setIsLoading(false);

    /* End of addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
  };

  //Get the Donation description data

  // const getDonationDescription = async () => {
  //     const response = await getWhatsAppDetailsApi();
  //     if (response != null) {
  //       setDonationDescriptionData(response?.donation_description);
  //     }
  //   };

  //End of addition for the getting the Donation description data

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;

  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    // Commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details
    // getContactUsDetails();
    // End of commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details
  }, []);

  const getContactUsDetails = async () => {
    const response = await getContactUsDetailsApi();

    if (response?.contact_us_details != null) {
      setContactUsDetails(response?.contact_us_details);
    }
  };

  return (
    <div className={`${donationStyle.pageFrame}`}>
      {/* Addition by Om Shrivastava on 04-04-24
      Reason : Need to add loader in this page  */}
      {isLoading ? (
        <div
          className={`${donationStyle.loaderDiv}`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "93vh",
          }}
        >
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      ) : (
        //       End of addition by Om Shrivastava on 04-04-24
        // Reason : Need to add loader in this page
        <div className={`${donationStyle.coloredBackground}`}>
          <div className={`${donationStyle.pageContainer}`}>
            {" "}
            <div className={`${donationStyle.title}`}>
              <div className={`${donationStyle.heading}`}>
                {" "}
                {Constants.DONATION_TEXT}
              </div>
              <div className={`${donationStyle.currentTimeData}`}>
                {currentDate}
              </div>
            </div>
            <div
              className={`${donationStyle.content} ${donationStyle.section}`}
            >
              {/* // Commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details */}
              {/* <DonationFlowContainer contactUsDetails={contactUsDetails} /> */}
              <DonationFlowContainer />
              {/* // End of commented by Om Shrivastava on 02-04-24
    // Reason : Need to comment this method because no need to use contact details */}
              <VerticalLine />
              <DonationForm
                donationTypeData={donationType}
                donationDescription={donationDescriptionData} 
              />
              <VerticalLine />
              <DonationScannerContainer
                bankDetails={paymentDetails}
                donationDescription={donationDescriptionData}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Donation;
