/**
 * Created by - Ashish Dewangan on 12-01-2024
 * Reason - To have about gods page
 */
import { useEffect, useState } from "react";
import { getAllDetailsApi, getDetailsOfGodApi } from "../../Api/services";

import aboutGodStyle from "./AboutGod.module.css";
import parse from "html-react-parser";
import config from "../../Api/config";
import { useLocation } from "react-router-dom";
import notificationObject from "../../components/Widgets/Notification/Notification";

const AboutGod = (props) => {
  const [detailId, setDetailId] = useState("");
  const [detailsOfGod, setDetailsOfGod] = useState({});
  var state = useLocation();

  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    if (state.state) setDetailId(state.state);
  }, []);

  useEffect(() => {
    if (detailId != null && detailId != "") {
      getDetailsOfGod();
    }
  }, [detailId]);

  const getDetailsOfGod = async () => {
    /**
     * Modified by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */
    // const response = await getAllDetailsApi();
    const response = await getDetailsOfGodApi(detailId);
    /**
     * End of modification by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */

    if (response?.details_of_god != null) {
      /**
       * Modified by - Ashish Dewangan on 20-01-2024
       * Reason - Changed logic to single object from backend rather than array.
       */
      // var detailList = [];
      // detailList = response?.details_of_gods;
      // if (detailList.length > 0) {
      //   setDetailsOfGod(
      //     detailList.filter((e) => {
      //       // return e.id == 2;
      //       return e.id == detailId;
      //     })[0]
      //   );
      // }
      setDetailsOfGod(response?.details_of_god);
      /**
       * End of modification by - Ashish Dewangan on 20-01-2024
       * Reason - Changed logic to single object from backend rather than array.
       */
    }
  };
  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${aboutGodStyle.pageFrame}`}>
      <div className={`${aboutGodStyle.coloredBackground}`}>
        {/* End of code addition by - Ashish Dewangan on 01-02-2024
      Reason - To have background image */}
        <div className={`${aboutGodStyle.pageContainer}`}>
          {" "}
          <div className={`${aboutGodStyle.title}`}>{detailsOfGod?.name} </div>
          <div className={`${aboutGodStyle.content}`}>
            <div className={`${aboutGodStyle.detailsContainer}`}>
              <div className={`${aboutGodStyle.imageSection}`}>
                {detailsOfGod?.image != null &&
                  detailsOfGod?.image?.length > 0 && (
                    <img
                      className={`${aboutGodStyle.image}`}
                      src={config.baseURL + detailsOfGod?.image}
                    />
                  )}
              </div>
              <div className={`${aboutGodStyle.descriptionSection}`}>
                {parse(
                  detailsOfGod?.description?.length > 0
                  ? // Modification and commented by Om shrivastava on 18-03-24
                  // Reason : Need to add this section in div
                  // detailsOfGod?.description
                  `<div >
                    ${detailsOfGod?.description}
                  </div>`
                : // End of modification and commented by Om shrivastava on 18-03-24
                  // Reason : Need to add this section in div
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutGod;
