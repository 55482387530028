/**
 * Added by - Ashish Dewangan on 19-01-2024
 * Reason - Added configuration for notification
 */
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const notificationObject = new Notyf({
  // Modification and addition by Om Shrivastava on 01-2-24
  // Reason : Need to decrease the message timing
  // duration: 5000,
  duration: 2000,
  // End of modification and addition by Om Shrivastava on 01-2-24
  // Reason : Need to decrease the message timing

  position: {
    x: "right",
    y: "top",
  },
  types: [
    {
      type: "success",
      background: '#4AB516',
    },
  ],
});

export default notificationObject;
