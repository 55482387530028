/**
 * Added by - Ashish Dewangan on 13-01-2024
 * Reason - To store constants values
 */
const Constants = {
  /**
   * Modified by - Ashish Dewangan on 03-02-2024
   * Reason - To add full stop sign at the end of the text
   */
  // dataNotFoundText: "जानकारी उपलब्ध नहीं हैं",
  // noContentFoundText: "जानकारी उपलब्ध नहीं हैं",
  dataNotFoundText: "जानकारी उपलब्ध नहीं हैं।",
  noContentFoundText: "जानकारी उपलब्ध नहीं हैं।",
  /**
   * End of modification by - Ashish Dewangan on 03-02-2024
   * Reason - To add full stop sign at the end of the text
   */
  privacyPolicyTitleText: "प्राइवेसी पॉलिसी",
  termsAndConditionsTitleText: "नियम और शर्तें",
  aboutUsTitleText: "परिचय",
  galleryTitleText: "गैलरी",
  contactUsTitleText: "संपर्क करें",

  /**
   * Added by - Ashish Dewangan on 27-01-2024
   * Reason - Added hindi text for  receipt
   */
  receiptTitleText: "ज्योत रसीद",
  /**
   * End of addition by - Ashish Dewangan on 27-01-2024
   * Reason - Added hindi text for  receipt
   */

  // Addition by Om Shrivastava on 25-01-24
  // Reason : Add heading for jyot
  // Modification and addition by Om Shrivastava on 27-01-24
  // Reason : Need to capitalize this const
  // jyotTitleText: "ज्योत",
  // parvText:" पर्व ",
  // accountText:" खाता संख्या ",
  // nameText: "नाम",
  // contactText:"नंबर",
  // addressText:"पता",
  // dobText:"जन्मतिथि",
  // genderText:"लिंग",
  // dateText:"तिथि",
  // rupeeText:"रुपया"
  JYOT_TITLE_TEXT: "ज्योत",
  PARV_TEXT: " पर्व ",
  ACCOUNT_TEXT: " खाता संख्या ",
  NAME_TEXT: "नाम",
  CONTACT_TEXT: "मोबाइल नंबर",
  ADDRESS_TEXT: "पता",
  DOBTEXT: "जन्मतिथि",
  GENDER_TEXT: "लिंग",
  DATE_TEXT: "तिथि",
  RUPEE_TEXT: "राशि",

  MALE_TEXT: "पुरुष",
  FEMALE_TEXT: "महिला",
  OTHER_TEXT: "अन्य",
  // Modification and addition by Om Shrivastava on 29-04-24
  // Reason : Need to change the text
  // SUBMIT_TEXT : "दर्ज करें",
  SUBMIT_TEXT: "सबमिट करें",
  // End of Modification and addition by Om Shrivastava on 29-04-24
  // Reason : Need to change the text
  JYOTTYPE_TEXT: "ज्योत प्रकार",
  AGE_TEXT: "आयु",

  // End of Modification and addition by Om Shrivastava on 27-01-24
  // Reason : Need to capitalize this const

  // End of Addition by Om Shrivastava on 25-01-24
  // Reason : Add heading for jyot
  // Added by - Ashlekh on 25-01-2024
  // Reason - Volunteer & receipt values
  volunteerTitleText: "वॉलंटियर",
  volunteerSubTitleText: "शीघ्र आने वाला है",
  volunteerContact: "संपर्क करें",
  receiptSubTitleText: "कृपया रसीद प्राप्त करने के लिए मोबाइल नंबर दर्ज करें।",
  receiptMobileNumber: "मोबाइल नंबर ",
  // End of code addition by - Ashlekh on 25-01-2024
  // Reason - Volunteer & Receipt values
  JYOT_NUMBER_TEXT: "ज्योत संख्या",
  PRINT_RECEIPT: "प्रिंट रसीद",
  // Addition by Om Shrivastava on 01-02-24
  // Reason : Need to set the
  RUPEE_IN_HINDI: "रुपये",
  // Addition by Om Shrivastava on 02-02-24
  // Reason : Need to add Download text
  DOWNLOAD_TEXT: "डाउनलोड रसीद",

  /**
   * Added by - Ashish Dewangan on 03-02-2024
   * Reason - To control gallery page texts from this file file
   */
  PHOTO_TEXT: "फोटो",
  LIVE_VIDEO_TEXT: "लाइव ",
  VIDEO_TEXT: "वीडियो",
  /**
   * End of addition by - Ashish Dewangan on 03-02-2024
   * Reason - To control gallery page texts from this file file
   */

  /**
   * Added by - Ashish Dewangan on 03-02-2024
   * Reason - To control contact us page texts from this file file
   */
  YOU_CAN_CONTACT_US_TEXT: "आप निम्न पते पर भी हमसे संपर्क कर सकते हैं : ",
  MOBILE_NUMBER_1_TEXT: " मोबाइल नंबर 1",
  MOBILE_NUMBER_2_TEXT: " मोबाइल नंबर 2",
  EMAIL_ADDRESS_TEXT: " ई-मेल एड्रेस",
  ASK_QUESTION_TEXT: "सवाल पूछे",
  /**
   * End of addition by - Ashish Dewangan on 03-02-2024
   * Reason - To control contact us page texts from this file file
   */
  // Addition by Om Shrivastava on 04-02-24
  // Reason : Need to add rupee in word
  RUPEE_IN_WORD: " राशि ( शब्दों में )",
  // End of Addition by Om Shrivastava on 04-02-24
  // Reason : Need to add add rupee in word
  // Added by - Ashlekh on 12-02-2024
  // Reason - Need to add title of temple trustee
  templeTrusteeTitle: "ट्रस्टी",
  // End of code - Ashlekh on 12-02-2024
  // Reason - Need to add title of temple trustee
  // Addition by Om Shrivastava on 14-02-24
  // Reason - Need to add title of Donation
  DONATION_TEXT: "दान",
  COMMENT_TEXT: "टिप्पणी",
  DONATION_TYPE_TEXT: "दान के प्रकार",
  // Addition by Om Shrivastava on 15-02-24
  // Reason : Need to add the receiver signature text in hindi 
  RECIEVER_SIGNATURE_TEXT : "प्राप्तकर्ता का हस्ताक्षर",
  // End of addition by Om Shrivastava on 15-02-24
  // Reason : Need to add the receiver signature text in hindi 
  // End of addition by Om Shrivastava on 14-02-24
  // Reason - Need to add title of Donation
  // Addition by Om Shrivastava on 17-02-24
  // Reason : Need to add the khalari darsan text in hindi 
  KHALLARI_DARSHAN_TEXT : "खल्लारी दर्शन",
  // End of addition by Om Shrivastava on 17-02-24
  // Reason : Need to add the khalari darsan text in hindi 
  // Added by - Ashlekh on 18-02-2024
  // Reason - To have home page text
  homePageTitle: "होम",
  submitTextReceiptHistory: "सबमिट",
  viewTextReceiptHistory: "रसीद देखें",
  receiptNumberTextReceiptHistory: "रसीद नंबर",
  nameTextReceiptHistory: "नाम",
  paymentStatusTextReceiptHistory: "पेमेंट स्टेटस",
  // End of code - Ashlekh on 18-02-2024
  // Reason - To have home page text

  JYOT_PERIOD: "ज्योत प्रज्वलित तिथि",
  // Addition by Om Shrivastava on 11-03-24
  // Reason : Need to add the razorpay key id
  // RAZORPAY_KEY_ID : "rzp_test_FU5SNWRBelrZDX"
  // End of Addition by Om Shrivastava on 11-03-24
  // Reason : Need to add the razorpay key id

  // Added by - Ashlekh on 29-03-2024
  // Reason - To add donation receipt title
  donationReceiptTitleText: "दान रसीद",
  donationReceiptSubtitleText: "कृपया रसीद प्राप्त करने के लिए मोबाइल नंबर दर्ज करें।",
  // End of code - Ashlekh on 29-03-2024
  // Reason - To add donation receipt title
  noteTitleText : "नोट",
  donationNumber: "दान क्रमांक ",


};
export default Constants;
