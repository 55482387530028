/**
 * Created by - Ashlekh on 18-01-2024
 * Reason - For Details of Cover Image
 */

import { useEffect, useState } from "react";
import { getAllDetailsApi } from "../../Api/services";

import coverImageStyle from "./coverImage.module.css";
import config from "../../Api/config";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CoverImage = (props) => {
  // console.log("props@coverphotos", props);
  const [coverPhotos, setCoverPhotos] = useState([]);
  // const getDetails = async() => {
  //     const response = await getAllDetailsApi();
  //     if (response != null) {
  //         setCoverPhotos(response?.cover_photos);
  //     }
  // }
  // Addition by Om Shrivastava on 22-01-24
  // Reason :Need to fix the resposiveness
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 721 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    // End of Addition by Om Shrivastava on 22-01-24
    // Reason :Need to fix the resposiveness
  };

  return (
    <div className={`${coverImageStyle.coverImageContainer}`}>
      <div className={`${coverImageStyle.sliderContainer}`}>
        {/* Modification and addition by Om Shrivastava on 22-01-24
        Reason : Need to change the library*/}
        {/* <Carousel autoPlay={true} transitionTime={1} infiniteLoop={true}>
          {/* {coverPhotos.map(e => */}
        {/* {props?.coverPhotos.map((e) => (
            <Carousel.Item key={e.id}>
              <div className={`${coverImageStyle.imageDiv}`}>
                <img
                  className={`${coverImageStyle.image}`}
                  src={config.baseURL + e.photo}
                  alt=""
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
         */}
        {/* Modified by - Ashish Dewangan on 29-01-2024
         Reason - To have auto rotate functionality on cover images */}
        {/* <Carousel responsive={responsive} showDots={true} autoPlay={true} infinite={true} autoPlaySpeed={2000}> */}
        <Carousel
          responsive={responsive}
          // Code changed by - Ashlekh on 05-02-2024
          // Reason - If there is one image in cover photo then dot will not appear
          // showDots={true}
          showDots={props?.coverPhotos?.length > 1 ? true : false}
          // End of modification by - Ashlekh on 05-02-2024
          // Reason - If there is one image in cover photo then dot will not appear
          // Commented and added by - Ashlekh on 04-02-2024
          // Reason - If there is one image then image will not autorotate
          // autoPlay={true}
          autoPlay={props?.coverPhotos?.length > 1 ? true : false}
          // End of comment by - Ashlekh on 04-02-2024
          // Reason - If there is one image then image will not autorotate
          infinite={true}
          autoPlaySpeed={2000}
          // Added by - Ashlekh on 04-02-2024
          // Reason - If there is more than one image then arrow will display
          arrows={props?.coverPhotos?.length > 1 ? true : false}
          // End of code addition by - Ashlekh on 04-02-2024
          // Reason - If there is more than one image then arrow will display
        >
          {/* End of addition by - Ashish Dewangan on 29-01-2024
         Reason - To have auto rotate functionality on cover images */}
          {props?.coverPhotos.map((e, index) => {
            return (
              // <Carousel.Item key={index}>
              <div key={index}>
                <img
                  className={`${coverImageStyle.image}`}
                  src={config.baseURL + e.photo}
                  alt=""
                />
              </div>
              // </Carousel.Item>
            );
          })}
        </Carousel>
        {/* End of modification and addition by Om Shrivastava on 22-01-24
        Reason : Need to change the library*/}
      </div>
    </div>
  );
};
export default CoverImage;
