/**
 * Created by - Ashish Dewangan on 27-01-2024
 * Reason - To have jyot receipt page
 */
import { useEffect, useRef, useState } from "react";
import { getContactUsDetailsApi, getJyotReceiptApi } from "../../Api/services";
import { useLocation } from "react-router-dom";
import jyotReceiptStyle from "./JyotReceipt.module.css";
import Constants from "../../constants/Constants";
import notificationObject from "../../components/Widgets/Notification/Notification";
import ReactToPrint from "react-to-print";
import { usePDF } from "react-to-pdf";
import { ToWords } from "to-words";
import { GlobalContext } from "../../context/Context";
import { useContext } from "react";
import config from "../../Api/config";

const JyotReceipt = () => {
  const [jyotReceipt, setJyotReceipt] = useState({});
  // Addition by Om Shrivastava on 30-03-24
  // Reason : Set logo from context file
  var { logo } = useContext(GlobalContext);
  // End of addition by Om Shrivastava on 30-03-24
  // Reason : Set logo from context file
  const [contactUsDetails, setContactUsDetails] = useState({});

  const location = useLocation();
  const dataReceived = location.state;
  const jyotId = dataReceived.id;
  // const jyotId = location.state;
  // Addition by Om Shrivastava on 04-02-24
  // Reaosn : Need to create this const for save the jyot receipt
  const { toPDF, targetRef } = usePDF({ filename: "jyot_receipt.pdf" });
  // End of addition by Om Shrivastava on 04-02-24
  // Reaosn : Need to create this const for save the jyot receipt

  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var { globalCurrentRoute, setGlobalCurrentRoute } = useContext(GlobalContext);
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context

  useEffect(() => {
    // notificationObject.dismissAll();
    window.scrollTo(0, 0);
    getContactUsDetails();
    // Added by - Ashlekh on 29-03-2024
    // Reason - To store current route
    setGlobalCurrentRoute(location.pathname);
    // End of code - Ashlekh on 29-03-2024
    // Reason - To store current route
  }, []);

  // Addition by Om Shrivastava on 10-02-24
  // Reason : Need to get admin details
  const getContactUsDetails = async () => {
    const response = await getContactUsDetailsApi();
    if (response?.contact_us_details != null) {
      setContactUsDetails(response?.contact_us_details);
    }
  };
  // End of addition by Om Shrivastava on 10-02-24
  // Reason : Need to get admin details

  useEffect(() => {
    if (jyotId != null && jyotId != "") {
      // Commented by Om Shrivastava on 11-03-24
      // Reason : No need to use this API
      // Uncommented by Om Shrivastava on 22-05-2024
      // Reason : Using this method for get the jyot request details
      getJyotReceipt();
      // Uncommented by Om Shrivastava on 22-05-2024
      // Reason : Using this method for get the jyot request details
      // End of commented by Om Shrivastava on 11-03-24
      // Reason : No need to use this API
    }
    // Added by - Ashlekh on 14-02-2024
    // Reason - To store current Route
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    // End of code - Ashlekh on 14-02-2024
    // Reason - To store current Route
  }, [jyotId]);

  // Commented by Om Shrivastava on 11-03-24
  // Reason : No need to use this API
  // Uncommented by Om Shrivastava on 22-05-2024
  // Reason : Using this API fetch the jyot request
  const getJyotReceipt = async () => {
    const response = await getJyotReceiptApi(jyotId);
    if (response?.jyot_receipt) {
      setJyotReceipt(response?.jyot_receipt);
    }
  };
  // Uncommented by Om Shrivastava on 22-05-2024
  // Reason : Using this API fetch the jyot request
  // End of Commented by Om Shrivastava on 11-03-24
  // Reason : No need to use this API

  function ExtractDateTime(format) {
    var d = new Date(format);
    var date = format?.substring(0, 10).split("-").reverse().join("/");
    var time = format?.substring(11, 19);

    return { date, time };
  }
  const date = new Date();

  let componentRef = useRef();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;
  // Addition by Om Shrivastava on 08-02-24
  // Reason : Need to add the hindi word for the amount section
  var toWords = new ToWords({
    localeCode: "hi-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "रुपये मात्र ",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  var amountInHindi = toWords?.convert(
    // Modification and addition by Om Shrivastava on 12-03-24
    // Reason : Need to change the variable
    // jyotReceipt?.amount != undefined ? jyotReceipt?.amount : 0
    dataReceived?.amount != undefined ? dataReceived?.amount : 0
    // End of modification and addition by Om Shrivastava on 12-03-24
    // Reason : Need to change the variable
  );
  // End of Modification and addition by Om Shrivastava on 08-02-24
  // Reason : Need to add the hindi word for the amount section

  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */

    // Commnted by Om Shrivastava on 11-02-24
    // Reason : Need to remove this design
    // <div className={`${jyotReceiptStyle.pageFrame}`}>
    //   <div className={`${jyotReceiptStyle.coloredBackground}`}>

    //     <div className={`${jyotReceiptStyle.pageContainer}`}>
    //       {" "}

    //       <div ref={targetRef}>
    //         <div ref={(el) => (componentRef = el)}>
    //           <div className={`${jyotReceiptStyle.title}`}>
    //             {Constants.receiptTitleText}
    //           </div>
    //           <div className={`${jyotReceiptStyle.content}`}>
    //             <div className={`${jyotReceiptStyle.borderedContainer}`}>

    //               <div className={`${jyotReceiptStyle.watermarkBackground}`}>

    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div
    //                     className={`${jyotReceiptStyle.row}`}
    //                     style={{ width: "40%" }}
    //                   >
    //                     <div className={`${jyotReceiptStyle.label}`}>
    //                       {Constants.receiptTitleText}
    //                     </div>
    //                     <div className={`${jyotReceiptStyle.value}`}>
    //                       :&nbsp; &nbsp;
    //                       {dataReceived?.receipt_number != "" ? (
    //                         dataReceived.receipt_number
    //                       ) : (
    //                         <span style={{ paddingLeft: "13%" }}>-</span>
    //                       )}
    //                     </div>
    //                   </div>
    //                   <div
    //                     className={`${jyotReceiptStyle.row} ${jyotReceiptStyle.dateField}`}
    //                   >
    //                     <div className={`${jyotReceiptStyle.label}`}>
    //                       {Constants.DATE_TEXT}
    //                     </div>
    //                     <div
    //                       className={`${jyotReceiptStyle.value}`}
    //                       style={{ wordBreak: "break-all" }}
    //                     >
    //                       :&nbsp;&nbsp;{currentDate}
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.JYOT_NUMBER_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt.jyot_number != null ? (
    //                       <span> {jyotReceipt.jyot_number}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.PARV_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.parv_value != "" ? (
    //                       <span> {jyotReceipt?.parv_value}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.JYOT_TITLE_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.jyot_kalash_type != "" ? (
    //                       <span> {jyotReceipt?.jyot_type_value}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.NAME_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.name != "" ||
    //                     jyotReceipt?.name != null ? (
    //                       <span> {jyotReceipt?.name}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.AGE_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;

    //                     {jyotReceipt?.dob != "" ? (

    //                       <span> {jyotReceipt?.dob}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.GENDER_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.gender != null ? (
    //                       <span> {jyotReceipt?.gender}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.ADDRESS_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.address != "" ? (
    //                       <span> {jyotReceipt.address}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.CONTACT_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.contact_number != "" ? (
    //                       <span> {jyotReceipt?.contact_number}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.RUPEE_TEXT}
    //                   </div>
    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     {jyotReceipt?.amount != "" ? (
    //                       <span> {jyotReceipt?.amount}</span>
    //                     ) : (
    //                       <span style={{ paddingLeft: "13%" }}>-</span>
    //                     )}
    //                   </div>

    //                 </div>

    //                 <div className={`${jyotReceiptStyle.row}`}>
    //                   <div className={`${jyotReceiptStyle.label}`}>
    //                     {Constants.RUPEE_IN_WORD}
    //                   </div>

    //                   <div className={`${jyotReceiptStyle.value}`}>
    //                     :&nbsp;
    //                     ( {amountInHindi})
    //                   </div>

    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className={`${jyotReceiptStyle.title}`}>
    //         <div className={jyotReceiptStyle.buttonContainer}>
    //           <ReactToPrint
    //             trigger={() => (
    //               <button
    //                 className={jyotReceiptStyle.button}
    //                 type="submit"
    //               >
    //                 {Constants.PRINT_RECEIPT}
    //               </button>
    //             )}
    //             content={() => componentRef}
    //           ></ReactToPrint>

    //           <button
    //             className={jyotReceiptStyle.button}
    //             type="submit"
    //             onClick={() => toPDF()}
    //           >
    //             {Constants.DOWNLOAD_TEXT}
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // End of commnted by Om Shrivastava on 11-02-24
    // Reason : Need to remove this design

    <div className={`${jyotReceiptStyle.pageFrame}`}>
      <div className={`${jyotReceiptStyle.coloredBackground}`}>
        <div className={`${jyotReceiptStyle.pageContainer}`}>
          {" "}
          <div ref={targetRef}>
            <div ref={(el) => (componentRef = el)}>
              <div
                className={`${jyotReceiptStyle.title}`}
                // Added by - Ashlekh on 03-04-2024
                // Reason - When we were printing receipt then heading was not printing properly
                style={{ paddingTop: "10px" }}
                // End of code - Ashlekh on 03-04-2024
                // Reason - When we were printing receipt then heading was not printing properly
              >
                {/* {Constants.receiptTitleText} */}
              </div>

              <div className={`${jyotReceiptStyle.receiptContainer}`}>
                {/* Addition by Om Shrivastava on 13-04-24
                Reason : Add heading inside the receipt  */}
                <h3
                  style={{ padding: "0", margin: "0", paddingTop: "1%" }}
                  className={`${jyotReceiptStyle.title}`}
                >
                  {Constants.receiptTitleText}{" "}
                </h3>
                {/* End of Addition by Om Shrivastava on 13-04-24
                Reason : Add heading inside the receipt   */}
                <div className={`${jyotReceiptStyle.receiptFirstRowContainer}`}>
                  <div className={`${jyotReceiptStyle.receiptImgContainer}`}>
                    <img
                      className={`${jyotReceiptStyle.receiptImg}`}
                      // Modification and addition by Om Shrivastava on 29-03-24
                      // Reason : Need to set image path
                      // src="project_logo.png"
                      // Addition by Om Shrivastava on 30-03-24
                      // Reason : Set the image from backend
                      // src={config.mediaURL + "project_logo.png"}
                      src={config.baseURL + logo}
                      // End of addition by Om Shrivastava on 30-03-24
                      // Reason : Set the image from backend
                      // End of modification and addition by Om Shrivastava on 29-03-24
                      // Reason : Need to set image path
                      alt=""
                    />
                  </div>

                  <div className={`${jyotReceiptStyle.adminDetailsContainer}`}>
                    {/* Addition by Om Shrivastava on 15-02-24
                    Reason : Need to add the current date */}
                    <div
                      className={`${jyotReceiptStyle.headingDateConatainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.heading}`}
                        // style={{ border: "1px solid red", marginLeft: "6.5%" }}
                      >
                        <img
                          className={`${jyotReceiptStyle.receiptHeadingImg}`}
                          src="jyot_heading.png"
                          alt=""
                        />
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {" "}
                        {currentDate}
                      </div>
                    </div>
                    {/* End of addition by Om Shrivastava on 15-02-24
                    Reason : Need to add the current date */}
                    {/* Addition by Om Shrivastava on 15-02-24
                    Reason : Need to add condtion when address is null then no need to so this div */}
                    {contactUsDetails.address !== null ? (
                      <div className={`${jyotReceiptStyle.receiptAddress}`}>
                        {contactUsDetails?.address
                          ? contactUsDetails.address
                          : ""}
                      </div>
                    ) : null}
                    {/* End of addition by Om Shrivastava on 15-02-24
                    Reason : Need to add condtion when address is null then no need to so this div */}
                    {/* Addition by Om Shrivastava on 15-02-24
                    Reason : Need to set the contact data when its null  */}
                    {/* {contactUsDetails!=undefined||contactUsDetails!=null|| contactUsDetails.length>0?  */}

                    {contactUsDetails.contact_number1 != null ||
                    contactUsDetails.contact_number1 != undefined ? (
                      <div className={`${jyotReceiptStyle.receiptContact}`}>
                        <>
                          {Constants.contactUsTitleText}
                          {" - "}
                          {contactUsDetails.contact_number1}
                        </>

                        {contactUsDetails.contact_number2 !== null ? (
                          <>,{contactUsDetails.contact_number2}</>
                        ) : null}
                      </div>
                    ) : null}
                    {/* // :null} */}
                    {/* End of addition by Om Shrivastava on 15-02-24
                    Reason : Need to set the contact data when its null */}
                    <div className={`${jyotReceiptStyle.parvData}`}>
                      {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                      {/* {jyotReceipt.parv_name} */}
                      {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name  */}
                      {/* {dataReceived.parv_name} */}
                      {jyotReceipt.parv_name}
                      {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                      {/* End of modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                      {Constants.JYOT_PERIOD} (
                      {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                      {/* {ExtractDateTime(jyotReceipt.fromDate).date} -{" "}
                      {ExtractDateTime(jyotReceipt.endDate).date}) */}
                      {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name  */}
                      {/* {ExtractDateTime(dataReceived.fromDate).date} -{" "}
                      {ExtractDateTime(dataReceived.endDate).date}) */}
                      {ExtractDateTime(jyotReceipt.fromDate).date} -{" "}
                      {ExtractDateTime(jyotReceipt.endDate).date})
                      {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                      {/* End of modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                    </div>
                  </div>
                </div>

                <div
                  className={`${jyotReceiptStyle.receiptSecondRowContainer}`}
                  style={{ padding: "0.5% 0% 0% 2%" }}
                >
                  <div className={`${jyotReceiptStyle.receiptDataContainer}`}>
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.receiptTitleText}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.receipt_number != "" ? (
                          jyotReceipt.receipt_number
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name  */}
                        {/* {dataReceived?.receipt_number != "" ? (
                          dataReceived.receipt_number
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span> */}
                        {jyotReceipt?.receipt_number != "" ? (
                          jyotReceipt.receipt_number
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                    <div
                      className={`${jyotReceiptStyle.receiptSecondColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.JYOT_NUMBER_TEXT}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt.jyot_number != null ? (
                          <span> {jyotReceipt.jyot_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived.jyot_number != null ? (
                          <span> {dataReceived.jyot_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span> */}
                        {jyotReceipt.jyot_number != null ? (
                          <span> {jyotReceipt.jyot_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                  </div>
                  {/* Added by - Ashlekh on 26-02-2024
                  Reason - For having jyot section */}
                  <div
                    className={`${jyotReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptAddressFirstColumnData}`}
                      >
                        {Constants.JYOT_TITLE_TEXT}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptAddressSecondColumnData}`}
                      >
                        {" "}
                        :{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptAddressThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.jyot_value != "" ? (
                          <span> {jyotReceipt?.jyot_value}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.jyot_value != "" ? (
                          <span> {dataReceived?.jyot_value}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span> */}
                        {jyotReceipt?.jyot_value != "" ? (
                          <span> {jyotReceipt?.jyot_value}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                  </div>
                  {/* End of code - Ashlekh on 26-02-2024
                  Reason - For have jyot section */}
                  <div
                    className={`${jyotReceiptStyle.receiptDataContainer}`}
                    // style={{
                    //   display: "flex",
                    //   border: "1px solid green",
                    //   flexWrap: "wrap",
                    // }}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        // className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                        className={`${jyotReceiptStyle.receiptAddressFirstColumnData}`}
                      >
                        {/* {Constants.JYOT_TITLE_TEXT}{" "} */}
                        {Constants.JYOTTYPE_TEXT}{" "}
                      </div>
                      <div
                        // className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                        className={`${jyotReceiptStyle.receiptAddressSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        // className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                        className={`${jyotReceiptStyle.receiptAddressThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.jyot_kalash_type != "" ? (
                          <span> {jyotReceipt?.jyot_type_value}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.jyot_kalash_type != "" ? (
                          <span> {dataReceived?.jyot_type_value}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span> */}
                        {jyotReceipt?.jyot_kalash_type != "" ? (
                          <span> {jyotReceipt?.jyot_type_value}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                    {/* Commented by Om Shrivastava on 15-02-24
                    Reason : Need to remove this date and set the top of the page */}
                    {/* <div
                      className={`${jyotReceiptStyle.receiptSecondColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.DATE_TEXT}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {currentDate}
                      </div>
                    </div> */}
                    {/* End of commented by Om Shrivastava on 15-02-24
                    Reason : Need to remove this date and set the top of the page */}
                  </div>
                  <div
                    className={`${jyotReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.NAME_TEXT}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.name != "" ||
                        jyotReceipt?.name != null ? (
                          <span> {jyotReceipt?.name}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.name != "" ||
                        dataReceived?.name != null ? (
                          <span> {dataReceived?.name}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span> */}
                        {jyotReceipt?.name != "" ||
                        jyotReceipt?.name != null ? (
                          <span> {jyotReceipt?.name}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                    <div
                      className={`${jyotReceiptStyle.receiptSecondColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.receiptMobileNumber}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.contact_number != "" ? (
                          <span> {jyotReceipt?.contact_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.contact_number != "" ? (
                          <span> {dataReceived?.contact_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span> */}
                        {jyotReceipt?.contact_number != "" ? (
                          <span> {jyotReceipt?.contact_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                  </div>
                  <div className={`${jyotReceiptStyle.receiptDataContainer}`}>
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.AGE_TEXT}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.dob != "" ? (
                          <span> {jyotReceipt?.dob}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.dob != "" ? (
                          <span> {dataReceived?.dob}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span> */}
                        {jyotReceipt?.dob != "" ? (
                          <span> {jyotReceipt?.dob}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable */}
                      </div>
                    </div>
                    <div
                      className={`${jyotReceiptStyle.receiptSecondColumnDataContainer}`}
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.GENDER_TEXT}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.gender != null &&
                        jyotReceipt.gender != "" ? (
                          <span> {jyotReceipt.gender}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.gender != null &&
                        dataReceived.gender != "" ? (
                          <span> {dataReceived.gender}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                          //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable
                        )} */}
                        {jyotReceipt?.gender != null &&
                        jyotReceipt.gender != "" ? (
                          <span> {jyotReceipt.gender}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${jyotReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        // className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                        className={`${jyotReceiptStyle.receiptAddressFirstColumnData}`}
                      >
                        {Constants.ADDRESS_TEXT}{" "}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptAddressSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptAddressThirdColumnData}`}
                      >
                        {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                        {/* {jyotReceipt?.address != "" ? (
                          <span> {jyotReceipt.address}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.1%" }}>-</span>
                        )} */}
                        {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        {/* {dataReceived?.address != "" ? (
                          <span> {dataReceived.address}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.1%" }}>-</span>
                          //     End of modification and addition by Om Shrivastava 11-03-24
                          // Reason : Need to change the variable
                        )} */}
                        {jyotReceipt?.address != "" ? (
                          <span> {jyotReceipt.address}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.1%" }}>-</span>
                        )}
                        {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${jyotReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${jyotReceiptStyle.receiptFirstColumnDataContainer}`}
                      // Added by - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                      style={{ alignItems: "center" }}
                      // End of code - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.RUPEE_TEXT}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData} ${jyotReceiptStyle.receiptThirdColumnBtn}`}
                      >
                        <button
                          style={{
                            paddingLeft: "3px",
                            // Code changed by - Ashlekh on 30-03-2024
                            // Reason - To change height
                            // height: "40px",
                            height: "30px",
                            // End of code - Ashlekh on 30-03-2024
                            // Reason - To change height
                            cursor: "none",
                          }}
                          className={`${jyotReceiptStyle.donation_button}`}
                        >
                          {/* Modification and addition by Om Shrivastava 11-03-24
                      Reason : Need to change the variable */}
                          {/* {jyotReceipt?.amount != "" ? (
                            <span> {jyotReceipt?.amount} /-</span>
                          ) : (
                            <span style={{ paddingLeft: "12%" }}>-</span>
                          )} */}
                          {/* Modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                          {/* {dataReceived?.amount != "" ? (
                            <span> {dataReceived?.amount} /-</span>
                          ) : (
                            <span style={{ paddingLeft: "12%" }}>-</span>
                            //     End of modification and addition by Om Shrivastava 11-03-24
                            // Reason : Need to change the variable
                          )} */}
                          {jyotReceipt?.amount != "" ? (
                            <span> {jyotReceipt?.amount} /-</span>
                          ) : (
                            <span style={{ paddingLeft: "12%" }}>-</span>
                          )}
                          {/* End of modification and addition by Om Shrivastava on 22-05-2024
                      Reason : Now data is retrieve from the backend so change the variable name   */}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`${jyotReceiptStyle.receiptSecondColumnDataContainer}`}
                      // Added by - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                      style={{ alignItems: "center" }}
                      // End of code - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                    >
                      <div
                        className={`${jyotReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.RUPEE_IN_WORD}
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${jyotReceiptStyle.receiptThirdColumnData} ${jyotReceiptStyle.receiptThirdColumnBtn}`}
                      >
                        <button
                          style={{ cursor: "none" }}
                          className={`${jyotReceiptStyle.donation_button}`}
                        >
                          {amountInHindi}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`${jyotReceiptStyle.receiptThirdRowContainer}`}
                  style={{ paddingBottom: "1.5%" }}
                >
                  <div
                    className={`${jyotReceiptStyle.receiptSignatureFirstColumnData}`}
                  >
                    {Constants.RECIEVER_SIGNATURE_TEXT}
                  </div>
                  <div
                    className={`${jyotReceiptStyle.receiptSignatureSecondColumnData}`}
                  >
                    :
                  </div>
                  <div
                    className={`${jyotReceiptStyle.receiptSignatureThirdColumnData}`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${jyotReceiptStyle.title}`}>
            <div className={jyotReceiptStyle.buttonContainer}>
              <ReactToPrint
                trigger={() => (
                  <button className={jyotReceiptStyle.button} type="submit">
                    {Constants.PRINT_RECEIPT}
                  </button>
                )}
                content={() => componentRef}
              ></ReactToPrint>

              <button
                className={`${jyotReceiptStyle.button} ${jyotReceiptStyle.downloadButton}`}
                type="submit"
                onClick={() => toPDF()}
              >
                {Constants.DOWNLOAD_TEXT}
              </button>
            </div>
          </div>
          {/* Addition by Om Shrivastava on 28-06-2024
  Reason : Set the text */}
          <h4
            className={`${jyotReceiptStyle.textHeading}`}
            style={{ color: "#ff8a15", fontSize: "12px", textAlign: "center" }}
          >
            इस रसीद को डाउनलोड करने के लिए प्रिंट रसीद बटन को दबाएं और Save to Pdf को चुने और Save करें।
          </h4>
          {/* Addition by Om Shrivastava on 28-06-2024
          Reason : Set the text   */}

           {/* Addition by Om Shrivastava on 28-06-2024
  Reason : Set the text */}
  {jyotReceipt.jyot_number == null ?(
          <h4
            style={{ color: "#ff8a15", fontSize: "16px", textAlign: "center" }}
          >
            {/* एडमिन के द्वारा पैमेंट वेरिफिकेशन के बाद दान संख्या प्रदान किया जायेगा */}
            Admin के द्वारा Payement verification के बाद ज्योत संख्या प्रदान किया जायेगा
          
          </h4>)
          :null}
          {/* Addition by Om Shrivastava on 28-06-2024
          Reason : Set the text   */}
        </div>
      </div>
    </div>
  );
};

export default JyotReceipt;
