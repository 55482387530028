import API from "./api";

/**
 * Added by - Ashlekh on 11-01-2024
 * Reason - To get all combined data from all tables
 */
export const getAllDetailsApi = async () => {
  const response = await API.get(`/get_all_details/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashlekh on 11-01-2024
 * Reason - To get all combined data from all tables
 */

/**
 * Added by - Ashish Dewangan on 18-01-2024
 * Reason - To post contact us request to backend
 */
export const postContactUsRequestApi = async (contactUsRequestData) => {
  const response = await API.post(
    `/post_contact_us_request/`,
    contactUsRequestData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  ).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 18-01-2024
 * Reason - To post contact us request to backend
 */

/**
 * Added by - Ashish Dewangan on 20-01-2024
 * Reason - To get contact us details from backend
 */
export const getContactUsDetailsApi = async () => {
  const response = await API.get(`/get_contact_us_details/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 20-01-2024
 * Reason - To get contact us details from backend
 */

/**
 * Added by - Ashish Dewangan on 20-01-2024
 * Reason - To get gallery contents from backend
 */
export const getGalleryContentsApi = async () => {
  const response = await API.get(`/get_gallery_contents/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 20-01-2024
 * Reason - To get gallery contents from backend
 */

/**
 * Added by - Ashish Dewangan on 20-01-2024
 * Reason - To get about us details from backend
 */
export const getAboutUsDetailsApi = async () => {
  const response = await API.get(`/get_about_us_details/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 20-01-2024
 * Reason - To get about us details from backend
 */

/**
 * Added by - Ashish Dewangan on 20-01-2024
 * Reason - To get privacy policy details from backend
 */
export const getPrivacyPolicyDetailsApi = async () => {
  const response = await API.get(`/get_privacy_policy_details/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 20-01-2024
 * Reason - To get privacy policy details from backend
 */

/**
 * Added by - Ashish Dewangan on 20-01-2024
 * Reason - To get terms and conditions details from backend
 */
export const getTermsAndConditionsDetailsApi = async () => {
  const response = await API.get(`/get_terms_and_conditions_details/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 20-01-2024
 * Reason - To get terms and conditions details from backend
 */

/**
 * Added by - Ashish Dewangan on 20-01-2024
 * Reason - To get details of god from backend
 */
export const getDetailsOfGodApi = async (detailId) => {
  const response = await API.get(`/get_details_of_god/${detailId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 20-01-2024
 * Reason - To get details of god from backend
 */

/**
 * Added by - Ashish Dewangan on 27-01-2024
 * Reason - To get jyot receipt of a jyot request id
 */
export const getJyotReceiptApi = async (jyotRequestId) => {
  const response = await API.get(`/get_jyot_receipt/${jyotRequestId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashish Dewangan on 27-01-2024
 * Reason - To get jyot receipt of a jyot request id
 */

/**
 * Added by - Om Shrivastava on 28-01-2024
 * Reason - To get jyot details from backend
 */
export const getJyotDetailsApi = async () => {
  const response = await API.get(`/get_jyot_details/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Om Shrivastava on 28-01-2024
 * Reason - To get jyot details from backend
 */

/**
 * Added by - Om Shrivastava on 28-01-2024
 * Reason - To post jyot details to backend
 */
export const postJyotRequestApi = async (contactUsRequestData) => {
  const response = await API.post(
    `/post_jyot_request/`,
    contactUsRequestData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  ).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Om Shrivastava on 28-01-2024
 * Reason - To post jyot details to backend
 */

/**
 * Added by - Ashlekh on 29-01-2024
 * Reason - To post mobile number for receipt history
 */
export const getJyotReceiptHistoryApi = async (mobileNumber) => {
  const response = await API.get(`/jyot_receipt_history/${mobileNumber}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Ashlekh on 29-01-2024
 * Reason - To post mobile number for receipt history
 */

/**
 * Added by - Om Shrivastava on 31-01-2024
 * Reason - To post the email request data in backend
 */
export const postEmailRequestApi = async (emailReaquestAPI) => {
  const response = await API.post(
    `/post_email_request/`,
    emailReaquestAPI,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  ).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Om Shrivastava on 31-01-2024
 * Reason - To post the email request data in backend
 */

/**
 * Added by - Ashlekh on 01-02-2024
 * Reason - To get whatsapp details
 */
export const getWhatsAppDetailsApi = async () => {
  const response = await API.get(`/whatsapp_details_request/`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};

/**
 * End of code addition by - Ashlekh on 01-02-2024
 * Reason - To get whatsapp details
 */

/**
 * Added by - Ashlekh on 11-02-2024
 * Reason - To get temple trustee details
 */
export const getTempleTrusteeDetailsApi = async () => {
  const response = await API.get(`/temple_trustee_details/`, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};

/**
 * End of code addition by - Ashlekh on 11-02-2024
 * Reason - To get temple trustee details
 */

/**
 * Added by - Om Shrivastava on 14-02-24
 * Reason - To get donation type from backend
 */
export const getDonationTypeApi = async () => {
  const response = await API.get(`/get_donation_type/`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Om Shrivastava on 14-02-24
 * Reason - To get donation type from backend
 */

/**
 * Added by - Om Shrivastava on 14-02-24
 * Reason - To post donation details to backend
 */
export const postDonationRequestApi = async (contactUsRequestData) => {
  const response = await API.post(
    `/donation_request/`,
    contactUsRequestData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  ).catch((err) => console.log(err));
  return response ? response.data : {};
};

// Addition by Om Shrivastava on 24-02-24
// Reasonb : Need to add the razorpay post and verify payment API
export const postRazorPayRequriedApi = async (contactUsRequestData) => {
  const response = await API.post(
    `/order/create/`,
    contactUsRequestData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  ).catch((err) => console.log(err));
  return response ? response.data : {};
};

export const checkTransactionApi = async (contactUsRequestData) => {
  const response = await API.post(
    `/save-transaction/`,
    contactUsRequestData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  ).catch((err) => console.log(err));
  return response ? response.data : {};
};
// End of addition by Om Shrivastava on 24-02-24
// Reasonb : Need to add the razorpay post and verify payment API

/**
 * Added by - Om Shrivastava on 26-03-24
 * Reason - To get donation receipt of a donation request id
 */
export const getDonationReceiptApi = async (donationRequestId) => {
  const response = await API.get(`/get_donation_receipt/${donationRequestId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code addition by - Om Shrivastava on 26-03-24
 * Reason - To get donation receipt of a donation request id
 */

/**
 * End of code addition by - Om Shrivastava on 14-02-24
 * Reason - To post donation details to backend
 */

/**
 * Added by - Ashlekh on 29-03-2024
 * Reason - To post mobile number for donation receipt history
 */
export const getDonationReceiptHistoryApi = async (mobileNumber) => {
  const response = await API.get(`/donation_receipt_history/${mobileNumber}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      // "Content-Type": "application/json",
    },
  }).catch((err) => console.log(err));
  return response ? response.data : {};
};
/**
 * End of code - Ashlekh on 29-03-2024
 * Reason - To post mobile number for donation receipt history
 */