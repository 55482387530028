/**
 * Created by - Om Shrivastava on 26-03-24
 * Reason - To have donation receipt page
 */
import { useContext, useEffect, useRef, useState } from "react";
import { getContactUsDetailsApi,getDonationReceiptApi } from "../../Api/services";
import { useLocation } from "react-router-dom";
import donationReceiptStyle from "./DonationReceipt.module.css";
import Constants from "../../constants/Constants";
import ReactToPrint from "react-to-print";
import { usePDF } from "react-to-pdf";
import { ToWords } from "to-words";
import config from "../../Api/config";
import { GlobalContext } from "../../context/Context";

const DonationReceipt = () => {
  // Addition by Om Shrivastava on 30-03-24
  // Reason : Set logo from context file
  var { logo } = useContext(GlobalContext);
  // End of addition by Om Shrivastava on 30-03-24
  // Reason : Set logo from context file
  const [contactUsDetails, setContactUsDetails] = useState({});

  const [donationReceipt, setDonationReceipt] = useState({});


  const location = useLocation();
  const dataReceived = location.state;
  const donationId = dataReceived.id;

  const { toPDF, targetRef } = usePDF({ filename: "donation_receipt.pdf" });
  // Added by - Ashlekh on 29-03-2024
  // Reason - To have context variables
  var { globalCurrentRoute, setGlobalCurrentRoute } = useContext(GlobalContext);
  // console.log("currentRoute@DonationReceipt", globalCurrentRoute);
  // End of code - Ashlekh on 29-03-2024
  // Reason - To have context variables
  useEffect(() => {
    window.scrollTo(0, 0);
    getContactUsDetails();
    // Added by - Ashlekh on 29-03-2024
    // Reason - To store current route
    setGlobalCurrentRoute(location.pathname);
    // End of code - Ashlekh on 29-03-2024
    // Reason - To store current route
  }, []);

  useEffect(() => {
    if (donationId != null && donationId != "") {
      getDonationReceipt();
    }
    setGlobalCurrentRoute(location.pathname);
  }, [donationId]);

  // Addition by Om Shrivastava on 26-03-24
  // Reason : Need to get admin details
  const getContactUsDetails = async () => {
    const response = await getContactUsDetailsApi();
    if (response?.contact_us_details != null) {
      setContactUsDetails(response?.contact_us_details);
    }
  };
  // End of addition by Om Shrivastava on 26-03-24
  // Reason : Need to get admin details

  const date = new Date();

  let componentRef = useRef();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;
  // Addition by Om Shrivastava on 26-03-24
  // Reason : Need to add the hindi word for the amount section
  var toWords = new ToWords({
    localeCode: "hi-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "रुपये मात्र ",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  var amountInHindi = toWords?.convert(
    dataReceived?.amount != undefined ? dataReceived?.amount : 0
  );
  
  const getDonationReceipt = async () => {
    const response = await getDonationReceiptApi(donationId);
    if (response?.donation_receipt) {
      setDonationReceipt(response?.donation_receipt);
    }
  };

  // console.log(donationReceipt,'checkkk')
  return (
    <div className={`${donationReceiptStyle.pageFrame}`}>
      <div className={`${donationReceiptStyle.coloredBackground}`}>
        <div className={`${donationReceiptStyle.pageContainer}`}>
          {" "}
          <div ref={targetRef}>
            <div ref={(el) => (componentRef = el)}>
              <div
                className={`${donationReceiptStyle.title}`}
                // Added by - Ashlekh on 03-04-2024
                // Reason - When we were printing receipt then heading was not printing properly
                style={{ paddingTop: "10px" }}
                // End of code - Ashlekh on 03-04-2024
                // Reason - When we were printing receipt then heading was not printing properly
              >
                {/* Code changed by - Ashlekh on 29-03-2024
                Reason - To change heading of donation */}
                {/* {Constants.receiptTitleText} */}
                {/* {Constants.donationReceiptTitleText} */}
                {/* End of code - Ashlekh on 29-03-2024
                Reason - To change heading of donation */}
              </div>

              <div className={`${donationReceiptStyle.receiptContainer}`}>
                {/* Addition by Om Shrivastava on 13-04-24
                Reason : Add heading inside the receipt  */}
                <h3
                  style={{ padding: "0", margin: "0", paddingTop: "1%" }}
                  className={`${donationReceiptStyle.title}`}
                >
                  {" "}
                  {Constants.donationReceiptTitleText}
                </h3>
                {/* End of Addition by Om Shrivastava on 13-04-24
                Reason : Add heading inside the receipt   */}
                <div
                  className={`${donationReceiptStyle.receiptFirstRowContainer}`}
                >
                  <div
                    className={`${donationReceiptStyle.receiptImgContainer}`}
                  >
                    <img
                      className={`${donationReceiptStyle.receiptImg}`}
                      // Modification and addition by Om Shrivastava on 29-03-24
                      // Reason : Need to set image path
                      // src="project_logo.png"
                      // Addition by Om Shrivastava on 30-03-24
                      // Reason : Set the image from backend
                      // src={config.mediaURL + "project_logo.png"}
                      src={config.baseURL + logo}
                      // End of addition by Om Shrivastava on 30-03-24
                      // Reason : Set the image from backend
                      // End of modification and addition by Om Shrivastava on 29-03-24
                      // Reason : Need to set image path
                      alt=""
                    />
                  </div>

                  <div
                    className={`${donationReceiptStyle.adminDetailsContainer}`}
                  >
                    <div
                      className={`${donationReceiptStyle.headingDateConatainer}`}
                    >
                      <div className={`${donationReceiptStyle.heading}`}>
                        <img
                          className={`${donationReceiptStyle.receiptHeadingImg}`}
                          src="jyot_heading.png"
                          alt=""
                        />
                      </div>
                      <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                        {" "}
                        {currentDate}
                      </div>
                    </div>

                    {contactUsDetails.address !== null ? (
                      <div className={`${donationReceiptStyle.receiptAddress}`}>
                        {contactUsDetails?.address
                          ? contactUsDetails.address
                          : ""}
                      </div>
                    ) : null}

                    {contactUsDetails.contact_number1 != null ||
                    contactUsDetails.contact_number1 != undefined ? (
                      <div className={`${donationReceiptStyle.receiptContact}`}>
                        <>
                          {Constants.contactUsTitleText}
                          {" - "}
                          {contactUsDetails.contact_number1}
                        </>

                        {contactUsDetails.contact_number2 !== null ? (
                          <>,{contactUsDetails.contact_number2}</>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div
                  className={`${donationReceiptStyle.receiptSecondRowContainer}`}
                  style={{ padding: "0.5% 0% 0% 2%" }}
                >
                  <div
                    className={`${donationReceiptStyle.receiptDataContainer}`}
                  >
                    <div
                      className={`${donationReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptFirstColumnData}`}
                      >
                        {/* Code changed by - Ashlekh on 30-03-2024
                        Reason - To change name */}
                        {/* {Constants.receiptTitleText}{" "} */}
                        {Constants.donationReceiptTitleText}
                        {/* End of code - Ashlekh on 30-03-2024
                        Reason - To change name */}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptThirdColumnData}`}
                      >
                        {donationReceipt?.receipt_number != "" ||
                        donationReceipt?.receipt_number != null ? (
                          donationReceipt.receipt_number
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${donationReceiptStyle.receiptSecondColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.donationNumber}{" "}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptThirdColumnData}`}
                      >
                        {donationReceipt?.donation_number != null ? (
                          <span> {donationReceipt.donation_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${donationReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${donationReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptAddressFirstColumnData}`}
                      >
                        {Constants.DONATION_TYPE_TEXT}{" "}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptAddressSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptAddressThirdColumnData}`}
                      >
                        {donationReceipt?.donation_type_name != "" ? (
                          <span> {donationReceipt?.donation_type_name}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.6%" }}>-</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${donationReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${donationReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.NAME_TEXT}{" "}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptThirdColumnData}`}
                      >
                        {donationReceipt?.name != "" ||
                        donationReceipt?.name != null ? (
                          <span> {donationReceipt?.name}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                      </div>
                    </div>

                    <div
                      className={`${donationReceiptStyle.receiptSecondColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.GENDER_TEXT}{" "}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptThirdColumnData}`}
                      >
                        {donationReceipt?.gender != "" ? (
                          <span> {donationReceipt?.gender}</span>
                        ) : (
                          <span style={{ paddingLeft: "13%" }}>-</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${donationReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${donationReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptAddressFirstColumnData}`}
                      >
                        {Constants.CONTACT_TEXT}{" "}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptAddressSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptAddressThirdColumnData}`}
                      >
                        {donationReceipt?.contact_number != null ? (
                          <span> {donationReceipt.contact_number}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.1%" }}>-</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${donationReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${donationReceiptStyle.receiptFirstColumnDataContainer}`}
                    >
                      <div
                        className={`${donationReceiptStyle.receiptAddressFirstColumnData}`}
                      >
                        {Constants.ADDRESS_TEXT}{" "}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptAddressSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptAddressThirdColumnData}`}
                      >
                        {donationReceipt?.address != "" ? (
                          <span> {donationReceipt.address}</span>
                        ) : (
                          <span style={{ paddingLeft: "5.1%" }}>-</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${donationReceiptStyle.receiptDataContainer}`}
                    style={{ paddingTop: "1%" }}
                  >
                    <div
                      className={`${donationReceiptStyle.receiptFirstColumnDataContainer}`}
                      // Added by - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                      style={{ alignItems: "center" }}
                      // End of code - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                    >
                      <div
                        className={`${donationReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.RUPEE_TEXT}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptThirdColumnData} ${donationReceiptStyle.receiptThirdColumnBtn}`}
                      >
                        <button
                          style={{
                            paddingLeft: "3px",
                            // Code changed by - Ashlekh on 30-03-2024
                            // Reason - To manage height
                            // height: "40px",
                            height: "30px",
                            // End of code - Ashlekh on 30-03-2024
                            // Reason - To manage height
                            cursor: "none",
                          }}
                          className={`${donationReceiptStyle.donation_button}`}
                        >
                          {donationReceipt?.amount != "" ? (
                            <span> {donationReceipt?.amount} /-</span>
                          ) : (
                            <span style={{ paddingLeft: "12%" }}>-</span>
                          )}
                        </button>
                      </div>
                    </div>
                    <div
                      className={`${donationReceiptStyle.receiptSecondColumnDataContainer}`}
                      // Added by - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                      style={{ alignItems: "center" }}
                      // End of code - Ashlekh on 30-03-2024
                      // Reason - To align values in center
                    >
                      <div
                        className={`${donationReceiptStyle.receiptFirstColumnData}`}
                      >
                        {Constants.RUPEE_IN_WORD}
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptSecondColumnData}`}
                      >
                        :
                      </div>
                      <div
                        className={`${donationReceiptStyle.receiptThirdColumnData} ${donationReceiptStyle.receiptThirdColumnBtn}`}
                      >
                        <button
                          style={{ cursor: "none" }}
                          className={`${donationReceiptStyle.donation_button}`}
                        >
                          {amountInHindi}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`${donationReceiptStyle.receiptThirdRowContainer}`}
                  style={{ paddingBottom: "1.5%" }}
                >
                  <div
                    className={`${donationReceiptStyle.receiptSignatureFirstColumnData}`}
                  >
                    {Constants.RECIEVER_SIGNATURE_TEXT}
                  </div>
                  <div
                    className={`${donationReceiptStyle.receiptSignatureSecondColumnData}`}
                  >
                    :
                  </div>
                  <div
                    className={`${donationReceiptStyle.receiptSignatureThirdColumnData}`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${donationReceiptStyle.title}`}>
            <div className={donationReceiptStyle.buttonContainer}>
              <ReactToPrint
                trigger={() => (
                  <button className={donationReceiptStyle.button} type="submit">
                    {Constants.PRINT_RECEIPT}
                  </button>
                )}
                content={() => componentRef}
              ></ReactToPrint>

              <button
                className={`${donationReceiptStyle.button} ${donationReceiptStyle.downloadButton}`}
                type="submit"
                onClick={() => toPDF()}
              >
                {Constants.DOWNLOAD_TEXT}
              </button>
            </div>
          </div>
          {/* Addition by Om Shrivastava on 28-06-2024
  Reason : Set the text */}
          <h4
            className={`${donationReceiptStyle.textHeading}`}
            style={{ color: "#ff8a15", fontSize: "12px", textAlign: "center" }}
          >
            इस रसीद को डाउनलोड करने के लिए प्रिंट रसीद बटन को दबाएं और Save to
            Pdf को चुने और Save करें।
          </h4>
          {/* Addition by Om Shrivastava on 28-06-2024
          Reason : Set the text   */}

          {/* Addition by Om Shrivastava on 28-06-2024
  Reason : Set the text */}
        {donationReceipt?.donation_number == null ? 

          <h4
            // className={`${donationReceiptStyle.textHeading}`}
            style={{ color: "#ff8a15", fontSize: "16px", textAlign: "center" }}
          >
            {/* एडमिन के द्वारा पैमेंट वेरिफिकेशन के बाद दान संख्या प्रदान किया जायेगा */}
            Admin के द्वारा Payement verification के बाद दान क्रमांक प्रदान किया जायेगा
         
          </h4>
           :null}
          {/* Addition by Om Shrivastava on 28-06-2024
          Reason : Set the text   */}
        </div>
      </div>
    </div>
  );
};

export default DonationReceipt;
