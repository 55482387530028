/**
 * Created by - Ashish Dewangan on 13-01-2024
 * Reason - To have container for holding live video
 */
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import liveVideoContainerStyle from "./LiveVideoContainer.module.css";
import Constants from "../../constants/Constants";

const LiveVideoContainer = (props) => {
  // Code modified by - Ashlekh on 22-02-2024
  // Reason - To increase width
  // const [videoWidth, setVideoWidth] = useState(500);
  const [videoWidth, setVideoWidth] = useState(530);
  // End of code - Ashlekh on 22-02-2024
  // Reason - To increase width
  const [videoHeight, setVideoHeight] = useState(290);

  /**
   * Added by - Ashish Dewangan on 22-01-2024
   * Reason - To set height of in responsive
   */
  useEffect(() => {
    if (window.innerWidth < 720) {
      setVideoHeight(200);
    } else {
      setVideoHeight(290);
    }
  }, [window.innerWidth]);
  /**
   * End of code addition by - Ashish Dewangan on 22-01-2024
   * Reason - To set height of in responsive
   */

  return (
    <div>
      <hr className={`${liveVideoContainerStyle.horizontalLine}`} />
      <div className={`${liveVideoContainerStyle.subTitle}`}>
        {/* Modified by - Ashish Dewangan on 03-02-2024
        Reason - To get text label from constants file */}
        {/* {"लाइव वीडियो"} */}
        {/* Addition by Om Shrivastava on 08-02-24
        Reason : Need to set the blink property of the div */}
        {/* Modification and addition by Om Shrivastava on 10-02-24
        Reason : Need to add the live video image */}
        {/* <span style={{color:'red',fontSize:'25px'}} className={`${liveVideoContainerStyle.blinkDiv}`}> •</span> */}
          {/* End of addition by Om Shrivastava on 08-02-24
        Reason : Need to set the blink property of the div */}
        {/* {Constants.LIVE_VIDEO_TEXT} */}
        {/* End of modification by - Ashish Dewangan on 03-02-2024
        Reason - To get text label from constants file */}
        <div>
        <img className={`${liveVideoContainerStyle.liveVideoImage}`} src="live.gif" alt="" />
        </div>
         {/* End of modification and addition by Om Shrivastava on 10-02-24
        Reason : Need to add the live video image */}
      </div>
      <div className={`${liveVideoContainerStyle.liveVideoContainer}`}>
        <ReactPlayer
          className={`${liveVideoContainerStyle.video}`}
          url={props?.liveVideo?.video}
          controls={true}
          /**
           * Modified by - Ashish Dewangan on 22-01-2024
           * Reason - To adjust size of video according to responsive
           */
          // width={500}
          // height={290}
          width={videoWidth}
          height={videoHeight}
          /**
           * End of code modification by - Ashish Dewangan on 22-01-2024
           * Reason - To adjust size of video according to responsive
           */
        />
      </div>
    </div>
  );
};

export default LiveVideoContainer;
