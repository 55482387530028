/**
 * Created by - Ashlekh on 10-01-2024
 * 
 */

//const baseURL = "http://192.168.1.4:8000";

const baseURL = ""

const config = {
  baseURL : baseURL,
  apiBaseURL: `${baseURL}/api`,
  staticBaseURL: `${baseURL}/static/`,
  apiTimeout: 500000,
  // Addition by Om Shrivastava on 11-03-24
  // Reason : Need to add the razorpay key id 
  RAZORPAY_KEY_ID : "rzp_test_PLRaIAhDefEzc8"
  // End of addition by Om Shrivastava on 11-03-24
  // Reason : Need to add the razorpay key id 

};

export default config;
