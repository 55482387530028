/**
 * Created by - Ashish Dewangan on 11-01-2024
 * Reason - To have terms and conditions page
 */

import { useContext, useEffect, useState } from "react";
import {
  getAllDetailsApi,
  getTermsAndConditionsDetailsApi,
} from "../../Api/services";

import TermsAndConditionsStyle from "./TermsAndConditions.module.css";
import parse from "html-react-parser";
import Constants from "../../constants/Constants";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { GlobalContext } from "../../context/Context";

const TermsAndConditions = () => {
  const [termsAndConditionsDetails, setTermsAndConditionsDetails] =
    useState("");

  /* Addition by Om Shrivastava on 04-04-24
      Reason : Set the usestate data for loader  */
  const [isLoading, setIsLoading] = useState(true);
  /* End of addition by Om Shrivastava on 04-04-24
              Reason : Set the usestate data for loader  */
  // Added by - Ashlekh on 05-04-2024
  // Reason - To have variables of context
  var {
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalCurrentRoute,
    setGlobalCurrentRoute,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
  } = useContext(GlobalContext);
  // End of code - Ashlekh on 05-04-2024
  // Reason - To have variables of context
  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    getTermsAndConditionsDetails();
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  const getTermsAndConditionsDetails = async () => {
    /**
     * Modified by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */
    // const response = await getAllDetailsApi();
    const response = await getTermsAndConditionsDetailsApi();
    /**
     * End of modification by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */

    if (response?.terms_and_conditions != null) {
      setTermsAndConditionsDetails(response?.terms_and_conditions);
      /* Addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
      setIsLoading(false);
      /* End of addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
    }
  };
  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${TermsAndConditionsStyle.pageFrame}`}>
      {/* Addition by Om Shrivastava on 04-04-24
      Reason : Need to add loader in this page  */}
      {isLoading ? (
        <div
          className={`${TermsAndConditionsStyle.loaderDiv}`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "93vh",
          }}
        >
          <img style={{ height: "30vh" }} src="adyant_loader.gif" />
        </div>
      ) : (
        //       End of addition by Om Shrivastava on 04-04-24
        // Reason : Need to add loader in this page
        <div className={`${TermsAndConditionsStyle.coloredBackground}`}>
          {/* End of code addition by - Ashish Dewangan on 01-02-2024
      Reason - To have background image */}
          <div className={`${TermsAndConditionsStyle.pageContainer}`}>
            {" "}
            <div className={`${TermsAndConditionsStyle.title}`}>
              {Constants.termsAndConditionsTitleText}
            </div>
            <div className={`${TermsAndConditionsStyle.content}`}>
              {parse(
                termsAndConditionsDetails != null &&
                  termsAndConditionsDetails.trim().length > 0
                  ? // Modification and commented by Om shrivastava on 18-03-24
                    // Reason : Need to add this section in div
                    // termsAndConditionsDetails
                    `<div >
                ${termsAndConditionsDetails}
              </div>`
                  : // End of modification and commented by Om shrivastava on 18-03-24
                    // Reason : Need to add this section in div
                    ""
              )}
              {(termsAndConditionsDetails == null ||
                termsAndConditionsDetails.trim().length == 0) && (
                <div
                  className={`${TermsAndConditionsStyle.noContentFoundText}`}
                >
                  {Constants.dataNotFoundText}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
