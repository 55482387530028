/**
 * Created by - Ashish Dewangan on 12-01-2024
 * Reason - To have about us page
 */
import { useContext, useEffect, useState } from "react";
import { getAboutUsDetailsApi, getAllDetailsApi } from "../../Api/services";

import aboutUsStyle from "./AboutUs.module.css";
import parse from "html-react-parser";
import Constants from "../../constants/Constants";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { GlobalContext } from "../../context/Context";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
  const [aboutUsDetails, setAboutUsDetails] = useState("");
  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalCurrentRoute,
    setGlobalCurrentRoute,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);
  const location = useLocation();
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context
  {
    /* Addition by Om Shrivastava on 04-04-24
      Reason : Set the usestate data for loader  */
  }
  const [isLoading, setIsLoading] = useState(true);
  {
    /* End of addition by Om Shrivastava on 04-04-24
      Reason : Set the usestate data for loader  */
  }
  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    getAboutUsDetails();
    // Added by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    // End of code by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  const getAboutUsDetails = async () => {
    /**
     * Modified by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */
    // const response = await getAllDetailsApi();
    const response = await getAboutUsDetailsApi();
    /**
     * End of modification by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */
    if (response?.about_us!= null) {
      setAboutUsDetails(response?.about_us);

      /* Addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */

      setIsLoading(false);

      /* End of addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
    }
  };
  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${aboutUsStyle.pageFrame}`}>
      {/* Addition by Om Shrivastava on 04-04-24
      Reason : Need to add loader in this page  */}
      {isLoading ? (
        <div
          className={`${aboutUsStyle.loaderDiv}`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "93vh",
          }}
        >
          <img style={{height:'30vh'}} src="adyant_loader.gif" />
        </div>
      ) : (
        //       End of addition by Om Shrivastava on 04-04-24
        // Reason : Need to add loader in this page
        <div className={`${aboutUsStyle.coloredBackground}`}>
          {/* End of code addition by - Ashish Dewangan on 01-02-2024
      Reason - To have background image */}
          <div className={`${aboutUsStyle.pageContainer}`}>
            {" "}
            <div className={`${aboutUsStyle.title}`}>
              {Constants.aboutUsTitleText}
            </div>
            <div className={`${aboutUsStyle.content}`}>
              {parse(
                aboutUsDetails != null && aboutUsDetails.trim().length > 0
                  ? // Modification and commented by Om shrivastava on 18-03-24
                    // Reason : Need to add this section in div
                    // aboutUsDetails
                    `<div >
                ${aboutUsDetails}
              </div>`
                  : // End of modification and commented by Om shrivastava on 18-03-24
                    // Reason : Need to add this section in div
                    ""
              )}
              {(aboutUsDetails == null ||
                aboutUsDetails.trim().length == 0) && (
                <div className={`${aboutUsStyle.noContentFoundText}`}>
                  {Constants.dataNotFoundText}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
