/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have contact us page
 */
import { useContext, useEffect, useState } from "react";
import {
  getAllDetailsApi,
  getContactUsDetailsApi,
  postContactUsRequestApi,
} from "../../Api/services";

import contactUsStyle from "./ContactUs.module.css";
import parse from "html-react-parser";
import Constants from "../../constants/Constants";
import VerticalLine from "../../components/Widgets/Line/VerticalLine";
import ContactUsDetails from "./ContactUsDetails";
import ContactUsForm from "./ContactUsForm";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { GlobalContext } from "../../context/Context";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const [contactUsDetails, setContactUsDetails] = useState({});
  // Added by - Ashlekh on 14-02-2024
  // Reason - To have context
  var {
    globalCurrentRoute,
    setGlobalCurrentRoute,
    // Added by - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
    globalJyotReceiptList,
    setGlobalJyotReceiptList,
    globalJyotReceiptHistoryMobileNumber,
    setGlobalJyotReceiptHistoryMobileNumber,
    globalDonationReceiptList,
    setGlobalDonationReceiptList,
    globalDonationReceiptHistoryMobileNumber,
    setGlobalDonationReceiptHistoryMobileNumber,
    // End of code - Ashlekh on 05-04-2024
    // Reason - To have variables from context file
  } = useContext(GlobalContext);

  /* Addition by Om Shrivastava on 04-04-24
      Reason : Set the usestate data for loader  */
  const [isLoading, setIsLoading] = useState(true);
  /* End of addition by Om Shrivastava on 04-04-24
          Reason : Set the usestate data for loader  */
  const location = useLocation();
  // End of code - Ashlekh on 14-02-2024
  // Reason - To have context

  useEffect(() => {
    notificationObject.dismissAll();
    window.scrollTo(0, 0);
    getContactUsDetails();
    // Added by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // localStorage.setItem("currentRoute", location.pathname);
    setGlobalCurrentRoute(location.pathname);
    // End of code by - Ashlekh on 14-02-2024
    // Reason - For storing currentRoute
    // Added by - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
    setGlobalJyotReceiptHistoryMobileNumber("");
    setGlobalJyotReceiptList([]);
    setGlobalDonationReceiptHistoryMobileNumber("");
    setGlobalDonationReceiptList([]);
    // End of code - Ashlekh on 05-04-2024
    // Reason - set variables to empty in mobile number and list
  }, []);

  const getContactUsDetails = async () => {
    /**
     * Modified by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */
    // const response = await getAllDetailsApi();
    const response = await getContactUsDetailsApi();
    /**
     * End of code modification by - Ashish Dewangan on 20-01-2024
     * Reason - Called relevant api for this page rather than calling api that gets details of other pages also
     */

    if (response?.contact_us_details != null) {
      setContactUsDetails(response?.contact_us_details);
      /* Addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
      setIsLoading(false);
      /* End of addition by Om Shrivastava on 04-04-24
      Reason : Need to set usestate data for loader  */
    }
  };

  return (
    /**
     * Added by - Ashish Dewangan on 01-02-2024
     * Reason - To have background image
     */
    <div className={`${contactUsStyle.pageFrame}`}>
      {/* Addition by Om Shrivastava on 04-04-24
      Reason : Need to add loader in this page  */}
      {/* {isLoading ? (
        <div
          className={`${contactUsStyle.loaderDiv}`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "93vh",
          }}
        >
          <img style={{height:'30vh'}} src="adyant_loader.gif" />
          {Constants.dataNotFoundText}

        </div>
      ) : ( */}
        <div className={`${contactUsStyle.coloredBackground}`}>
          
          <div className={`${contactUsStyle.pageContainer}`}>
            {" "}
            <div className={`${contactUsStyle.title}`}>
              {Constants.contactUsTitleText}
            </div>
            <div
              className={`${contactUsStyle.content} ${contactUsStyle.section}`}
            >
              <ContactUsDetails contactUsDetails={contactUsDetails} />
              {/* <VerticalLine /> */}
              <ContactUsForm contactUsDetails={contactUsDetails} />
            </div>
            {/* Added by - Ashish Dewangan on 31-01-2024
          Reason - To resize map */}
            <div
              className={`${contactUsStyle.content} ${contactUsStyle.section}`}
              style={{ justifyContent: "center" }}
            >
              {" "}
              {contactUsDetails?.google_map_link?.length > 0 && (
                <iframe
                  className={`${contactUsStyle.map}`}
                  title="Map"
                  src={contactUsDetails?.google_map_link}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{ border: "0", outline: "none" }}
                ></iframe>
              )}
            </div>
            {/* End of addition by - Ashish Dewangan on 31-01-2024
          Reason - To resize map */}
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default ContactUs;
