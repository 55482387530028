/**
 * Created by - Ashlekh on 10-01-2024
 * Reason - To have footer section
 */

import React, { useContext, useEffect, useState } from "react";
import config from "../../Api/config";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  getAllDetailsApi,
  getWhatsAppDetailsApi,
  postEmailRequestApi,
} from "../../Api/services";
import { Link } from "react-router-dom";
import { TfiAngleDoubleRight } from "react-icons/tfi";
import style from "./footer.module.css";
import { AiOutlineSend } from "react-icons/ai";
import { useFormik } from "formik";
import * as yup from "yup";
import notificationObject from "../Widgets/Notification/Notification";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { GlobalContext } from "../../context/Context";

const Footer = () => {
  const [whatsappLogo, setWhatsappLogo] = useState("");
  const [instagramLogo, setInstagramLogo] = useState("");
  const [facebookLogo, setFacebookLogo] = useState("");
  const [email, setEmail] = useState("");

  // const [socialMediaDetails, setSocialMediaDetails] = useState([]);
  var { socialMediaDetails } = useContext(GlobalContext);

  //Added by - Ashlekh on 02-02-2024
  const [whatsAppDetails, setWhatsAppDetails] = useState([]);
  const [logo, setLogo] = useState([]);
  //End of code addition by - Ashlekh on 02-02-2024

  useEffect(() => {
    // Commented by Om Shrivastava on 01-04-24
    // Reason : Need to remove this method
    // getFooterLogo();
    // End of commented by Om Shrivastava on 01-04-24
    // Reason : Need to remove this method
    //Added by - Ashlekh on 02-02-2024
    getWhatsAppDetails();
    //End of code addition by - Ashlekh on 02-02-2024
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    onSubmit: (values) => {
      submitEmailRequest(values);
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .email("कृपया सही फार्मेट में ईमेल दर्ज करें")
        .required("कृपया ई-मेल दर्ज करें"),
    }),
  });
  /**
   * Addition by - Om Shrivastava on 31-01-24
   * Reason - Added method to Submit the email post functionality
   */

  // async function submitEmailRequest(values) {
  //   const requestData = {
  //     email: values.email,
  //   };
  //   formik.resetForm();

  //   const response = await postEmailRequestApi(requestData);
  //   if (response.message) {
  //     document.getElementById("form").reset();
  //     notificationObject.success(response.message);
  //   } else if (response.error) {
  //     notificationObject.error(response.error);
  //   }
  // }
  async function submitEmailRequest() {
    const requestData = {
      email: document.getElementById("email")?.value,
    };
    const response = await postEmailRequestApi(requestData);
    if (response.message) {
      // document.getElementById("form").reset();
      notificationObject.success(response.message);
      document.getElementById("email").value = "";
    } else if (response.error) {
      notificationObject.error(response.error);
    }
  }
  /**
   * End of code addition by - Om Shrivastava on 31-01-24
   * Reason - Added method to Submit the email post functionality
   */
  // Commented by Om Shrivastava on 01-04-24
  // Reason : Need to remove this method
  // const getFooterLogo = async () => {
  //   const response = await getAllDetailsApi();
  //   // console.log("response", response);
  //   if (response?.social_links != null) {
  //     //Commented by - Ashlekh on 21-12-2024
  //     //Reason - To change footer section
  //     // var socialPlatforms = response.social_links;

  //     // const whatsapp = socialPlatforms.find(
  //     //   (platform) => platform.name === "Whatsapp"
  //     // )?.logo;
  //     // const instagram = socialPlatforms.find(
  //     //   (platform) => platform.name === "Instagram"
  //     // )?.logo;
  //     // const facebook = socialPlatforms.find(
  //     //   (platform) => platform.name === "Facebook"
  //     // )?.logo;

  //     // setWhatsappLogo(whatsapp);
  //     // setInstagramLogo(instagram);
  //     // setFacebookLogo(facebook);
  //     setSocialMediaDetails(response.social_links);
  //     //End of comment by - Ashlekh on 21-01-2024
  //     //Reason - To change footer section

  //     // console.log("aaaaaaaaaa", whatsappLogo, instagramLogo, facebookLogo);
  //   }
  //   // console.log(socialPlatforms,'linkkkkkk')
  // };
  // Commented by Om Shrivastava on 01-04-24
  // Reason : Need to remove this method

  //Added by - Ashlekh on 01-02-2024
  const getWhatsAppDetails = async () => {
    const response = await getWhatsAppDetailsApi();
    // console.log("response@footer", response);
    if (response != null) {
      setWhatsAppDetails(response);
      setLogo(response?.header_logo);
    }
  };
  //End of code addition by - Ashlekh on 01-02-2024

  function submitForm() {
    var email2 = document.getElementById("email")?.value;

    console.log("Submitted email:", email2);
    // Here you can send the email value to your backend or perform any other action.
  }
  // Addition by Om Shrivastava on 04-04-24
  // Reason : Create method for email validation
  const InvalidMsg = (event) => {
    const textbox = event.target;
    const email = textbox.value.toLowerCase(); // Convert email to lowercase for case-insensitive matching
    if (email === "") {
      textbox.setCustomValidity("कृपया ई-मेल दर्ज करें | ");
    // } else if (textbox.validity.typeMismatch) {
    //   textbox.setCustomValidity("कृपया ई-मेल दर्ज करें");
    } else if (
      !email.endsWith("@gmail.com") &&
      !email.endsWith("@gmail.co.in")
    ) {
      textbox.setCustomValidity("कृपया सही फार्मेट में ईमेल दर्ज करें | ");
    } else {
      textbox.setCustomValidity("");
    }
  };
  // End of addition by Om Shrivastava on 04-04-24
  // Reason : Create method for email validation

  return (
    <div className={`${style.footerContainer}`}>
      <div className={`${style.footerSection1} ${style.element}`}>
        <div className={`${style.footerSection1Text}`}>
          {/* <Link to="" className={`${style.footerLink}`}>� 2024 All Rights Reserved. </Link> */}
          <div
            className={`${style.footerLink}`}
            // Addition by om Shrivastav on 22-01-24
            // Reason : set the font size
            // style={{ fontSize: "11px" }}
            // Addition by om Shrivastav on 22-01-24
            // Reason : set the font size
          >
            © 2024 All Rights Reserved.{" "}
          </div>
        </div>
      </div>

      <div className={`${style.combined}`}>
        <div className={`${style.footerSection2} ${style.element}`}>
          {/* <div className={`${style.subFooterSection2}`}> */}
          <Link to="/privacy_policy" className={`${style.footerLink}`}>
            {/* Privacy Policy |{" "} */}
            प्राइवेसी पॉलिसी
          </Link>
          {/* Addition by Om Shrivastava on 22-01-24 
          Reason : Create new div  */}
          <div>|</div>
          {/* End of addition by Om Shrivastava on 22-01-24 
          Reason : Create new div   */}
          <Link to="/terms_and_conditions" className={`${style.footerLink}`}>
            {/* Terms & Conditions */}
            नियम और शर्तें
          </Link>
          {/* </div> */}
        </div>

        <div className={`${style.footerSection3} ${style.element2}`}>
          {/* <div className={`${style.socialLinks}`}> */}
          {/* Code commented by - Ashlekh on 21-01-2024
          Reason - To fix social links */}
          {/* {whatsappLogo && whatsappLogo.length > 0 ? (
            <Link to="https://adyant.co.in/">
              <img
                src={config.baseURL + whatsappLogo}
                alt=""
                className={`${style.logo}`}
              />
            </Link>
          ) : (
            ""
          )}

          {facebookLogo && facebookLogo.length > 0 ? (
            <Link to="https://adyant.co.in/">
              <img
                src={config.baseURL + facebookLogo}
                alt=""
                className={`${style.logo}`}
              />
            </Link>
          ) : (
            ""
          )}

          {instagramLogo && instagramLogo.length > 0 ? (
            <Link to="https://adyant.co.in/">
              <img
                src={config.baseURL + instagramLogo}
                alt=""
                className={`${style.logo}`}
              />
            </Link>
          ) : (
            ""
          )} */}
          {socialMediaDetails?.map((socialMedia, index) => (
            <Link key={index} to={socialMedia.link} target="blank">
              <img
                src={config.baseURL + socialMedia.logo}
                alt={socialMedia.name}
                className={`${style.logo}`}
              />
            </Link>
          ))}
          {/* End of comment by - Ashlekh on 21-01-2024
          Reason - To fix social links */}
          {/* </div> */}
        </div>
      </div>

      <div className={`${style.footerSection4}`}>
        {/* Modification and addition by Om Shrivastava on 30-03-24
       Reason : Need to set simple form for submitting the email data */}
        <form
          // id="form"
          // onSubmit={formik.handleSubmit}
          // noValidate={true}
          onSubmit={(e) => {
            e.preventDefault();
            submitEmailRequest();
          }}
        >
          <div className={`${style.wrapper}`}>
            <input
              className={`${style.inputBtn2}`}
              type="email"
              id="email"
              name="email"
              // Addition by Om Shrivastava on 04-04-24
              // Reason : Create oninvalid and oninput for email validation
              onInvalid={InvalidMsg}
              onInput={InvalidMsg}
              // End of addition by Om Shrivastava on 04-04-24
              // Reason : Create oninvalid and oninput for email validation
              required
              placeholder="
                अपना ई-मेल दर्ज करें"
            />

            <button className={`${style.submitBtn}`} type="submit">
              <AiOutlineSend className={`${style.emailBtnn}`} color="black" />
            </button>
          </div>
        </form>
        {/* End of modification and addition by Om Shrivastava on 30-03-24
       Reason : Need to set simple form for submitting the email data */}
        {/* End of modification and addition by Om Shrivastava on 22-01-24
          Reason : Need to design the email button and apply the post functionality */}
        {/* </div> */}
      </div>

      <div className={`${style.footerSection5} ${style.element}`}>
        <div
          // Addition by om Shrivastav on 22-01-24
          // Reason : set the font size
          style={{ fontSize: "11px" }}
          // Addition by om Shrivastav on 22-01-24
          // Reason : set the font size
          className={`${style.responsiveCopyRight}`}
        >
          © 2024 All Rights Reserved.
        </div>
        <a
          // Addition by om Shrivastav on 22-01-24
          // Reason : set the font size
          style={{ color: "black", textDecoration: "none" }}
          href="https://adyant.co.in/"
          target="blank"
          // Addition by om Shrivastav on 22-01-24
          // Reason : set the font size
          className={`${style.footerSection5Text}`}
        >
          Powered By Adyant
        </a>
      </div>
      {/* Added by - Ashlekh on 02-02-2024
      Reason - For whatsapp  */}
      {whatsAppDetails.contact_number1 != null &&
      whatsAppDetails?.contact_number1?.length > 0 ? (
        <div className={`${style.whatsAppIcon}`} draggable={true}>
          <FloatingWhatsApp
            style={{ right: -30 }}
            // Code changed by - Ashlekh on 25-05-2024
            // Reason - To increase whatsapp icon height from bottom
            // buttonStyle={{ width: 40, height: 40, bottom: "8%" }}
            buttonStyle={{ width: 40, height: 40, bottom: "12%" }}
            // End of code - Ashlekh on 25-05-2024
            // Reason - To increase whatsapp icon height from bottom
            phoneNumber={whatsAppDetails?.contact_number1}
            avatar={config.baseURL + logo}
            accountName="Khallari Mata Mandir"
          />
        </div>
      ) : null}
      {/* End of code addition by - Ashlekh on 02-02-2024
       Reason - For whatsapp */}
    </div>
  );
};
export default Footer;
