// Modification and Commented by Om Shrivastava on 17-02-24
// Reason : Need to use formik in this jyot form
// Modification and Uncommented by Om Shrivastava on 17-02-24
// Reason : As per discussion with Nishant sir, need to use this code.

import jyotFormStyle from "./JyotForm.module.css";
import {
  checkTransactionApi,
  postJyotRequestApi,
  postRazorPayRequriedApi,
  checkTransactionApiForJyot,
  postRazorPayRequriedApiForJyot,
  getJyotReceiptApi,
} from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { useFormik } from "formik";
import * as yup from "yup";
import Constants from "../../constants/Constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToWords } from "to-words";
import useRazorpay from "react-razorpay";
import config from "../../Api/config";
import parse from "html-react-parser";

const JyotForm = (props) => {
  var [jyotRequestId, setJyotRequestId] = useState(0);
  // console.log("props@JyotForm", props);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  // Code changed by - Ashlekh on 23-02-2024
  // Reason - Parv is not used, instead of parv jyot will come
  // const [parvDataId, setParvDataId] = useState("");
  const [jyotDataId, setJyotDataId] = useState("");
  // End of code - Ashlekh on 23-02-2024
  // Reason - Parv is not used, instead of parv jyot will come
  const nav = useNavigate();
  // Added by - Ashlekh on 25-02-2024
  // Reason - For razorpay variable
  const [Razorpay] = useRazorpay();
  // End of code - Ashlekh on 25-02-2024
  // Reason - For razorpay variable
  const [genderDetails, setGenderDetails] = useState([
    { id: 0, name: Constants.MALE_TEXT },
    { id: 1, name: Constants.FEMALE_TEXT },
    { id: 2, name: Constants.OTHER_TEXT },
  ]);
  const [genderSelect, setGenderSelect] = useState();

  const [selectedJyotTypeOption, setSelectedJyotTypeOption] = useState(
    props.jyotData[0]?.id
  );
  // Added by - Ashlekh on 23-02-2024
  // Reason - To make useState for radio buttons for jyot and by default 1st will be selected
  const [selectedJyotOption, setSelectedJyotOption] = useState(
    props.jyotDetails[0]?.id
  );
  // End of code - Ashlekh on 23-02-2024
  // Reason - To make useState for radio buttons for jyot and by default 1st will be selected
  // Added by - Ashlekh on 23-02-2024
  // Reason - Radio button which is selected will set here
  const handleJyotDetailsChange = (event) => {
    setSelectedJyotOption(event.target.value);
  };
  // End of code - Ashlekh on 23-02-2024
  // Reason - Radio button which is selected will set here
  const handleJyotOptionChange = (event) => {
    setSelectedJyotTypeOption(event.target.value);
  };

  const handleGenderValueChange = (event) => {
    setGenderSelect(event.target.value);
  };

  var selectedJyotOptionData = props.jyotData.find(
    (option) => parseInt(option.id) === parseInt(selectedJyotTypeOption)
  );
  // Added by - Ashlekh on 23-02-2024
  // Reason - To store details of selected jyot
  var selectedJyotDetailsOptionData = props.jyotDetails.find(
    (index) => parseInt(index.id) === parseInt(selectedJyotOption)
  );
  // End of code - Ashlekh on 23-02-2024
  // Reason - To store details of selected jyot

  useEffect(() => {
    setSelectedJyotTypeOption(props.jyotData[0]?.id);
  }, [props.jyotData[0]?.id]);
  // useEffect(() => {
  //   setGenderSelect(genderDetails[0]?.name);
  // }, [genderDetails[0]?.name]);
  // Code changed by - Ashlekh on 23-02-2024
  // Reason - parv will not be used, instead jyot will come
  // useEffect(() => {
  //   setParvDataId(props.parvData?.id);
  // }, [props.parvData?.id]);
  // useEffect(() => {
  //   setSelectedJyotOption(props?.jyotDetails[0]?.id);
  // }, [props?.jyotDetails[0]?.id]);
  // End of code - Ashlekh on 23-02-2024
  // Reason - parv will not be used, instead jyot will come

  useEffect(() => {
    setIsDataLoaded(true);
  }, []);

  var toWords = new ToWords({
    localeCode: "hi-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: "Rupee",
        plural: "रुपये ",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });
  // Code changed by - Ashlekh on 24-02-2024
  // Reason - To convert whole amount (jyot & jyot type) in hindi
  // var amountInHindi = toWords?.convert(
  //   selectedJyotOptionData?.amount != undefined
  //     ? selectedJyotOptionData?.amount
  //     : 0
  // );
  var amountInHindi = toWords?.convert(
    parseFloat(selectedJyotOptionData?.amount ?? 0) +
      parseFloat(selectedJyotDetailsOptionData?.amount ?? 0)
  );
  // End of code - Ashlekh on 24-02-2024
  // Reason - To convert whole amount (jyot & jyot type) in hindi

  const formik = useFormik({
    initialValues: {
      // Code changed by - Ashlekh on 23-02-2024
      // Reason - Parv will not be used, instead jyot will come
      // parv: parvDataId,
      jyot: selectedJyotOption,
      // End of code - Ashlekh on 23-02-2024
      // Reason - Parv will not be used, instead jyot will come
      jyot_type: selectedJyotTypeOption,

      name: document.getElementById("userName")?.value,
      dob: document.getElementById("userAge")?.value,

      gender: genderSelect ? genderSelect : "",

      address: document.getElementById("userAddress")?.value,
      contact_number: document.getElementById("userContact")?.value,
      // Code changed by - Ashlekh on 23-02-2024
      // Reason - total amount must be displayed ( jyot & jyotType )
      // amount: selectedJyotOptionData?.amount,
      // amount:
      //   parseFloat(selectedJyotOptionData?.amount ?? 0) +
      //   parseFloat(selectedJyotDetailsOptionData?.amount ?? 0),
      amount: document.getElementById("totalAmountJyotAndJyotType")?.value,
      currency: "INR",
      // Code changed by - Ashlekh on 23-02-2024
      // Reason - total amount must be displayed ( jyot & jyotType )
      // Reason - parv will not be used, instead jyot will come
      // fromDate: props.parvData.fromDate,
      // endDate: props.parvData.endDate,
      // fromDate: document.getElementById("fromDate")?.value
      //   ? new Date(document.getElementById("fromDate")?.value)
      //       .toISOString()
      //       .split("T")[0]
      //   : "",
      // endDate: document.getElementById("endDate")?.value
      //   ? new Date(document.getElementById("endDate")?.value)
      //       .toISOString()
      //       .split("T")[0]
      //   : "",
      // fromDate: document.getElementById("fromDate")?.value,
      // endDate: document.getElementById("endDate")?.value,
      // End of code - Ashlekh on 23-02-2024
      // Reason - parv will not be used, instead jyot will come
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      submitJyotRequest(values);
    },
    validationSchema: yup.object({
      name: yup
        .string()

        .matches(
          /^[a-zA-Z0-9\u0900-\u097F\s]+$/,
          "कृपया सही प्रारूप में नाम दर्ज करे | "
        )

        .required("कृपया नाम दर्ज करें | "),

      contact_number: yup
        .number()
        .transform((value, originalValue) =>
          /\s/.test(originalValue) ? NaN : value
        )
        .integer()
        .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें | ")
        .required("कृपया मोबाइल नंबर दर्ज करें |")
        .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें | ")
        .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें | "),
    }),
  });

  /**
   * Added by - Ashish Dewangan on 10-03-2024
   * Reason - To get jyot request details from backend and send it to receipt page
   */
  async function getJyotReceipt(jyotReceiptId) {
    const response = await getJyotReceiptApi(jyotReceiptId);
    if (response) {
      nav("/jyot_receipt", { state: response?.jyot_receipt });
    }
  } 
  /**
   * End of code addition by - Ashish Dewangan on 10-03-2024
   * Reason - To get jyot request details from backend and send it to receipt page
   */

  // Added by - Ashlekh on 25-02-2024
  // Reason -
  async function razorpay_data(amount, orderId) {
    const requestData = {
      amount: parseFloat(amount),
      currency: "INR",
      // Added by - Ashlekh on 04-03-2024
      // Reason - To send orderId to razorpay so that we can save razorpay orderId in our jyotRequest Table
      rowId: orderId,
      key: "jyot",
      // End of code - Ashlekh on 04-03-2024
      // Reason - To send orderId to razorpay so that we can save razorpay orderId in our jyotRequest Table
    };
    const response = await postRazorPayRequriedApi(requestData);
    if (response) {
      // console.log("dataInResponse", response.data);
      var order_id = response?.data?.id;
      var amount = response?.data?.amount;
      var objectid = response?.details?.id;
      setJyotRequestId(response?.details?.id);
      // console.log("amounttt", amount);

      const options = {
        // Modification and addition by Om Shrivastava on 11-03-24
        // Reason : Need to add the razorpay key id
        // key: "rzp_test_FU5SNWRBelrZDX", // Enter the Key ID generated from the Dashboard
        key: config.RAZORPAY_KEY_ID,
        // Enf of modification and addition by Om Shrivastava on 11-03-24
        // Reason : Need to add the razorpay key id
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          // complete_order(
          //   response.razorpay_payment_id,
          //   response.razorpay_order_id,
          //   response.razorpay_signature,
          //   amount
          // );
          // Added by - Ashlekh on 09-03-2024
          // Reason - When payment is completed then  navigate in jyot receipt
          // console.log("jyotRequestId22222222222222222222", objectid);
          getJyotReceipt(objectid);
          // nav("/jyot_receipt", { state:objectid });
          // End of code - Ashlekh on 09-03-2024
          // Reason - When payment is completed then navigate in jyot receipt
        },

        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
          request_for: "jyot",
          // Added by - Ashlekh on 09-03-2024
          // Reason - To send rowId (so that we can navigate to receipt page)
          rowId: objectid,
          // End of code - Ashlekh on 09-03-2024
          // Reason - To send rowId (so that we can navigate to receipt page)
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    }
  }

  async function complete_order(paymentID, orderID, signature, amount) {
    const requestData = {
      payment_id: paymentID,
      order_id: orderID,
      signature: signature,
      amount: amount,
    };
    // console.log("requestData", requestData);
    // const response = await checkTransactionApi(requestData);
    // if (response?.message == "transaction created") {
    //   console.log("checkTransactionAPI", response);
    //   // nav("/jyot_receipt", { state: response.jyot_request_details });
    // }
  }
  // End of code - Ashlekh on 25-02-2024
  // Reason
  async function submitJyotRequest(values) {
    const requestData = {
      // Code commented by - Ashlekh on 23-02-2024
      // Reason - Instead of parv, jyot will come
      // parv: values.parv,
      jyot: values.jyot,
      // End of comment - Ashlekh on 23-02-2024
      // Reason - Instead of parv, jyot will come
      jyot_type: values.jyot_type,
      name: values.name,
      dob: values.dob,
      gender: values.gender,
      address: values.address,
      contact_number: values.contact_number,
      amount: values.amount,
      // Commented by - Ashlekh on 28-02-2024
      // Reason - No need to save dates from frontend
      // fromDate: values.fromDate,
      // endDate: values.endDate,
      // End of comment - Ashlekh on 28-02-2024
      // Reason - No need to save dates from frontend
    };

    const response = await postJyotRequestApi(requestData);

    if (response.message) {
      formik.resetForm();
      document.getElementById("form").reset();
      //  Modification and addition by Om Shrivastava on 20-05-2024
      //  Reason : Currently No use of payment gateway 
      // // notificationObject.dismissAll();
      // // notificationObject.success(response.message);
      // var totalAmount = response.jyot_request_details.amount;
      // // console.log("totalAmount", totalAmount);
      // // Code changed by - Ashlekh on 04-03-2024
      // // Reason - To save order Id
      // var orderId = response?.jyot_request_details?.id;
      // // razorpay_data(totalAmount);
      // razorpay_data(totalAmount, orderId);
      var objectid = response?.jyot_request_details?.id;
      getJyotReceipt(objectid);
      //  End of modification and addition by Om Shrivastava on 20-05-2024
      //  Reason : Currently No use of payment gateway 
      // End of code - Ashlekh on 04-03-2024
      // nav("/jyot_receipt", { state: response.jyot_request_details });
    } else if (response.error) {
      notificationObject.dismissAll();
      notificationObject.error(response.error);
    }
  }

  function ExtractDateTime(format) {
    var d = new Date(format);
    var date = format?.substring(0, 10).split("-").reverse().join("/");
    var time = format?.substring(11, 19);

    return { date, time };
  }

  // Added by - Ashlekh on 28-02-2024
  // Reason - To format date
  function formateDate(dateString) {
    const parts = dateString.split("T")[0].split("-");
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  }
  // End of code - Ashlekh on 28-02-2024
  // Reason - To format date
  return (
    <div className={`${jyotFormStyle.formContainer}`}>
      {/* Added by - Ashlekh on 26-02-2024
      Reason - If jyot and jyot type is not added from backend then form will not display */}
      {props?.jyotDetails != null &&
      props?.jyotDetails.length > 0 &&
      props?.jyotData != [] > 0 ? (
        // End of code - Ashlekh on 26-02-2024
        // Reason - If jyot and jyot type is not added then form will not display
        <div>
          <form
            className={jyotFormStyle.form}
            id="form"
            onSubmit={formik.handleSubmit}
            noValidate={true}
          >
            {/* Code changed by - Ashlekh on 23-02-2024
        Reason - For displaying jyot radio buttons */}
            {/* <div className={jyotFormStyle.inputHolder}>
          <label className={jyotFormStyle.label} style={{ paddingLeft: "1px" }}>
            {Constants.PARV_TEXT}
          </label>
         
          <div className={`${jyotFormStyle.colon}`}> : </div>
         
          <div className={jyotFormStyle.inputWithError}>
            <input
              type="text"
              name="parv"
              id="parv"
              disabled
              {...formik.getFieldProps("parv")}
              maxLength={50}
              className={jyotFormStyle.inputBox}
              value={props.parvData.name}
            />
          </div>
        </div> */}
            <div className={jyotFormStyle.inputHolder}>
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "1px" }}
              >
                {Constants.JYOT_TITLE_TEXT}
              </label>

              <div className={`${jyotFormStyle.colon}`}> : </div>

              <div
                className={jyotFormStyle.label}
                style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
              >
                {props?.jyotDetails?.map((index) => (
                  <div
                    key={index.id}
                    style={{
                      paddingLeft: "2%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      htmlFor={`jyot-${index.id}`}
                      // style={{ fontWeight: "bold" }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <div style={{ paddingTop: "2px" }}>
                          <input
                            type="radio"
                            name="jyot"
                            {...formik.getFieldProps("jyot")}
                            // id="html"
                            id={`jyot-${index.id}`}
                            value={index.id}
                            checked={parseInt(selectedJyotOption) === index.id}
                            onChange={handleJyotDetailsChange}
                          />
                        </div>
                        &nbsp;{" "}
                        {/* <label style={{ marginTop: "1%" }} htmlFor="html">
                      {index.name.charAt(0).toUpperCase() + index.name.slice(1)}
                      
                    </label> */}
                        <div>
                          {index.name.charAt(0).toUpperCase() +
                            index.name.slice(1)}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* End of code - Ashlekh on 23-02-2024
        Reason - For displaying jyot radio buttons */}

            <div className={jyotFormStyle.inputHolder}>
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "1px" }}
              >
                {Constants.JYOTTYPE_TEXT}
              </label>

              <div className={`${jyotFormStyle.colon}`}> : </div>

              <div
                className={jyotFormStyle.label}
                style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
              >
                {props.jyotData.map((option) => (
                  <div
                    key={option.id}
                    style={{
                      paddingLeft: "2%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label htmlFor={`jyot_type-${option.id}`}>
                      <div
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <div style={{ paddingTop: "2px" }}>
                          <input
                            type="radio"
                            name="jyot_type"
                            {...formik.getFieldProps("jyot_type")}
                            // id="jyot_type"
                            id={`jyot_type-${option.id}`}
                            value={option.id}
                            checked={
                              parseInt(selectedJyotTypeOption) === option.id
                            }
                            onChange={handleJyotOptionChange}
                          />
                        </div>
                        &nbsp;{" "}
                        <div>
                          {option.name.charAt(0).toUpperCase() +
                            option.name.slice(1)}
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className={jyotFormStyle.inputHolder}>
              <label className={jyotFormStyle.label}>
                {Constants.NAME_TEXT}
                <span style={{ color: "red" }}> * </span>
              </label>

              <div className={`${jyotFormStyle.colon}`}> : </div>
              <div className={jyotFormStyle.inputWithError}>
                <input
                  type="text"
                  name="name"
                  id="userName"
                  {...formik.getFieldProps("name")}
                  maxLength={50}
                  className={jyotFormStyle.inputBox}
                />
                {formik.touched.name && formik.errors.name ? (
                  <span className={`${jyotFormStyle.validationMsgContainer}`}>
                    {formik.errors.name}
                  </span>
                ) : null}
              </div>
            </div>

            <div className={jyotFormStyle.inputHolder}>
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "1px" }}
              >
                {Constants.AGE_TEXT}
              </label>

              <div className={`${jyotFormStyle.colon}`}> : </div>

              <div className={jyotFormStyle.inputWithError}>
                <input
                  type="number"
                  name="dob"
                  id="userAge"
                  {...formik.getFieldProps("dob")}
                  maxLength={50}
                  className={jyotFormStyle.ageInputBox}
                />
              </div>
            </div>

            <div className={jyotFormStyle.inputHolder}>
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "1px" }}
              >
                {Constants.GENDER_TEXT}
              </label>

              <div className={`${jyotFormStyle.colon}`}> : </div>
              {genderDetails.map((option) => (
                <div
                  key={option.id}
                  style={{
                    paddingLeft: "1%",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1%",
                  }}
                >
                  {/* Code changed by - Ashlekh on 24-02-2024
              Reason - Radio button is not selecting when we click on gender text  */}
                  {/* <input
                type="radio"
                name="gender"
                {...formik.getFieldProps("gender")}
                id="gender"
                value={option.name}
                checked={genderSelect === option.name}
                onChange={handleGenderValueChange}
              />
              <label htmlFor="html" style={{ fontWeight: "bold" }}>
              &nbsp;
                {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
              </label> */}
                  <label
                    htmlFor={`gender-${option.id}`}
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="gender"
                      {...formik.getFieldProps("gender")}
                      id={`gender-${option.id}`}
                      value={option.name}
                      checked={genderSelect === option.name}
                      onChange={handleGenderValueChange}
                      // style={{ border: "1px solid green" }}
                    />
                    &nbsp;
                    {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
                  </label>
                  {/* End of code - Ashlekh on 24-02-2024
              Reason - Radio button is not selecting when we click on gender text */}
                </div>
              ))}
            </div>

            <div className={jyotFormStyle.inputHolder}>
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "1px" }}
              >
                {Constants.ADDRESS_TEXT}
              </label>

              <div className={`${jyotFormStyle.colon}`}> : </div>

              <div className={jyotFormStyle.inputWithError}>
                <textarea
                  type="text"
                  name="address"
                  id="userAddress"
                  {...formik.getFieldProps("address")}
                  className={`${jyotFormStyle.inputBox} ${jyotFormStyle.textArea}`}
                />
              </div>
            </div>

            <div className={jyotFormStyle.inputHolder}>
              <label className={jyotFormStyle.label}>
                {Constants.CONTACT_TEXT}
                <span style={{ color: "red" }}> * </span>
              </label>
              <div className={`${jyotFormStyle.colon}`}> : </div>
              <div className={jyotFormStyle.inputWithError}>
                <input
                  name="contact_number"
                  id="userContact"
                  pattern="[1-9]{1}[0-9]{9}"
                  {...formik.getFieldProps("contact_number")}
                  className={jyotFormStyle.inputBox}
                />
                {formik.touched.contact_number &&
                formik.errors.contact_number ? (
                  <span className={`${jyotFormStyle.validationMsgContainer}`}>
                    {formik.errors.contact_number}
                  </span>
                ) : null}
              </div>
            </div>
            {/* Code commented by - Ashlekh on 23-02-2024
        Reason - To display date from jyot */}
            {/* <div className={jyotFormStyle.inputHolder}>
          <label className={jyotFormStyle.label} style={{ paddingLeft: "1px" }}>
            {Constants.DATE_TEXT}
          </label>
          <div className={`${jyotFormStyle.colon}`}> : </div>
          <div className={jyotFormStyle.inputWithError}>
            <div
              className={jyotFormStyle.dateContainer}
            >
              <input
                name="fromDate"
                disabled
                {...formik.getFieldProps("fromDate")}

                value={ExtractDateTime(props.parvData.fromDate).date}
                className={jyotFormStyle.inputBox}
                style={{ width: "50%" }}
              />
              <input
                name="endDate"
                disabled
                {...formik.getFieldProps("endDate")}
                value={ExtractDateTime(props.parvData.endDate).date}
                className={jyotFormStyle.inputBox}
                style={{ width: "50%" }}
              />
            </div>
          </div>
        </div> */}
            <div className={jyotFormStyle.inputHolder}>
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "1px" }}
              >
                {Constants.DATE_TEXT}
              </label>
              <div className={`${jyotFormStyle.colon}`}> : </div>
              <div className={jyotFormStyle.inputWithError}>
                <div className={jyotFormStyle.dateContainer}>
                  {/* <input
                    name="fromDate"
                    id="fromDate"
                    disabled
                    value={
                      // selectedJyotOption &&
                      // props?.jyotDetails &&
                      props?.jyotDetails?.find((jyot) => {
                        // console.log("jyottt", jyot);
                        // console.log("jyotId", jyot.id);
                        // console.log("selectedJyotOption", selectedJyotOption);
                        // console.log("checkkk", jyot.id == selectedJyotOption);
                        return jyot.id == selectedJyotOption;
                      })
                        ? new Date(
                            props?.jyotDetails?.find((jyot) => {
                              return jyot.id == selectedJyotOption;
                            }).fromDate
                          ).toLocaleDateString()
                        : "bbb"
                    }
                    className={jyotFormStyle.inputBox}
                    style={{ width: "50%" }}
                  />
                  <input
                    name="endDate"
                    id="endDate"
                    disabled
                    value={
                      selectedJyotOption &&
                      props.jyotDetails &&
                      props.jyotDetails.find(
                        (jyot) => jyot.id == selectedJyotOption
                      )
                        ? new Date(
                            props?.jyotDetails?.find(
                              (jyot) => jyot.id == selectedJyotOption
                            ).endDate
                          ).toLocaleDateString()
                        : "aaa"
                    }
                    className={jyotFormStyle.inputBox}
                    style={{ width: "50%" }}
                  /> */}
                  <input
                    name="fromDate"
                    id="fromDate"
                    disabled
                    value={
                      // selectedJyotOption &&
                      // props?.jyotDetails &&
                      props?.jyotDetails?.find((jyot) => {
                        // console.log("jyottt", jyot);
                        // console.log("jyotId", jyot.id);
                        // console.log("selectedJyotOption", selectedJyotOption);
                        // console.log("checkkk", jyot.id == selectedJyotOption);
                        return jyot.id == selectedJyotOption;
                      })
                        ? formateDate(
                            props?.jyotDetails?.find((jyot) => {
                              return jyot.id == selectedJyotOption;
                            }).fromDate
                          )
                        : "bbb"
                    }
                    className={jyotFormStyle.inputBox}
                    style={{ width: "50%" }}
                  />
                  <input
                    name="endDate"
                    id="endDate"
                    disabled
                    value={
                      selectedJyotOption &&
                      props.jyotDetails &&
                      props.jyotDetails.find(
                        (jyot) => jyot.id == selectedJyotOption
                      )
                        ? formateDate(
                            props?.jyotDetails?.find(
                              (jyot) => jyot.id == selectedJyotOption
                            ).endDate
                          )
                        : "aaa"
                    }
                    className={jyotFormStyle.inputBox}
                    style={{ width: "50%" }}
                  />
                </div>
              </div>
            </div>
            {/* End of code - Ashlekh on 23-02-2024
Reason - To display date from jyot */}

            <div
              className={`${jyotFormStyle.inputHolder} ${jyotFormStyle.inputHolderamount}`}
            >
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "3px" }}
              >
                {Constants.RUPEE_TEXT}
              </label>
              <div
                className={`${jyotFormStyle.colon}`}
                style={{ paddingLeft: "2px" }}
              >
                {" "}
                :{" "}
              </div>
              <div
                className={jyotFormStyle.inputWithError}
                style={{ paddingLeft: "1px" }}
              >
                <input
                  type="text"
                  name="amount"
                  id="totalAmountJyotAndJyotType"
                  style={{ width: "50%" }}
                  disabled
                  {...formik.getFieldProps("amount")}
                  maxLength={50}
                  className={jyotFormStyle.inputBox}
                  // Code changed by - Ashlekh on 23-02-2024
                  // Reason - both jyot & jyot detail amount will add and display
                  // value={
                  //   selectedJyotOptionData ? selectedJyotOptionData.amount : ""
                  // }
                  // value={
                  //   selectedJyotOptionData && selectedJyotDetailsOptionData
                  //     ? `${parseFloat(selectedJyotOptionData.amount).toFixed(2)} + ${parseFloat(selectedJyotDetailsOptionData.amount).toFixed(2)} = ${(parseFloat(selectedJyotOptionData.amount) + parseFloat(selectedJyotDetailsOptionData.amount)).toFixed(2)}`
                  //     : ""
                  // }
                  value={
                    selectedJyotOptionData && selectedJyotDetailsOptionData
                      ? parseFloat(selectedJyotOptionData.amount) +
                        parseFloat(selectedJyotDetailsOptionData.amount)
                      : ""
                  }
                  // End of code - Ashlekh on 23-02-2024
                  // Reason - both jyot & jyot detail amount will add and display
                />
              </div>
            </div>

            <div
              className={`${jyotFormStyle.inputHolder} ${jyotFormStyle.inputHolderamountInWord}`}
            >
              <label
                className={jyotFormStyle.label}
                style={{ paddingLeft: "3px" }}
              >
                {Constants.RUPEE_IN_WORD}
              </label>
              <div
                className={`${jyotFormStyle.colon}`}
                style={{ paddingLeft: "2px" }}
              >
                {" "}
                :{" "}
              </div>
              <div className={jyotFormStyle.inputWithError}>
                ( {amountInHindi})
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ color: "black", fontWeight: "bold" }}>
                कृपया भुगतान के बाद पुष्टि करें :-
              </div>
            </div>
            <div
              className={jyotFormStyle.buttonContainer}
              style={{
                paddingTop: "2%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className={jyotFormStyle.button} type="submit">
                {Constants.SUBMIT_TEXT}
              </button>
            </div>

            {/* Code commented by - Ashlekh on 19-03-2024
            Reason - To remove bullet points information */}
            {/* Addition by Om Shrivastava on 18-05-2024
            Reason : Add the jyot form description part  */}
            <div className={jyotFormStyle.descriptionContainer}>
              {parse(
                props.jyotDescription != null &&
                  props.jyotDescription.length > 0
                  ? // Modification and commented by Om shrivastava on 18-03-24
                    // Reason : Need to add this section in div
                    // props.donationDescription
                    `<div >
                    ${props.jyotDescription}
                  </div>`
                  : // End of modification and commented by Om shrivastava on 18-03-24
                    // Reason : Need to add this section in div
                    ""
              )}
            </div>
            {/* End of addition by Om Shrivastava on 18-05-2024
            Reason : Add the jyot form description part  */}
            {/* <div
              className={jyotFormStyle.label}
              style={{
                width: "100%",
                fontSize: "12px",
                fontWeight: "500",
                color: "red",
                paddingLeft: "1%",
                display: "flex",
                paddingTop: "1%",
              }}
            >
              <span style={{ color: "red", fontSize: "18px" }}>• </span>
              <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>
                तेल ज्योति कलश को प्रज्वलित करने के लिए तेल का उपयोग किया जाता
                हैं, जिसका मूल्य 1001/- होगा।
              </span>
            </div> */}

            {/* <div
              className={jyotFormStyle.label}
              style={{
                width: "100%",
                fontSize: "12px",
                fontWeight: "500",
                color: "red",
                paddingLeft: "1%",

                display: "flex",
              }}
            >
              <span style={{ color: "red", fontSize: "18px" }}>• </span>
              <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>
                घी ज्योति कलश को प्रज्वलित करने के लिए देशी घी का उपयोग किया
                जाता हैं,जिसका मूल्य 2001/- होगा।
              </span>
            </div> */}

            {/* <div
              className={jyotFormStyle.label}
              style={{
                width: "100%",
                fontSize: "12px",
                fontWeight: "500",
                color: "red",
                paddingLeft: "1%",

                display: "flex",
              }}
            >
              <span style={{ color: "red", fontSize: "18px" }}>• </span>
              <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>
                ऊपर उल्लेखित ज्योति कलशों में से किसी को भी आपके नाम पर आजीवन
                जलाए रखने का विकल्प चुना जा सकता हैं।
              </span>
            </div> */}
            {/* End of comment - Ashlekh on 19-03-2024
            Reason - To remove bullet points information */}
          </form>
        </div>
      ) : null}
    </div>
  );
};

export default JyotForm;

// Formik code

// import jyotFormStyle from "./JyotForm.module.css";
// import { postJyotRequestApi } from "../../Api/services";
// import notificationObject from "../../components/Widgets/Notification/Notification";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import Constants from "../../constants/Constants";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { ToWords } from "to-words";
// import { Formik, Form, Field, ErrorMessage } from "formik";

// const JyotForm = (props) => {
//   const [parvDataId, setParvDataId] = useState(props.parvData?.id);
//   const nav = useNavigate();

//   const productOptions = props.jyotData.map((product, key) => (
//     <option value={product.id} key={key.id}>
//       {product.name}
//     </option>
//   ));

//   console.log(props);

//   const [genderDetails, setGenderDetails] = useState([
//     { id: 0, name: Constants.MALE_TEXT },
//     { id: 1, name: Constants.FEMALE_TEXT },
//     { id: 2, name: Constants.OTHER_TEXT },
//   ]);

//   const [genderSelect, setGenderSelect] = useState(genderDetails[0]?.name);

//   const [selectedJyotTypeOption, setSelectedJyotTypeOption] = useState(
//     props.jyotData[0]?.id
//   );

//   const handleJyotOptionChange = (event) => {
//     setSelectedJyotTypeOption(event.target.value);
//   };

//   const handleGenderValueChange = (event) => {
//     setGenderSelect(event.target.value);
//   };

//   var selectedJyotOptionData = props.jyotData.find(
//     (option) => parseInt(option.id) === parseInt(selectedJyotTypeOption)
//   );

//   useEffect(() => {
//     setSelectedJyotTypeOption(props.jyotData[0]?.id);
//   }, [props.jyotData[0]?.id]);

//   useEffect(() => {
//     setGenderSelect(genderDetails[0]?.name);
//   }, [genderDetails[0]?.name]);

//   useEffect(() => {
//     setParvDataId(props.parvData?.id);
//   }, [props.parvData?.id]);

//   var toWords = new ToWords({
//     localeCode: "hi-IN",
//     converterOptions: {
//       currency: true,
//       ignoreDecimal: false,
//       ignoreZeroCurrency: false,
//       doNotAddOnly: false,
//       currencyOptions: {
//         name: "Rupee",
//         plural: "रुपये ",
//         symbol: "₹",
//         fractionalUnit: {
//           name: "Paisa",
//           plural: "Paise",
//           symbol: "",
//         },
//       },
//     },
//   });

//   var amountInHindi = toWords?.convert(
//     selectedJyotOptionData?.amount != undefined
//       ? selectedJyotOptionData?.amount
//       : 0
//   );

//   async function submitJyotRequest(values) {
//     const requestData = {
//       parv: values.parv,
//       jyot_type: values.jyot_type,
//       name: values.name,
//       dob: values.dob,
//       gender: values.gender,
//       address: values.address,
//       contact_number: values.contact_number,
//       amount: values.amount,
//       fromDate: values.fromDate,
//       endDate: values.endDate,
//     };

//     const response = await postJyotRequestApi(requestData);

//     if (response.message) {
//       notificationObject.dismissAll();
//       notificationObject.success(response.message);
//       nav("/jyot_receipt", { state: response.jyot_request_details });
//     } else if (response.error) {
//       notificationObject.dismissAll();
//       notificationObject.error(response.error);
//     }
//   }

//   function ExtractDateTime(format) {
//     var d = new Date(format);
//     var date = format?.substring(0, 10).split("-").reverse().join("/");
//     var time = format?.substring(11, 19);

//     return { date, time };
//   }
//   console.log(parvDataId);
//   const initialValues = {
//     parv: props.parvData.id,
//     jyot_type: selectedJyotTypeOption,
//     name: "",
//     dob: "",
//     gender: "",
//     address: "",
//     contact_number: "",
//     amount: selectedJyotOptionData?.amount,

//     fromDate: props.parvData.fromDate,
//     endDate: props.parvData.endDate,
//   };
//   const validationSchema = yup.object({
//     jyot_type: yup.string().required("कृपया ज्योत के प्रकार चुने"),
//     name: yup
//       .string()
//       .matches(
//         /^[a-zA-Z0-9\u0900-\u097F\s]+$/,
//         "कृपया सही प्रारूप में नाम दर्ज करे"
//       )
//       .required("कृपया नाम दर्ज करें"),
//     contact_number: yup
//       .number()
//       .transform((value, originalValue) =>
//         /\s/.test(originalValue) ? NaN : value
//       )
//       .integer()
//       .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें")
//       .required("कृपया मोबाइल नंबर दर्ज करें")
//       .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें")
//       .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें"),
//   });

//   const onSubmit = (values) => {
//     alert(JSON.stringify(values, null, 2));
//     submitJyotRequest(values);
//   };

//   const renderError = (message) => (
//     <span className={`${jyotFormStyle.validationMsgContainer}`}>{message}</span>
//   );
//   console.log(selectedJyotOptionData.amount);

//   return (
//     <div className={`${jyotFormStyle.formContainer}`}>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={async (values, { resetForm }) => {
//           await onSubmit(values);
//           resetForm();
//         }}
//         enableReinitialize={true}
//       >
//         <Form>
//           <div className={jyotFormStyle.inputHolder}>
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "1px" }}
//             >
//               {/* <span style={{ color: "red" }}>*</span> */}
//               {Constants.PARV_TEXT}
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div className={jyotFormStyle.inputWithError}>
//               <Field
//                 name="parv"
//                 type="text"
//                 className={jyotFormStyle.inputBox}
//                 disabled
//                 value={props.parvData.name}
//               />
//             </div>
//           </div>

//           <div className={jyotFormStyle.inputHolder}>
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "1px" }}
//             >
//               {Constants.JYOTTYPE_TEXT}
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div
//               className={jyotFormStyle.label}
//               style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
//             >
//               <div className={jyotFormStyle.manageWidthForRadioAndDropdown}>

//                 {props.jyotData.map((dataa) => (
//                   <label
//                     style={{
//                       paddingLeft: "1%",
//                     }}
//                     key={dataa.id.toString()}
//                   >
//                     <Field
//                       type="radio"
//                       name="jyot_type"
//                       value={dataa.id.toString()}
//                       checked={parseInt(selectedJyotTypeOption) === dataa.id}
//                       onChange={handleJyotOptionChange}
//                     />
//                     {dataa.name}
//                   </label>
//                 ))}
//                 {console.log(props.jyotData)}
//               </div>

//               <ErrorMessage
//                 name="jyot_type"
//                 render={renderError}
//               />
//             </div>
//           </div>

//           <div className={jyotFormStyle.inputHolder}>
//             <label className={jyotFormStyle.label}>
//               {Constants.NAME_TEXT}
//               <span style={{ color: "red" }}> * </span>
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div className={jyotFormStyle.inputWithError}>
//               <Field
//                 name="name"
//                 type="text"
//                 className={jyotFormStyle.inputBox}
//               />
//               <ErrorMessage name="name" render={renderError} />
//             </div>
//           </div>

//           <div className={jyotFormStyle.inputHolder}>
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "1px" }}
//             >
//               {Constants.AGE_TEXT}
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div className={jyotFormStyle.inputWithError}>
//               <Field
//                 name="dob"
//                 type="number"
//                 className={jyotFormStyle.ageInputBox}
//               />
//             </div>
//           </div>

//           <div
//             className={jyotFormStyle.inputHolder}
//             role="group"
//             aria-labelledby="radio-group"
//           >
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "1px" }}
//             >
//               {Constants.GENDER_TEXT}
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div
//               className={jyotFormStyle.label}
//               style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
//             >
//               {genderDetails.map((dataa) => (
//                 <label
//                   key={dataa.id.toString()}
//                   style={{
//                     paddingLeft: "2%",
//                     display: "flex",
//                     alignItems: "center",
//                     fontSize: "13px",
//                   }}
//                 >
//                   <Field
//                     type="radio"
//                     name="gender"
//                     value={dataa.name}
//                     className={jyotFormStyle.inputBox}
//                   />

//                   {dataa.name}
//                 </label>
//               ))}
//               <div
//                 style={{ paddingLeft: "2.9%" }}
//                 className={jyotFormStyle.manageWidthForRadioAndDropdown}
//               >
//                 <ErrorMessage name="gender" render={renderError} />
//               </div>
//             </div>
//           </div>

//           <div className={jyotFormStyle.inputHolder}>
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "1px" }}
//             >
//               {Constants.ADDRESS_TEXT}
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div className={jyotFormStyle.inputWithError}>
//               <Field
//                 component="textarea"
//                 id="textareaField"
//                 name="address"
//                 className={jyotFormStyle.inputBox}
//               />
//             </div>
//           </div>

//           <div className={jyotFormStyle.inputHolder}>
//             <label className={jyotFormStyle.label}>
//               {Constants.CONTACT_TEXT}
//               <span style={{ color: "red" }}> * </span>
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div className={jyotFormStyle.inputWithError}>
//               <Field
//                 name="contact_number"
//                 type="number"
//                 className={jyotFormStyle.inputBox}
//               />
//               <ErrorMessage name="contact_number" render={renderError} />
//             </div>
//           </div>

//           <div className={jyotFormStyle.inputHolder}>
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "1px" }}
//             >
//               {Constants.DATE_TEXT}
//             </label>
//             <div className={`${jyotFormStyle.colon}`}> : </div>
//             <div className={jyotFormStyle.inputWithError}>
//               <div className={jyotFormStyle.dateContainer}>

//                 <Field
//                   name="fromDate"
//                   type="text"
//                   className={jyotFormStyle.inputBox}
//                   value={ExtractDateTime(props.parvData.fromDate).date}
//                   disabled
//                 />
//                 <Field
//                   name="endDate"
//                   type="text"
//                   value={ExtractDateTime(props.parvData.endDate).date}
//                   disabled
//                   className={jyotFormStyle.inputBox}
//                 />
//               </div>
//             </div>
//           </div>

//           <div

//             className={`${jyotFormStyle.inputHolder} ${jyotFormStyle.inputHolderamount}`}

//           >
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "3px" }}
//             >
//               {Constants.RUPEE_TEXT}
//             </label>
//             <div
//               className={`${jyotFormStyle.colon}`}
//               style={{ paddingLeft: "2px" }}
//             >
//               :{" "}
//             </div>
//             <div
//               className={jyotFormStyle.inputWithError}
//               style={{ paddingLeft: "1px" }}
//             >

//               <Field
//                 name="amount"
//                 type="text"
//                 disabled
//                 value={
//                     selectedJyotOptionData ? selectedJyotOptionData.amount : ""
//                   }
//                 className={jyotFormStyle.inputBox}
//               />
//             </div>
//           </div>
//           <div
//             className={`${jyotFormStyle.inputHolder} ${jyotFormStyle.inputHolderamountInWord}`}
//           >
//             <label
//               className={jyotFormStyle.label}
//               style={{ paddingLeft: "3px" }}
//             >
//               {Constants.RUPEE_IN_WORD}
//             </label>
//             <div
//               className={`${jyotFormStyle.colon}`}
//               style={{ paddingLeft: "2px" }}
//             >
//               :{" "}
//             </div>
//             <div className={jyotFormStyle.inputWithError}>
//               ( {amountInHindi})
//             </div>
//           </div>

//           <div style={{ display: "flex", justifyContent: "center" }}>
//             <div style={{ color: "black", fontWeight: "bold" }}>
//               कृपया भुगतान के बाद पुष्टि करें :-
//             </div>
//           </div>
//           <div
//             className={jyotFormStyle.buttonContainer}
//             style={{
//               paddingTop: "2%",
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             <button className={jyotFormStyle.button} type="submit">
//               {Constants.SUBMIT_TEXT}
//             </button>
//           </div>

//           <div
//             className={jyotFormStyle.label}
//             style={{
//               width: "100%",
//               fontSize: "12px",
//               fontWeight: "500",
//               color: "red",
//               paddingLeft: "1%",
//               display: "flex",
//               paddingTop: "1%",
//             }}
//           >

//             <span style={{ color: "red", fontSize: "18px" }}>• </span>
//             <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>

//               तेल ज्योति कलश को प्रज्वलित करने के लिए तेल का उपयोग किया जाता
//               हैं, जिसका मूल्य 1001/- होगा।
//             </span>
//           </div>

//           <div
//             className={jyotFormStyle.label}
//             style={{
//               width: "100%",
//               fontSize: "12px",
//               fontWeight: "500",
//               color: "red",
//               paddingLeft: "1%",

//               display: "flex",
//             }}
//           >

//             <span style={{ color: "red", fontSize: "18px" }}>• </span>
//             <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>

//               घी ज्योति कलश को प्रज्वलित करने के लिए देशी घी का उपयोग किया जाता
//               हैं,जिसका मूल्य 2001/- होगा।
//             </span>
//           </div>

//           <div
//             className={jyotFormStyle.label}
//             style={{
//               width: "100%",
//               fontSize: "12px",
//               fontWeight: "500",
//               color: "red",
//               paddingLeft: "1%",

//               display: "flex",
//             }}
//           >

//             <span style={{ color: "red", fontSize: "18px" }}>• </span>
//             <span style={{ paddingLeft: "1%", paddingTop: "4px" }}>

//               ऊपर उल्लेखित ज्योति कलशों में से किसी को भी आपके नाम पर आजीवन जलाए
//               रखने का विकल्प चुना जा सकता हैं।
//             </span>
//           </div>
//         </Form>
//       </Formik>
//     </div>
//   );
// };

// export default JyotForm;

// End of modification and Commented by Om Shrivastava on 17-02-24
// Reason : Need to use formik in this jyot form
