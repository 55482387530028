/**
 * Created by - Ashlekh on 10-01-2024\
 * Reason - To have header section
 */

import React, { useContext, useEffect, useState } from "react";
import style from "./header.module.css";
import config from "../../Api/config";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { getAllDetailsApi } from "../../Api/services";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import { Drawer } from "antd";
import Constants from "../../constants/Constants";
import { GlobalContext } from "../../context/Context";
// import 'antd/dist/antd.css';

const Header = () => {
  // Addition by Om Shrivastava on 30-03-24
  // Reason : Set logo from context file 
  // const [logo, setLogo] = useState("");
  var {logo} = useContext(GlobalContext);
  // End of addition by Om Shrivastava on 30-03-24
  // Reason : Set logo from context file 

  const [isOpen, setIsOpen] = React.useState(false);
  const [visible, setVisible] = useState(false);
  // Added by - Ashlekh on 25-05-2024
  // Reason - To have useState for header options 
  
  
  const [activeLink, setActiveLink] = useState("");
  // End of code - Ashlekh on 25-05-2024
  // Reason - To have useState for headre options
  const closeDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  // Added by - Ashlekh on 25-05-2024
  // Reason - When options from header are clicked then to update activeLink
  const handleClick = (link) => {
    setActiveLink(link);
  };
  
  // End of code - Ashlekh on 25-05-2024
  // Reason - When options from header are clicked then to update activeLink

  useEffect(() => {
    window.scrollTo(0, 0);
    // Commented by Om Shrivastava on 30-03-24
    // Reason : No need to call API 
    // getHeaderLogo();
    // End of commented by Om Shrivastava on 30-03-24
    // Reason : No need to call API 
  }, []);

  // Commented by Om Shrivastava on 30-03-24
  // Reason : No need to call API 
  // const getHeaderLogo = async () => {
  //   const response = await getAllDetailsApi();
  //   if (response?.website_contents != null) {
  //     setLogo(response?.website_contents?.header_logo);
  //   }
  // };
  // End of commented by Om Shrivastava on 30-03-24
  // Reason : No need to call API 

  function drawerMenu() {
    setIsOpen(true);
  }

  return (
    <div>
      <div className={`${style.mainHeaderContainer} `}>
        {/* Added by - Ashlekh on 27-01-2024
        Reason - To keep logo in and home jyot gallery about contact in one div */}
        <div className={`${style.subContainer1}`}>
          <div className={`${style.logoContainer} `}>
            <div>
              {logo?.length > 0 ? (
                <Link to="">
                  <img
                    src={config.baseURL + logo}
                    alt=""
                    className={`${style.logo}`}
                  />
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={`${style.navigationSubContainer1}`}>
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/" className={`${style.navigation}`}>
              {Constants.homePageTitle}
            </Link> */}
            <Link to="/" className={`${style.navigation} ${activeLink === '/' ? style.active : ''}`} onClick={() => handleClick("/")}>
              {Constants.homePageTitle}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/gallery" className={`${style.navigation} `}>
              {Constants.galleryTitleText}
            </Link> */}
            <Link to="/gallery" className={`${style.navigation} ${activeLink === '/gallery' ? style.active : ''} `} onClick={() => handleClick("/gallery")}>
              {Constants.galleryTitleText}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/about_us" className={`${style.navigation} `}>
            {Constants.aboutUsTitleText}
            </Link> */}
            <Link to="/about_us" className={`${style.navigation} ${activeLink === '/about_us' ? style.active : ''}`} onClick={() => handleClick("/about_us")}>
            {Constants.aboutUsTitleText}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/contact_us" className={`${style.navigation} `}>
              {Constants.contactUsTitleText}
            </Link> */}
            <Link to="/contact_us" className={`${style.navigation} ${activeLink === '/contact_us' ? style.active : ''}`} onClick={() => handleClick("/contact_us")}>
              {Constants.contactUsTitleText}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* Addition by Om Shrivastava on 14-02-24
            Reason : Need to add the donation  */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/donation" className={`${style.navigation} `}>
              {Constants.DONATION_TEXT}
            </Link> */}
            <Link to="/donation" className={`${style.navigation} ${activeLink === '/donation' ? style.active : ''}`} onClick={() => handleClick("/donation")}>
              {Constants.DONATION_TEXT}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* End of addition by Om Shrivastava on 14-02-24
            Reason : Need to add the donation  */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/jyot" className={`${style.navigation} `}>
              {Constants.JYOT_TITLE_TEXT}
            </Link> */}
            <Link to="/jyot" className={`${style.navigation} ${activeLink === '/jyot' ? style.active : ''}`} onClick={() => handleClick("/jyot")}>
              {Constants.JYOT_TITLE_TEXT}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            
           
            
          </div>
        </div>
        {/* End of code addition by - Ashlekh on 27-01-2024
        Reason - To keep logo in and home jyot gallery about contact in one div */}

        {/* Added by - Ashlekh on 27-01-2024
        Reason - To keep volunteer receipt in one div */}
        <div className={`${style.subContainer2}`} id="navigationLink">
          <div className={`${style.navigationSubContainer2}`}>
            {/* Code changed by - Ashlekh on 12-02-2024
            Reason - To remove volunteer and add temple trustee */}
            {/* <Link to="/" className={`${style.navigation} `}>
              वॉलंटियर
            </Link> */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/temple_trustee" className={`${style.navigation} `}>
              {Constants.templeTrusteeTitle}
            </Link> */}
            <Link to="/temple_trustee" className={`${style.navigation} ${activeLink === '/temple_trustee' ? style.active : ''}`} onClick={() => handleClick("/temple_trustee")}>
              {Constants.templeTrusteeTitle}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
             {/* Added by - Ashlekh on 29-03-2024
            Reason - To add donation receipt history */}
            {/* Code changed by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/donation_receipt_history" className={`${style.navigation} `}>
              {Constants.donationReceiptTitleText}
            </Link> */}
            <Link to="/donation_receipt_history" className={`${style.navigation} ${activeLink === '/donation_receipt_history' ? style.active : ''}`} onClick={() => handleClick("/donation_receipt_history")}>
              {Constants.donationReceiptTitleText}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* End of code - Ashlekh on 29-03-2024
            Reason - To add donation receipt history */}
            {/* End of code - Ashlekh on 12-02-2024
            Reason - To remove volunteer and add temple trustee */}
            {/* Added by - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
            {/* <Link to="/jyot_receipt_history" className={`${style.navigation} `}>
              {Constants.receiptTitleText}
            </Link> */}
            <Link to="/jyot_receipt_history" className={`${style.navigation} ${activeLink === '/jyot_receipt_history' ? style.active : ''}`} onClick={() => handleClick("/jyot_receipt_history")}>
              {Constants.receiptTitleText}
            </Link>
            {/* End of code - Ashlekh on 25-05-2024
            Reason - When user clicks on any option of header then it must be highlighted */}
           
          </div>
        </div>
        {/* End of code addition by - Ashlekh on 27-01-2024
        Reason - To keep volunteer receipt in one div */}
        {/* Code commented by - Ashlekh on 27-01-2024
        Reason - To fix header design */}
        {/* <div className={`${style.navigationContainer}`} id="navigationLink"> */}
        {/* Code added by - Ashlekh on 24-01-2024
          Reason - To have home jyot gallery about contact in one div */}
        {/* <div className={`${style.navigationSubContainer1}`}>
            <Link to="/" className={`${style.navigation} `}>
              होम
            </Link>
            <Link to="" className={`${style.navigation} `}>
              ज्योत
            </Link> */}
        {/* Commented by - Ashlekh on 24-01-2024
          Reason - To remove aarti section */}
        {/* <Link to="" className={`${style.navigation} `}>
            आरती
          </Link> */}
        {/* End of comment by - Ashlekh on 24-01-2024
          Reason - To remove aarti section */}
        {/* <Link to="/gallery" className={`${style.navigation} `}>
              गैलरी
            </Link>
            <Link to="/about_us" className={`${style.navigation} `}>
              परिचय
            </Link>
            <Link to="/contact_us" className={`${style.navigation} `}>
              संपर्क करें
            </Link>
          </div> */}
        {/* End of code addition by - Ashlekh on 24-01-2024
          Reason - To have home jyot gallery about contact in one div */}
        {/* Added by - Ashlekh on 24-01-2024
          Reason - To have volunteer receipt in one div */}
        {/* <div className={`${style.navigationSubContainer2}`}>
            <Link to="/volunteer" className={`${style.navigation} `}>
              वॉलंटियर
            </Link>
            <Link to="/receipt" className={`${style.navigation} `}>
              रिसिप्ट
            </Link>
          </div> */}
        {/* End of code addition by - Ashlekh on 24-01-2024
          Reason - To have volunteer receipt in one div */}
        {/* </div> */}
        {/* End of comment by - Ashlekh on 27-01-2024
        Reason - To fix header design */}
        <div className={`${style.iconContainer} `}>
          {/* <GiHamburgerMenu onClick={drawerMenu} className={`${style.button}`} /> */}
          <GiHamburgerMenu
            onClick={closeDrawer}
            className={`${style.button}`}
          />
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        placement="right"
        className={`${style.hamburgerDrawer}`}
        // style={{ background: "linear-gradient(to right, #FFDE59, #FF914D)", }}
      >
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
          className={`${style.drawerMenu}}`}
        >
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              <img style={{ width: "5vw" }} src="house.png" alt="" />
            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <Link onClick={closeDrawer} to="/" className={style.link}>
              {/* होम */}
              {Constants.homePageTitle}
            </Link>
          </li>
          <hr className={`${style.horizontalLine}`} />
          
          {/* Commented by - Ashlekh on 24-01-2024
          Reason - to remove aarti section */}
          {/* <li className={style.li}>
            <Link onClick={closeDrawer} to="" className={style.link}>
              आरती
            </Link>
          </li> */}
          {/* End of comment by - Ashlekh on 24-01-2024
          Reason - to remove aarti section */}
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              <img style={{ width: "5vw" }} src="gallery.png" alt="" />
            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <Link onClick={closeDrawer} to="/gallery" className={style.link}>
              {/* गैलरी */}
              {Constants.galleryTitleText}
            </Link>
          </li>
          <hr className={`${style.horizontalLine}`} />
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              <img style={{ width: "5vw" }} src="about.png" alt="" />
            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <Link onClick={closeDrawer} to="/about_us" className={style.link}>
              {Constants.aboutUsTitleText}
            </Link>
          </li>
          <hr className={`${style.horizontalLine}`} />
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              <img style={{ width: "5vw" }} src="contact.png" alt="" />
            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <Link onClick={closeDrawer} to="/contact_us" className={style.link} >
              {/* संपर्क करें */}
              {Constants.contactUsTitleText}
            </Link>
          </li>
          <hr className={`${style.horizontalLine}`} />
          {/* Addition by Om Shrivastava on 14-02-24
            Reason : Need to add the donation  */}
          <li className={style.li}>
            <div>
              {/* <img style={{ width: "6.5vw" }} src="donation.png" alt="" /> */}
              <img style={{ width: "6.5vw" }} src="donation_drawer_img.png" alt="" />

            </div>
            <Link 
            // Addition by Om Shrivastava on 17-02-24
            // Reason : Need to close the drawer when user navigate the donation form
            onClick={closeDrawer}
             // End of addition by Om Shrivastava on 17-02-24
            // Reason : Need to close the drawer when user navigate the donation form
            to="/donation" className={`${style.link} `} style={{ paddingLeft:'0.1%' }}>
              {Constants.DONATION_TEXT}
            </Link>
          </li>
          {/* End of addition by Om Shrivastava on 14-02-24
            Reason : Need to add the donation  */}

          <hr className={`${style.horizontalLine}`} />
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              <img style={{ width: "5vw" }} src="jyot.gif" alt="" />
            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <Link onClick={closeDrawer} to="/jyot" className={style.link}>
              {/* ज्योत */}
              {Constants.JYOT_TITLE_TEXT}
            </Link>
          </li>
          <hr className={`${style.horizontalLine}`} />
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              <img style={{ width: "5vw" }} src="volunteer.png" alt="" />
            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            {/* Commented by - Ashlekh on 12-02-2024
            Reason - To remove volunteer and add temple trustee */}
            {/* <Link onClick={closeDrawer} to="/volunteer" className={style.link}>
              वॉलंटियर
            </Link> */}
            <Link
              onClick={closeDrawer}
              to="/temple_trustee"
              className={style.link}
            >
              {/* ट्रस्टी */}
              {Constants.templeTrusteeTitle}
            </Link>
            {/* End of comment - Ashlekh on 12-02-2024
            Reason - To remove volunteer and temple trustee */}
          </li>
          
          {/* Added by - Ashlekh on 29-03-2024
          Reason - To have donation receipt history */}
          <hr className={`${style.horizontalLine}`} />
          <li className={style.li}>
            <div>
              <img style={{ width: "5vw" }} src="donation_drawer.png" alt="" />
            </div>
            <Link
              onClick={closeDrawer}
              to="/donation_receipt_history"
              className={style.link}
            >
              {Constants.donationReceiptTitleText}
            </Link>
          </li>
          <hr className={`${style.horizontalLine}`} />
          <li className={style.li}>
            {/* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <div>
              {/* Modification and addition by Om Shrivastava on 11-04-24
              Reason : Need to change the drawer image  */}
              {/* <img style={{ width: "5vw" }} src="receipt.png" alt="" /> */}
              <img style={{ width: "5vw" }} src="jyot_receipt.png" alt="" />
               {/* End of modification and addition by Om Shrivastava on 11-04-24
              Reason : Need to change the drawer image  */}

            </div>
            {/* End of /* Addition by Om Shrivastav on 09-02-24
            Reason : Need to set the image  */}
            <Link
              onClick={closeDrawer}
              to="/jyot_receipt_history"
              className={style.link}
            >
              {Constants.receiptTitleText}
            </Link>
          </li>
          {/* End of code - Ashlekh on 29-03-2024
          Reason - To have donation receipt history */}
        </ul>
      </Drawer>
    </div>
  );
};

export default Header;
