/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have contact us details component
 */

import contactUsDetailsStyle from "./ContactUsDetails.module.css";
import Constants from "../../constants/Constants";
import { MdEmail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";

const ContactUsDetails = (props) => {
  return (
    <div className={`${contactUsDetailsStyle.contactUsDetailsContainer}`}>
      {/* <div
        className={`${contactUsDetailsStyle.paragraph} ${contactUsDetailsStyle.contentTitle} `}
      >
        {"हमसे संपर्क करे"}
      </div> */}

      {props?.contactUsDetails?.address != null ||
      props?.contactUsDetails?.contact_number1 != null ||
      props?.contactUsDetails?.contact_number1 != null ||
      props?.contactUsDetails?.email != null ? (
        <div>
          <div className={`${contactUsDetailsStyle.paragraph}`}>
            {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
            {/* {"आप निम्न पते पर भी हमसे संपर्क कर सकते हैं : "} */}
            {Constants.YOU_CAN_CONTACT_US_TEXT}
            {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
          </div>
          {props?.contactUsDetails?.address != null &&
            props?.contactUsDetails?.address?.length > 0 && (
              /**
               * Modified by - Ashish Dewangan on 22-01-2024
               * Reason - To justify text
               */
              // <div className={`${contactUsDetailsStyle.paragraph}`} >
              <div
                className={`${contactUsDetailsStyle.paragraph}`}
                style={{ textAlign: "justify", hyphens: "auto" }}
              >
                {/* Modified by - Ashish Dewangan on 22-01-2024
                Reason - To justify text */}

                {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {/* {"पता : "} */}
                {Constants.ADDRESS_TEXT + " : "}
                {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {props?.contactUsDetails?.address}
              </div>
            )}
          {props?.contactUsDetails?.contact_number1 != null &&
            props?.contactUsDetails?.contact_number1?.length > 0 && (
              <div className={`${contactUsDetailsStyle.paragraph}`}>
                <BsFillTelephoneFill />
                {/* Modified by - Ashish Dewangan on 29-01-2024
                Reason - To change label  */}
                {/* {" दूरभाष क्रमांक 1 : "} */}
                {/* Modified by - Ashish Dewangan on 29-01-2024
                Reason - To change label  */}

                {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {/* {" मोबाइल नंबर 1 : "} */}
                {Constants.MOBILE_NUMBER_1_TEXT + " : "}
                {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {"+91-"}
                {props?.contactUsDetails?.contact_number1}
              </div>
            )}
          {props?.contactUsDetails?.contact_number2 != null &&
            props?.contactUsDetails?.contact_number2?.length > 0 && (
              <div
                className={`${contactUsDetailsStyle.paragraph}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <BsFillTelephoneFill style={{ marginRight: "5px" }} />
                {/* Modified by - Ashish Dewangan on 29-01-2024
                Reason - To change label  */}
                {/* {" दूरभाष क्रमांक 2 : "} */}
                {/* Modified by - Ashish Dewangan on 29-01-2024
                Reason - To change label  */}

                {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {/* {" मोबाइल नंबर 2 : "} */}
                {Constants.MOBILE_NUMBER_2_TEXT + " : "}
                {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {"+91-"}
                {props?.contactUsDetails?.contact_number2}
              </div>
            )}
          {props?.contactUsDetails?.email != null &&
            props?.contactUsDetails?.email?.length > 0 && (
              <div
                className={`${contactUsDetailsStyle.paragraph}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <MdEmail style={{ marginRight: "5px" }} />

                {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {/* {" ई-मेल एड्रेस : "} */}
                {Constants.EMAIL_ADDRESS_TEXT + " : "}
                {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
                {props?.contactUsDetails?.email}
              </div>
            )}
        </div>
      ) : (
        /**
         * Modified by - Ashish Dewangan on 22-01-2024
         * Reason - To hide this component on responsive
         */
        // <div
        //   className={`${contactUsDetailsStyle.noContentFoundText}
        // >
        <div
          className={`${contactUsDetailsStyle.noContentFoundText} ${contactUsDetailsStyle.hideOnResponsive}`}
        >
          {/* End of code modification by - Ashish Dewangan on 22-01-2024
          Reason - To hide this component on responsive */}

          {Constants.dataNotFoundText}
        </div>
      )}
    </div>
  );
};

export default ContactUsDetails;
