/**
 * Created by - Ashish Dewangan on 18-01-2024
 * Reason - To have contact us form
 */
import contactUsFormStyle from "./ContactUsForm.module.css";
import { postContactUsRequestApi } from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { useFormik } from "formik";
import * as yup from "yup";
import Constants from "../../constants/Constants";

const ContactUsForm = (props) => {
  /**
   * Added by - Ashish Dewangan on 19-01-2024
   * Reason - To handle form's initial values, validations and submit events
   */
  const formik = useFormik({
    initialValues: {
      name: "",
      contact_number: "",
      query: "",
    },

    onSubmit: (values) => {
      submitContactUsRequest(values);
    },
    validationSchema: yup.object({
      name: yup.string().required("कृपया नाम दर्ज करें | "),
      contact_number: yup
        .number()
        .transform((value, originalValue) =>
          /**
           * Added by - Ashish Dewangan on 22-01-2024
           * Reason - To validate if space is added in input
           */
          /\s/.test(originalValue) ? NaN : value
        )
        /**
         * End of code addition by - Ashish Dewangan on 22-01-2024
         * Reason - To validate if space is added in input
         */
        .integer()
        .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें | ")
        .required("कृपया मोबाइल नंबर दर्ज करें | ")
        .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें | ")
        .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें | "),
      query: yup.string().required("कृपया सवाल लिखें | "),
    }),
  });
  /**
   * End of code addition by - Ashish Dewangan on 19-01-2024
   * Reason - To handle form's initial values, validations and submit events
   */

  /**
   * Added by - Ashish Dewangan on 19-01-2024
   * Reason - Added method to submit contact query to backend
   */
  async function submitContactUsRequest(values) {
    const requestData = {
      name: values.name,
      contact_number: values.contact_number,
      query: values.query,
    };

    const response = await postContactUsRequestApi(requestData);

    if (response.message) {
      formik.resetForm();
      document.getElementById("form").reset();
      notificationObject.success(response.message);
    } else if (response.error) {
      notificationObject.error(response.error);
    }
  }
  /**
   * End of code addition by - Ashish Dewangan on 19-01-2024
   * Reason - Added method to submit contact query to backend
   */

  return (
    <div className={`${contactUsFormStyle.formContainer}`}>
      <form
        className={contactUsFormStyle.form}
        id="form"
        onSubmit={formik.handleSubmit}
        /**
         * Added by - Ashish Dewangan on 22-01-2024
         * Reason - To remove html validation
         */
        noValidate={true}
        /**
         * End of code addition by - Ashish Dewangan on 22-01-2024
         * Reason - To remove html validation
         */
      >
        <div className={contactUsFormStyle.inputHolder}>
          <label className={contactUsFormStyle.label}>
            <span style={{ color: "red" }}>*</span>

            {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
            {/* {" नाम :"} */}
            {" " + Constants.NAME_TEXT + " : "}
            {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
          </label>
          <div className={contactUsFormStyle.inputWithError}>
            <input
              type="text"
              name="name"
              {...formik.getFieldProps("name")}
              maxLength={50}
              className={contactUsFormStyle.inputBox}
            />
            {formik.touched.name && formik.errors.name ? (
              <span 
              // Modification and addition by Om Shrivastav on 08-02-24
              // Reason : Need to set the error font size globally
              // style={{ color: "red" }}
              className={`${contactUsFormStyle.validationMsgContainer}`}
              // Modification and addition by Om Shrivastav on 08-02-24
              // Reason : Need to set the error font size globally
              
              >{formik.errors.name}</span>
            ) : null}
          </div>
        </div>

        <div className={contactUsFormStyle.inputHolder}>
          <label className={contactUsFormStyle.label}>
            <span style={{ color: "red" }}>*</span>
            {/* Modified by - Ashish Dewangan on 22-01-2024
            Reason - To change spelling of नम्बर */}
            {/* {" नम्बर :"} */}

            {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
            {/* {" नंबर :"} */}
            {" " + Constants.CONTACT_TEXT + " : "}
            {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
            {/* End of modification by - Ashish Dewangan on 22-01-2024
            Reason - To change spelling of नम्बर */}
          </label>
          <div className={contactUsFormStyle.inputWithError}>
            <input
              name="contact_number"
              id="contact_number"
              pattern="[1-9]{1}[0-9]{9}"
              {...formik.getFieldProps("contact_number")}
              className={contactUsFormStyle.inputBox}
            />
            {formik.touched.contact_number && formik.errors.contact_number ? (
              <span 
              // Modification and addition by Om Shrivastav on 08-02-24
              // Reason : Need to set the error font size globally
              // style={{ color: "red" }}
              className={`${contactUsFormStyle.validationMsgContainer}`}
              // Modification and addition by Om Shrivastav on 08-02-24
              // Reason : Need to set the error font size globally
              >
                {formik.errors.contact_number}
              </span>
            ) : null}
          </div>
        </div>

        <div className={contactUsFormStyle.inputHolder}>
          <label className={contactUsFormStyle.label}>
            <span style={{ color: "red" }}>*</span>

            {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
            {/* {" सवाल पूछे :"} */}
            {" " + Constants.ASK_QUESTION_TEXT + " : "}
            {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
          </label>
          <div className={contactUsFormStyle.inputWithError}>
            <textarea
              type="text"
              name="query"
              {...formik.getFieldProps("query")}
              className={`${contactUsFormStyle.inputBox} ${contactUsFormStyle.textArea}`}
            />
            {formik.touched.query && formik.errors.query ? (
              <span 
              // Modification and addition by Om Shrivastav on 08-02-24
              // Reason : Need to set the error font size globally
              // style={{ color: "red" }}
              className={`${contactUsFormStyle.validationMsgContainer}`}
              // Modification and addition by Om Shrivastav on 08-02-24
              // Reason : Need to set the error font size globally
              
              >{formik.errors.query}</span>
            ) : null}
          </div>
        </div>

        <div className={contactUsFormStyle.buttonContainer}>
          <button className={contactUsFormStyle.button} type="submit">
            {/* Modified by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
            {/* {"दर्ज करें"} */}
            {Constants.SUBMIT_TEXT}
            {/* End of modification by - Ashish Dewangan on 03-02-2024
                Reason - To get text label from constants file */}
          </button>
        </div>
      </form>
      {/* Commented by - Ashish Dewangan on 31-01-2024
      Reason - Moved map from from section */}
      {/* <div className={contactUsFormStyle.mapContainer}>
        {" "}
        {props?.contactUsDetails?.google_map_link?.length > 0 && (
          <iframe
            title="Map"
            src={props?.contactUsDetails?.google_map_link}
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            style={{ border: "0", outline: "none" }}
          ></iframe>

          //           <object data={props?.contactUsDetails?.google_map_link}
          // width="100%"
          // height="100%"
          // >
          //     Alternative Content
          // </object>

          //  <object data={props?.contactUsDetails?.google_map_link} width="100%" height="100%">
          //     <embed src={props?.contactUsDetails?.google_map_link} width="100%" height="100%"/>
          //     Error: Embedded data could not be displayed.
          // </object>
        )}
      </div> */}
      {/* End of comment by - Ashish Dewangan on 31-01-2024
      Reason - Moved map from from section */}
    </div>
  );
};

export default ContactUsForm;
