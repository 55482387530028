// Modification and Commented by Om Shrivastava on 17-02-24
// Reason : Need to use formik in this donation form

// import donationFormStyle from "./DonationForm.module.css";
// import { postDonationRequestApi } from "../../Api/services";
// import notificationObject from "../../components/Widgets/Notification/Notification";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import Constants from "../../constants/Constants";
// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import parse from "html-react-parser";

// const JyotForm = (props) => {
//   const nav = useNavigate();

//   const [genderDetails, setGenderDetails] = useState([
//     { id: 0, name: Constants.MALE_TEXT },
//     { id: 1, name: Constants.FEMALE_TEXT },
//     { id: 2, name: Constants.OTHER_TEXT },
//   ]);
//   const [genderSelect, setGenderSelect] = useState(genderDetails[0]?.name);

//   const [selectedDonationType, setSelectedDonationType] = useState(
//     props.donationTypeData[0]?.id
//   );

//   const handleJyotOptionChange = (event) => {
//     setSelectedDonationType(event.target.value);
//   };

//   const handleGenderValueChange = (event) => {
//     setGenderSelect(event.target.value);
//   };

//   useEffect(() => {
//     setSelectedDonationType(props.donationTypeData[0]?.id);
//   }, [props.donationTypeData[0]?.id]);

//   useEffect(() => {
//     setGenderSelect(genderDetails[0]?.name);
//   }, [genderDetails[0]?.name]);

//   const formik = useFormik({
//     initialValues: {
//       name: document.getElementById("usrName")?.value,
//       donation_type: selectedDonationType,
//       gender: genderSelect,
//       address: document.getElementById("usrAddress")?.value,
//       comment: document.getElementById("userComment")?.value,
//       contact_number: document.getElementById("usrContact")?.value,
//       amount: document.getElementById("userAmount")?.value,
//       email: document.getElementById("userEmail")?.value,
//     },
//     enableReinitialize: true,

//     onSubmit: (values) => {
//       submitJyotRequest(values);

//     },
//     validationSchema: yup.object({
//       name: yup
//         .string()
//         .matches(
//           /^[a-zA-Z0-9\u0900-\u097F\s]+$/,
//           "कृपया सही प्रारूप में नाम दर्ज करे"
//         )
//         .required("कृपया नाम दर्ज करें"),

//       contact_number: yup
//         .number()
//         .transform((value, originalValue) =>
//           /\s/.test(originalValue) ? NaN : value
//         )
//         .integer()
//         .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें")
//         .required("कृपया मोबाइल नंबर दर्ज करें")
//         .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें")
//         .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें"),

//       amount: yup.string().required("कृपया राशि दर्ज करें"),

//       email: yup
//         .string()
//         .trim()
//         .email("कृपया सही फार्मेट में ईमेल दर्ज करें")
//     }),
//   });

//   async function submitJyotRequest(values) {
//     const requestData = {
//       name: values.name,
//       donation_type: values.donation_type,
//       gender: values.gender,
//       email: values.email,
//       address: values.address,
//       contact_number: values.contact_number,
//       amount: values.amount,
//       comment: values.comment,
//     };

//     const response = await postDonationRequestApi(requestData);
//     if (response.message) {
//       formik.resetForm();
//       document.getElementById("form").reset();
//       notificationObject.dismissAll();
//       notificationObject.success(response.message);
//       nav("/donation_receipt");
//     } else if (response.error) {
//       notificationObject.dismissAll();
//       notificationObject.error(response.error);
//     }
//   }
//   return (
//     <div className={`${donationFormStyle.formContainer}`}>
//       <form
//         className={donationFormStyle.form}
//         id="form"
//         onSubmit={formik.handleSubmit}
//         noValidate={true}
//       >
//         <div className={donationFormStyle.inputHolder}>
//           <label className={donationFormStyle.label}>
//             {Constants.NAME_TEXT}
//             <span style={{ color: "red" }}> * </span>
//           </label>
//           <div className={`${donationFormStyle.colon}`}> : </div>
//           <div className={donationFormStyle.inputWithError}>
//             <input
//               type="text"
//               name="name"
//               id="usrName"
//               {...formik.getFieldProps("name")}
//               maxLength={50}
//               className={donationFormStyle.inputBox}
//             />
//             {formik.touched.name && formik.errors.name ? (
//               <span className={`${donationFormStyle.validationMsgContainer}`}>
//                 {formik.errors.name}
//               </span>
//             ) : null}
//           </div>
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label
//             className={donationFormStyle.label}
//             style={{ paddingLeft: "1px" }}
//           >
//             {Constants.DONATION_TYPE_TEXT}
//           </label>
//           <div className={`${donationFormStyle.colon}`}> : </div>
//           <div
//             className={donationFormStyle.label}
//             style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
//           >
//             {props.donationTypeData.map((option) => (
//               <div
//                 key={option.id}
//                 style={{
//                   paddingLeft: "2%",
//                   display: "flex",
//                   alignItems: "center",
//                   fontSize: "13px",
//                 }}
//               >
//                 <input
//                   type="radio"
//                   name="donation_type"
//                   {...formik.getFieldProps("donation_type")}
//                   id="donation_type"
//                   value={option.id}
//                   checked={parseInt(selectedDonationType) === option.id}
//                   onChange={handleJyotOptionChange}
//                 />
//                 &nbsp;{" "}
//                 <label style={{ marginTop: "1%" }} htmlFor="html">
//                   {option.donation_type.charAt(0).toUpperCase() +
//                     option.donation_type.slice(1)}
//                 </label>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label
//             className={donationFormStyle.label}
//             style={{ paddingLeft: "1px" }}
//           >
//             {Constants.GENDER_TEXT}
//           </label>

//           <div className={`${donationFormStyle.colon}`}> : </div>

//           {genderDetails.map((option) => (
//             <div
//               key={option.id}
//               style={{
//                 paddingLeft: "1%",
//                 display: "flex",
//                 alignItems: "center",
//                 marginTop: "1%",
//                 fontSize: "13px",
//               }}
//             >
//               <input
//                 type="radio"
//                 name="gender"
//                 {...formik.getFieldProps("gender")}
//                 id="gender"
//                 value={option.name}
//                 checked={genderSelect === option.name}
//                 onChange={handleGenderValueChange}
//               />
//               &nbsp;
//               <label htmlFor="html" style={{ fontWeight: "bold" }}>
//                 {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
//               </label>
//             </div>
//           ))}
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label
//             className={donationFormStyle.label}
//             style={{ paddingLeft: "1px" }}
//           >
//             {Constants.ADDRESS_TEXT}
//           </label>

//           <div className={`${donationFormStyle.colon}`}> : </div>

//           <div className={donationFormStyle.inputWithError}>
//             <textarea
//               type="text"
//               name="address"
//               id="usrAddress"
//               {...formik.getFieldProps("address")}
//               className={`${donationFormStyle.inputBox} ${donationFormStyle.textArea}`}
//             />
//           </div>
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label className={donationFormStyle.label}>
//             {Constants.CONTACT_TEXT}
//             <span style={{ color: "red" }}> * </span>
//           </label>

//           <div className={`${donationFormStyle.colon}`}> : </div>

//           <div className={donationFormStyle.inputWithError}>
//             <input
//               name="contact_number"
//               id="usrContact"
//               pattern="[1-9]{1}[0-9]{9}"
//               {...formik.getFieldProps("contact_number")}
//               className={donationFormStyle.inputBox}
//             />
//             {formik.touched.contact_number && formik.errors.contact_number ? (
//               <span className={`${donationFormStyle.validationMsgContainer}`}>
//                 {formik.errors.contact_number}
//               </span>
//             ) : null}
//           </div>
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label
//             className={donationFormStyle.label}
//             style={{ paddingLeft: "1px" }}
//           >
//             {Constants.EMAIL_ADDRESS_TEXT}
//           </label>
//           <div className={`${donationFormStyle.colon}`}> : </div>
//           <div className={donationFormStyle.inputWithError}>
//             <input
//               type="text"
//               name="email"
//               id="userEmail"
//               {...formik.getFieldProps("email")}
//               maxLength={50}
//               className={donationFormStyle.inputBox}
//             />

//             {formik.touched.email && formik.errors.email ? (
//               <span className={`${donationFormStyle.validationMsgContainer}`}>
//                 {formik.errors.email}
//               </span>
//             ) : null}

//           </div>
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label
//             className={donationFormStyle.label}
//             style={{ paddingLeft: "1px" }}
//           >
//             {Constants.COMMENT_TEXT}
//           </label>

//           <div className={`${donationFormStyle.colon}`}> : </div>

//           <div className={donationFormStyle.inputWithError}>
//             <textarea
//               type="text"
//               name="comment"
//               id="userComment"
//               {...formik.getFieldProps("comment")}
//               className={`${donationFormStyle.inputBox} ${donationFormStyle.textArea}`}
//             />
//           </div>
//         </div>

//         <div className={donationFormStyle.inputHolder}>
//           <label className={donationFormStyle.label}>
//             {Constants.RUPEE_TEXT}
//             <span style={{ color: "red" }}> * </span>
//           </label>
//           <div className={`${donationFormStyle.colon}`}> : </div>
//           <div className={donationFormStyle.inputWithError}>
//             <input
//               type="number"
//               name="amount"
//               id="userAmount"
//               {...formik.getFieldProps("amount")}
//               maxLength={50}
//               className={donationFormStyle.inputBox}
//             />
//             {formik.touched.amount && formik.errors.amount ? (
//               <span className={`${donationFormStyle.validationMsgContainer}`}>
//                 {formik.errors.amount}
//               </span>
//             ) : null}
//           </div>
//         </div>

//         <div style={{ display: "flex", justifyContent: "center" }}>
//           <div style={{ color: "black", fontWeight: "bold" }}>
//             कृपया भुगतान के बाद पुष्टि करें :-
//           </div>
//         </div>
//         <div
//           className={donationFormStyle.buttonContainer}
//           style={{
//             paddingTop: "2%",
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <button className={donationFormStyle.button} type="submit">
//             {Constants.SUBMIT_TEXT}
//           </button>
//         </div>

//         <div

//           className={donationFormStyle.descriptionContainer}

//         >
//           {parse(
//             props.donationDescription != null &&
//               props.donationDescription.length > 0
//               ? props.donationDescription
//               : ""
//           )}
//         </div>
//       </form>
//     </div>
//   );
// };

// export default JyotForm;

/**
 * Created by - Om Shrivastava on 17-02-24
 * Reason - To have donation form
 */
import donationFormStyle from "./DonationForm.module.css";
import {
  checkTransactionApi,
  getDonationReceiptApi,
  postDonationRequestApi,
  postRazorPayRequriedApi,
} from "../../Api/services";
import notificationObject from "../../components/Widgets/Notification/Notification";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Constants from "../../constants/Constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { ToWords } from "to-words";
import useRazorpay from "react-razorpay";
import axios from "axios";
import config from "../../Api/config";

const DonationForm = (props) => {
  const nav = useNavigate();

  const [Razorpay] = useRazorpay();

  const [amountInHindi, setAmountInHindi] = useState("");

  const productOptions = props.donationTypeData.map((product, key) => (
    <option value={product.id} key={key.id}>
      {product.donation_type}
    </option>
  ));

  const [genderDetails, setGenderDetails] = useState([
    { id: 0, name: Constants.MALE_TEXT },
    { id: 1, name: Constants.FEMALE_TEXT },
    { id: 2, name: Constants.OTHER_TEXT },
  ]);

  const initialValues = {
    _type: "productReview",
    name: "",
    donation_type: "",
    gender: "",
    address: "",
    email: "",
    contact_number: "",
    comment: "",

    // amount:"",
    amount: document.getElementById("donationFormAmount")?.value,
    currency: "INR",
  };
  // console.log(amountInHindi);

  const validationSchema = Yup.object({
    donation_type: Yup.string().required("कृपया दान के प्रकार चुने | "),
    contact_number: Yup.number()
      .transform((value, originalValue) =>
        /\s/.test(originalValue) ? NaN : value
      )
      .integer()
      .typeError("कृपया 10 अंको का मोबाइल नंबर दर्ज करें | ")
      .required("कृपया मोबाइल नंबर दर्ज करें | ")
      .min(1000000000, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें | ")
      .max(9999999999, "कृपया 10 अंको का मोबाइल नंबर दर्ज करें | "),
      
    name: Yup.string()
      .matches(
        /^[a-zA-Z0-9\u0900-\u097F\s]+$/,
        "कृपया सही प्रारूप में नाम दर्ज करे | "
      )
      .required("कृपया नाम दर्ज करें | "),
    // Modification and addition by Om Shrivastava on 10-04-24
    // Reason : Add validation for amount field 
    // amount: Yup.string().required("कृपया राशि दर्ज करें | "),
    amount: Yup.number()
    .transform((value, originalValue) =>
      /\s/.test(originalValue) ? NaN : value
    )
    .integer()
    .typeError("कृपया राशि दर्ज करें | ")
    .required("कृपया राशि दर्ज करें | ")
    .max(999999999, "कृपया 9 अंको का राशि दर्ज करें | "),
    // End of modification and addition by Om Shrivastava on 10-04-24
    // Reason : Add validation for amount field 

    // Addiiton by Om Shrivastava on 17-02-24
    // Reason : Need to add the validation for email
    email: Yup.string().trim().email("कृपया सही फार्मेट में ई-मेल दर्ज करें | "),
    // End of addition by Om Shrivastava on 17-02-24
    // Reason : Need to add the validation for email
    // gender: Yup.string().required("कृपया लिंग चुने"),
  });
  const currency = "INR";

  // const complete_order = (paymentID, orderID, signature, amount) => {
  //   axios({
  //     method: "post",
  //     url: "http://192.168.1.10:8000/api/order/complete/",
  //     data: {
  //       payment_id: paymentID,
  //       order_id: orderID,
  //       signature: signature,
  //       amount: amount,
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //     });
  // };

   /**
   * Added by - Om Shrivastava on 26-03-24
   * Reason - To get donation request details from backend and send it to receipt page
   */
   async function getDonationReceipt(donationReceiptID){
    const response = await getDonationReceiptApi(donationReceiptID);
    if(response){
      nav("/donation_receipt", { state:response?.donation_receipt });
    }
  }
  /**
   * End of code addition by - Om Shrivastava on 26-03-24
   * Reason - To get donation request details from backend and send it to receipt page
   */

  async function razorpay_data(amount, orderId) {
    const requestData = {
      amount: parseFloat(amount),
      currency: "INR",
      // Added by - Ashlekh on 02-03-2024
      // Reason - To send orderId to razorpay so that we can save razorpya orderId in our donation request table
      rowId: orderId,
      key: "donation",
      // End of code - Ashlekh on 02-03-2024
      // Reason - To send orderId to razorpay so that we can save razorpay orderId in our donation request table
    };
    const response = await postRazorPayRequriedApi(requestData);
    if (response) {
      // console.log("postRazorPayRequiredApi",response.data);
      // console.log("postrazorpayrequiredapi", response);
      var order_id = response.data.id;
      var amount = response.data.amount;
      // Addition by Om Shrivastava on 26-03-24
      // Reason : Need to create function for sent the id in receipt page
      var objectid = response?.details?.id;
      // End of addition by Om Shrivastava on 26-03-24
      // Reason : Need to create function for sent the id in receipt page
      // console.log(amount);

      const options = {
       // Modification and addition by Om Shrivastava on 11-03-24
        // Reason : Need to add the razorpay key id
        // key: "rzp_test_FU5SNWRBelrZDX", // Enter the Key ID generated from the Dashboard
        key: config.RAZORPAY_KEY_ID, 
        // Enf of modification and addition by Om Shrivastava on 11-03-24
        // Reason : Need to add the razorpay key id
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          complete_order(
            response.razorpay_payment_id,
            response.razorpay_order_id,
            response.razorpay_signature,
            amount
          );
          // Addition by Om Shrivastava on 26-03-24
          // Reason : Need to create function for sent the id in receipt page
          getDonationReceipt(objectid)
          // End of addition by Om Shrivastava on 26-03-24
          // Reason : Need to create function for sent the id in receipt page
        },

        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
          request_for:"donation",
          // Added by - Om Shrivastava on 26-03-24
          // Reason - To send rowId (so that we can navigate to receipt page)
          rowId: objectid,
          // End of code - Om Shrivastava on 26-03-24
          // Reason - To send rowId (so that we can navigate to receipt page)
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
     

      rzp1.open();
      
    }
  }

  async function complete_order(paymentID, orderID, signature, amount) {
    const requestData = {
      payment_id: paymentID,
      order_id: orderID,
      signature: signature,
      amount: amount,
    };
    // console.log("requestDataaa", requestData);
    // const response = await checkTransactionApi(requestData);
    // if (response) {
    //   console.log("checkTransactionApi", response);
    // }
  }

  async function submitJyotRequest(values) {
    const requestData = {
      name: values.name,
      donation_type: values.donation_type,
      gender: values.gender,
      email: values.email,
      address: values.address,
      contact_number: values.contact_number,
      amount: values.amount,
      comment: values.comment,

      // amount: parseFloat(values.amount),
      // comment: values.comment,

      // currency: currency.toString(),
    };

    const response = await postDonationRequestApi(requestData);
    if (response.message) {
      notificationObject.success(response.message);
      // nav("/donation_receipt");

    //  Modification and addition by Om Shrivastava on 20-05-2024
    //  Reason : Currently No use of payment gateway 
      // var amount = response.donation_request_details.amount;
      // // Added by - Ashlekh on 02-03-2024
      // // Reason - To save orderId
      // var orderId = response.donation_request_details.id;
      // // console.log(amount);
      // // razorpay_data(amount);
      // razorpay_data(amount, orderId);
      // // End of code - Ashlekh on 02-03-2024
      // // Reason - To save orderId
      var objectid = response?.donation_request_details?.id;
      getDonationReceipt(objectid)
      //  End of modification and addition by Om Shrivastava on 20-05-2024
      //  Reason : Currently No use of payment gateway 

    } else if (response.error) {
      notificationObject.dismissAll();
      notificationObject.error(response.error);
    }
  }

  const onSubmit = (values) => {
    // console.log(JSON.stringify(values));
    submitJyotRequest(values);
  };

  const renderError = (message) => (
    <span className={`${donationFormStyle.validationMsgContainer}`}>
      {message}
    </span>
  );

  var toWords = new ToWords({
    localeCode: "hi-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "रुपये ",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "पैसे",
          symbol: "",
        },
      },
    },
  });

  var amountIynHindi = toWords?.convert(
    0
    // selectedJyotOptionData?.amount != undefined
    //   ? selectedJyotOptionData?.amount
    //   : 0
  );
  // console.log(values.amount)

  const handleFieldChange = (e) => {
    // e.stopPropagation()
    const { value } = e.target;
    // console.log("Current amount value:", value);
    setAmountInHindi(value);
  };

  // var amountConvertInHindi = toWords?.convert(
  //   amountInHindi != undefined && amountInHindi != "" ? amountInHindi : 0
  // );
  // console.log(amountInHindi, amountConvertInHindi);

  return (
    <div className={`${donationFormStyle.formContainer}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await onSubmit(values);
          resetForm();
        }}
        enableReinitialize={true}
      >
        <Form
        // Addition by Om Shrivastava on 10-04-24
        // Reason : For removing the html error method 
        noValidate={true}
        // End of addition by Om Shrivastava on 10-04-24
        // Reason : For removing the html error method 
        >
          <div className={donationFormStyle.inputHolder}>
            <label className={donationFormStyle.label}>
              {Constants.DONATION_TYPE_TEXT}
              <span style={{ color: "red" }}> * </span>
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div
              className={donationFormStyle.label}
              style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
            >
              <div className={donationFormStyle.manageWidthForRadioAndDropdown}>
                {/* Addition by Om Shrivastava on 18-02-24 When need to show radio button  */}
                {/* {props.donationTypeData.map(dataa => (
            <label key={dataa.id.toString()}>
              <Field type="radio" name="donation_type" value={dataa.id.toString()} />
              {dataa.donation_type}
            </label>
          ))} */}
                {/* End of addition  */}
                <Field
                  name="donation_type"
                  as="select"
                  className={donationFormStyle.selectBox}
                >
                  <option disabled value={""}>
                    कृपया दान के प्रकार चुने
                  </option>
                  {productOptions}
                </Field>
              </div>

              <ErrorMessage
                style={{ border: "1px solid red" }}
                name="donation_type"
                render={renderError}
              />
            </div>
          </div>
          <div className={donationFormStyle.inputHolder}>
            <label className={donationFormStyle.label}>
              {Constants.NAME_TEXT}
              <span style={{ color: "red" }}> * </span>
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div className={donationFormStyle.inputWithError}>
              <Field
                name="name"
                type="text"
                className={donationFormStyle.inputBox}
              />
              <ErrorMessage name="name" render={renderError} />
            </div>
          </div>

          <div
            className={donationFormStyle.inputHolder}
            role="group"
            aria-labelledby="radio-group"
          >
            <label
              className={donationFormStyle.label}
              style={{ paddingLeft: "1px" }}
            >
              {Constants.GENDER_TEXT}
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div
              className={donationFormStyle.label}
              style={{ width: "60%", display: "flex", flexWrap: "wrap" }}
            >
              {/* Addition by Om Shrivastava on 18-02-24 When need to show radio button  */}
              {genderDetails.map((dataa) => (
                <label
                  key={dataa.id.toString()}
                  style={{
                    paddingLeft: "2%",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "13px",
                  }}
                >
                  <Field
                    type="radio"
                    name="gender"
                    value={dataa.name}
                    className={donationFormStyle.inputBox}
                  />
                  &nbsp;{dataa.name}
                </label>
              ))}
              <div
                style={{ paddingLeft: "2.9%" }}
                className={donationFormStyle.manageWidthForRadioAndDropdown}
              >
                {/* End of addition  */}
                <ErrorMessage name="gender" render={renderError} />
              </div>
            </div>
          </div>

          <div className={donationFormStyle.inputHolder}>
            <label className={donationFormStyle.label}>
              {Constants.CONTACT_TEXT}
              <span style={{ color: "red" }}> * </span>
            </label>

            <div className={`${donationFormStyle.colon}`}> : </div>
            <div className={donationFormStyle.inputWithError}>
              <Field
                name="contact_number"
                type="number"
                className={donationFormStyle.inputBox}
              />
              <ErrorMessage name="contact_number" render={renderError} />
            </div>
          </div>

          <div className={donationFormStyle.inputHolder}>
            <label
              className={donationFormStyle.label}
              style={{ paddingLeft: "1px" }}
            >
              {Constants.EMAIL_ADDRESS_TEXT}
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div className={donationFormStyle.inputWithError}>
              <Field
                name="email"
                type="text"
                className={donationFormStyle.inputBox}
              />
              <ErrorMessage name="email" render={renderError} />
            </div>
          </div>

          <div className={donationFormStyle.inputHolder}>
            <label className={donationFormStyle.label}>
              {Constants.RUPEE_TEXT}
              <span style={{ color: "red" }}> * </span>
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div className={donationFormStyle.inputWithError}>
              <Field
                name="amount"
                type="number"
                id="donationFormAmount"
                className={donationFormStyle.amountInput}
                // onChange={(e) => handleFieldChange(e)}
                onClick={(e) => e.stopPropagation()}
              />
              <ErrorMessage name="amount" render={renderError} />
            </div>
          </div>
          {/* {amountInHindi != 0 ? (
            <div
              className={`${donationFormStyle.inputHolder} ${donationFormStyle.inputHolderamountInWord}`}
            >
              <label
                className={donationFormStyle.label}
                style={{ paddingLeft: "3px" }}
              >
                {Constants.RUPEE_IN_WORD}
              </label>
              <div
                className={`${donationFormStyle.colon}`}
                style={{ paddingLeft: "2px" }}
              >
                :{" "}
              </div>
              <div className={donationFormStyle.inputWithError}>
                ({amountConvertInHindi})
              </div>
            </div>
          ) : null} */}

          <div className={donationFormStyle.inputHolder}>
            <label
              className={donationFormStyle.label}
              style={{ paddingLeft: "1px" }}
            >
              {Constants.ADDRESS_TEXT}
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div className={donationFormStyle.inputWithError}>
              <Field
                component="textarea"
                id="textareaField"
                name="address"
                // className={donationFormStyle.inputBox}
                className={`${donationFormStyle.textArea} `}
              />
            </div>
          </div>

          <div className={donationFormStyle.inputHolder}>
            <label
              className={donationFormStyle.label}
              style={{ paddingLeft: "1px" }}
            >
              {Constants.COMMENT_TEXT}
            </label>
            <div className={`${donationFormStyle.colon}`}> : </div>
            <div className={donationFormStyle.inputWithError}>
              <Field
                component="textarea"
                id="textareaField"
                name="comment"
                className={`${donationFormStyle.textArea} `}
              />
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ color: "black", fontWeight: "bold" }}>
              कृपया भुगतान के बाद पुष्टि करें :-
            </div>
          </div>
          <div
            className={donationFormStyle.buttonContainer}
            style={{
              paddingTop: "2%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button className={donationFormStyle.button} type="submit">
              {Constants.SUBMIT_TEXT}
            </button>
          </div>
          {/* Commented by Om Shrivastava on 01-04-24
          Reason : Remove this content from form page  */}
          {/* Uncommented by Om Shrivastava on 18-05-2024
          Reason : As per discuss with Ashish sir,show the description below the donation form */}
          <div className={donationFormStyle.descriptionContainer}>
            {parse(
              props.donationDescription != null &&
                props.donationDescription.length > 0
                ? // Modification and commented by Om shrivastava on 18-03-24
                  // Reason : Need to add this section in div
                  // props.donationDescription
                  `<div >
                    ${props.donationDescription}
                  </div>`
                : // End of modification and commented by Om shrivastava on 18-03-24
                  // Reason : Need to add this section in div
                  ""
            )}
          </div>
          {/* Uncommented by Om Shrivastava on 18-05-2024
          Reason : As per discuss with Ashish sir,show the description below the donation form */}
          {/* End of commented code by Om Shrivastava on 01-04-24
          Reason : Remove this content from form page */}
        </Form>
      </Formik>
    </div>
  );
};

export default DonationForm;

// End of modification and Commented by Om Shrivastava on 17-02-24
// Reason : Need to use formik in this donation form
